import { useMutation } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { UseRunSystemJobParameters } from "./Models";

export function useRunSystemJob() {
  const { axios: ax } = useAxios();

  return useMutation<void, Error, UseRunSystemJobParameters>(async (parameters) => {
    await ax.post(parameters.url, {});
  });
}
