import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { AllUserModel, AllUsersQueryProperties } from "./Models";

//custom query hook
export function useAllUsers({ includeDisabled, includeElynx, customerType }: AllUsersQueryProperties) {
  const { axios: ax } = useAxios();

  //create the query key from the supplied query properties
  const queryKey = keys.userListQuery(includeDisabled, includeElynx, customerType);

  return useQuery<Array<AllUserModel>, Error>(
    queryKey,
    async () => {
      const { data } = await ax.get<Array<AllUserModel>>(
        `allusers?disabled=${includeDisabled}&elynx=${includeElynx}&customerType=${customerType}`
      );
      return data;
    },
    {
      staleTime: 2 * 60 * 1000, //we will use data that is slightly stale because the user list doesn't change often
      refetchInterval: 60 * 1000, //but let's auto-refresh the data
    }
  );
}
