import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { ISystemJob } from "./Models";

export type UseSystemJobsParameters = {
  autoRefreshSeconds: number;
};

export function useSystemJobs({autoRefreshSeconds}: UseSystemJobsParameters) {
  const { axios: ax } = useAxios();

  return useQuery<Array<ISystemJob>, Error>(
    keys.all,
    async () => {
      const { data } = await ax.get<Array<ISystemJob>>("jobs3");
      return data;
    },
    {
      staleTime: 5 * 60 * 1000, //we will use data that is old because the actual job list doesn't change much
      refetchInterval: autoRefreshSeconds > 0 ? autoRefreshSeconds * 1000 : false, 
      refetchIntervalInBackground: true,
      select: (data) => {
        return data.map((j) => {
          const job = {
            ...j,
            podNames:
              j.deployments && null !== j.deployments
                ? j.deployments.flatMap((d) => d.podNames)
                : null,
          } as ISystemJob;

          return job;
        });
      },
    }
  );
}
