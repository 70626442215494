import { addDays } from "date-fns";
import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { PageUsageQueryProperties, PageUsageResult } from "./Models";

export type UsePageUsageDataParameters = {
  enabled: boolean;
  pageUsageQueryProperties: PageUsageQueryProperties;
};

export function usePageUsageData({ enabled, pageUsageQueryProperties }: UsePageUsageDataParameters) {
  const { axios: ax } = useAxios();

  //create the query key from the supplied logQueryProperties
  const queryKey = keys.queryKey(pageUsageQueryProperties);

  return useQuery<PageUsageResult, Error>(
    queryKey,
    async ({ signal }) => {
      //we have to setup the timestamp part of the query inside this function
      //so that it will be updated every time a refetch is done
      let queryProperties = { ...pageUsageQueryProperties } as PageUsageQueryProperties;

      //unless the user selected "Custom" as the timeframe
      //create the date range from the timeframe and clone a new object
      if (pageUsageQueryProperties?.timeframe && pageUsageQueryProperties.timeframe > 0) {
        const endDate = new Date();
        const startDate = addDays(endDate, 0 - pageUsageQueryProperties.timeframe);
        queryProperties = {
          ...pageUsageQueryProperties,
          startDate: startDate,
          endDate: endDate,
        };
      }

      //now make the actual data call
      const { data } = await ax.post<PageUsageResult>("pageusage/query", queryProperties, { signal });
      return data;
    },
    {
      enabled: enabled,
    }
  );
}
