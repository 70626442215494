import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Box } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import useWindowSize from "../../hooks/use-window-size/UseWindowSize";
import { AuditTrailItem } from "../../queries/audit-trail/Models";
import { useAppSettings } from "../../contexts/app-context/AppContext";
import { LocalTimestampFormatter } from "../../components/ag-grid-extensions/utilities/LocalTimestampFormatter";

//our only prop for now is the actual audit trail data
export type AuditTrailGridProps = {
  auditTrailData: Array<AuditTrailItem>;
};

//use AG Grid to create a grid specific to showing log data
//clicking a message with an exception will show the exception text in a modal dialog
export default function AuditTrailGrid({ auditTrailData: auditTrailData }: AuditTrailGridProps) {
  const gridRef = useRef<AgGridReact>(null);
  const [appSettings] = useAppSettings();
  const gridTheme = appSettings.themeSet[appSettings.themeSet.mode].grid;

  //we don't want the logger name to crowd out everything else
  //so get the viewport size and limit the logger name to
  //a portion of the viewport width
  const { width: viewportWidth } = useWindowSize();
  const columnDefs = useMemo<Array<ColDef>>(() => {
    return [
      {
        headerName: "Timestamp",
        field: "ocat_timestamp",
        valueFormatter: LocalTimestampFormatter,
      },
      {
        headerName: "Username",
        field: "ocat_username",
      },
      {
        headerName: "IP Address",
        field: "ocat_ip_address",
      },
      {
        headerName: "Action",
        field: "ocat_action",
      },
      {
        headerName: "View",
        field: "ocat_view",
      },
      {
        headerName: "Object Type",
        field: "ocat_object_type_id",
      },
      {
        headerName: "Object ID",
        field: "ocat_object_id",
      },
      {
        headerName: "Object Name",
        field: "ocat_object_name",
      },
      {
        headerName: "Old Value",
        field: "ocat_old_value",
      },
      {
        headerName: "New Value",
        field: "ocat_new_value",
      },
      {
        headerName: "Description",
        field: "ocat_description",
      },
    ];
  }, [viewportWidth]);

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: false,
      resizable: true,
    };
  }, []);

  //auto-size all columns except message to fit their content
  //message is a flex column that will use the remaining space
  const autoSizeColumns = useCallback(() => {
    gridRef.current?.api.autoSizeAllColumns();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      autoSizeColumns();
    }, 300);
    return () => clearTimeout(timer);
  }, [auditTrailData]);

  return (
    <>
      {/* It appears that in order to make the grid fit into a flex scheme 
      it needs to be contained by a div with a hard size (calculated by flex, in this case) that it can fill completely */}
      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          "& .ag-theme-alpine .ag-cell-value": {
            lineHeight: "20px !important",
            wordBreak: "normal",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
          "& .ag-theme-alpine-dark .ag-cell-value": {
            lineHeight: "20px !important",
            wordBreak: "normal",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
        }}
      >
        <div className={gridTheme} style={{ height: "100%", width: "100%" }}>
          <AgGridReact
            ref={gridRef}
            defaultColDef={defaultColDef}
            rowData={auditTrailData}
            columnDefs={columnDefs}
            ensureDomOrder={true}
            enableCellTextSelection={true}
            // these events do not seem to auto size the columns correctly,
            // singularly or collectively, in every circumstance
            // so a useEffect has been setup to do it
            // onFirstDataRendered={autoSizeColumns}
            // onGridReady={autoSizeColumns}
            // onModelUpdated={autoSizeColumns}
          />
        </div>
      </Box>
    </>
  );
}
