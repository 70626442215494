import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Fab,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { useAppSettings } from "../../contexts/app-context/AppContext";
import { useUserRole } from "../../contexts/user-role-context/UserRoleContext";
import useStateRef from "react-usestateref";
import { useAlert } from "react-alert";
import { useDeleteConfigurationItem } from "../../queries/environment-configuration/UseDeleteConfigurationItem";
import { useConfirm } from "../../contexts/confirm-context/ConfirmContext";
import ContentHeader from "../../components/content-header/ContentHeader";
import ContentView from "../../components/content-view/ContentView";
import ContentSection from "../../components/content-section/ContentSection";
import { ConfigurationSettingModel } from "../../queries/environment-configuration/Models";
import { useEnvironmentConfiguration } from "../../queries/environment-configuration/UseEnvironmentConfiguration";
import { useSaveConfigurationItem } from "../../queries/environment-configuration/UseSaveConfigurationItem";
import AddConfigItem from "../../components/config-item/AddConfigItem";
import MuiIconCellRenderer, {
  MuiIconRendererProps,
} from "../../components/ag-grid-extensions/renderers/MuiIconCellRenderer";
import Editor from "@monaco-editor/react";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";

const configType = "nlogconfigurations";

export default function NLogConfiguration() {
  const theme = useTheme();
  const confirm = useConfirm();
  const alert = useAlert();
  const gridRef = useRef<AgGridReact>(null);
  const [appSettings] = useAppSettings();
  const gridTheme = appSettings.themeSet[appSettings.themeSet.mode].grid;
  const { isOpsCenterWriterMember } = useUserRole();
  const [configItems, setConfigItems, configItemsRef] = useStateRef<Array<ConfigurationSettingModel>>([]);
  const [addConfigItemOpen, setAddConfigItemOpen] = useState(false);
  const [addConfigItemDefaultValue, setAddConfigItemDefaultValue] = useState<string | null>(null);
  const [editorDialogOpen, setEditorDialogOpen] = useState(false);
  const [editorTextIsValid, setEditorTextIsValid] = useState(true);
  const pendingSaveConfigRef = useRef<ConfigurationSettingModel | null>(null);
  const pendingDeleteConfigRef = useRef<ConfigurationSettingModel | null>(null);
  const editingConfigRef = useRef<ConfigurationSettingModel | null>(null);
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);
  const {
    data: newConfigItems,
    isSuccess,
    isFetching,
    refetch,
  } = useEnvironmentConfiguration({ configType: configType });
  const {
    mutate: saveConfigItem,
    isError: isSaveConfigurationError,
    error: saveConfigurationError,
  } = useSaveConfigurationItem({ configType: configType });
  const {
    mutate: deleteConfigItem,
    isError: isDeleteConfigurationError,
    error: deleteConfigurationError,
  } = useDeleteConfigurationItem({ configType: configType });

  //if we get a new set of configuration items, put it in our state
  useEffect(() => {
    if (newConfigItems) {
      setConfigItems(newConfigItems);
    }
  }, [isSuccess, newConfigItems]);

  //report errors saving or deleting a configuration
  useEffect(() => {
    if (true === isSaveConfigurationError) {
      alert.error(
        `Error saving NLog configuration ${pendingSaveConfigRef.current?.settingId}: ${saveConfigurationError}`
      );
    }
    pendingSaveConfigRef.current = null;
  }, [isSaveConfigurationError]);

  useEffect(() => {
    if (true === isDeleteConfigurationError) {
      alert.error(
        `Error deleting NLog configuration ${pendingDeleteConfigRef.current?.settingId}: ${deleteConfigurationError}`
      );
    }
    pendingDeleteConfigRef.current = null;
  }, [isDeleteConfigurationError]);

  //setup grid columns, starting with their callback methods
  const onSaveConfigItem = useCallback((configItem: ConfigurationSettingModel) => {
    //if we were asked to save a new config item
    //and we know that item already exists
    //post an alert
    if (
      (!configItem.concurrencyToken || configItem.concurrencyToken.length == 0) &&
      configItemsRef?.current &&
      configItemsRef.current.findIndex((c) => c.settingId.toLowerCase() === configItem.settingId.toLowerCase()) > -1
    ) {
      alert.error(`NLog configuration ${configItem.settingId} already exists!`);
    } else {
      pendingSaveConfigRef.current = configItem;
      saveConfigItem(configItem);
    }
  }, []);

  const onDeleteClicked = useCallback((configItem: ConfigurationSettingModel) => {
    //confirm rejects the promise if the user does not confirm
    confirm({
      title: "Confirm Delete NLog Configuration",
      description: `Are you sure you want to delete NLog configuration ${configItem.settingId}?`,
      allowClose: false,
    })
      .then(() => {
        pendingDeleteConfigRef.current = configItem;
        deleteConfigItem(configItem);
      })
      .catch(() => {
        console.log(`Delete NLog configuration ${configItem.settingId} canceled!`);
      });
  }, []);

  const onEditClicked = useCallback((configItem: ConfigurationSettingModel) => {
    editingConfigRef.current = configItem;
    setEditorDialogOpen(true);
  }, []);

  const onAddItemClicked = useCallback(() => {
    //make sure there is no default text
    //addItemDefaultSettingValueRef.current = null;
    setAddConfigItemDefaultValue(null);
    setAddConfigItemOpen(true);
  }, []);

  const onCloneItemClicked = (configItem: ConfigurationSettingModel) => {
    //set the default value to the value of the item to be copied
    //addItemDefaultSettingValueRef.current = configItem.settingValue;
    setAddConfigItemDefaultValue(configItem.settingValue);
    setAddConfigItemOpen(true);
  };

  //setup all the columns
  const columnDefs = useMemo<Array<ColDef>>(() => {
    const columns = [
      {
        headerName: "Delete",
        colId: "delete",
        width: 100,
        cellRenderer: MuiIconCellRenderer,
        cellRendererParams: {
          disabled: !isOpsCenterWriterMember,
          iconName: "delete",
          size: "medium",
          tooltipText: "Delete",
          shouldDisplay: isOpsCenterWriterMember,
          onClick: onDeleteClicked,
        } as MuiIconRendererProps,
      },
      {
        headerName: "Name",
        field: "settingId",
        minWidth: 300,
        maxWidth: 400,
        flex: 1,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Value",
        field: "settingValue",
        minWidth: 300,
        flex: 2,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: isOpsCenterWriterMember ? "Edit" : "View",
        colId: "edit",
        width: 100,
        cellRenderer: MuiIconCellRenderer,
        cellRendererParams: {
          disabled: false,
          iconName: "edit",
          size: "medium",
          tooltipText: isOpsCenterWriterMember ? "View / Edit" : "View",
          shouldDisplay: true,
          onClick: onEditClicked,
        } as MuiIconRendererProps,
      },
      {
        hide: !isOpsCenterWriterMember,
        headerName: "Clone",
        colId: "clone",
        width: 100,
        cellRenderer: MuiIconCellRenderer,
        cellRendererParams: {
          disabled: false,
          iconName: "clone",
          size: "medium",
          tooltipText: "Clone this item",
          shouldDisplay: true,
          onClick: onCloneItemClicked,
        } as MuiIconRendererProps,
      },
    ] as Array<ColDef>;

    return columns;
  }, [isOpsCenterWriterMember]);

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  //callbacks for add and edit dialogs
  const handleAddConfigItemClose = useCallback(() => {
    setAddConfigItemOpen(false);
  }, []);

  //hide the dialog
  const handleEditorDialogClose = () => {
    setEditorDialogOpen(false);
  };

  const onDialogSaveClicked = useCallback(() => {
    //if we know what we are editing
    if (editingConfigRef.current && editorRef.current) {
      //get its current value from the editor
      const newConfigItem = {
        ...editingConfigRef.current,
        settingValue: editorRef.current.getValue(),
      } as ConfigurationSettingModel;

      //and save it
      onSaveConfigItem(newConfigItem);
      setEditorDialogOpen(false);
    }
  }, []);

  //monaco editor callbacks
  const handleEditorDidMount = useCallback((editor) => {
    editorRef.current = editor;
  }, []);

  const handleEditorValidation = useCallback((markers: Array<monaco.editor.IMarker>) => {
    if (markers.length > 0) {
      setEditorTextIsValid(false);
    } else {
      setEditorTextIsValid(true);
    }
  }, []);

  return (
    <>
      <ContentView>
        <ContentHeader title={"NLog Configuration"} />
        <Stack
          direction="row"
          spacing={3}
          divider={<Divider orientation="vertical" flexItem />}
          sx={{
            backgroundColor: theme.palette.neutral.lowContrast,
            flexWrap: "wrap",
            padding: "6px",
            rowGap: "6px",
            alignItems: "center",
          }}
        >
          <Box sx={{ "& > button": { m: 1 }, display: "flex", width: "14ch" }}>
            <Button
              onClick={() => refetch()}
              endIcon={isFetching ? <CircularProgress color="inherit" size="1em" /> : <RefreshIcon />}
              variant="contained"
            >
              Refresh
            </Button>
          </Box>
        </Stack>
        <ContentSection>
          {/* It appears that in order to make the grid fit into a flex scheme 
      it needs to be contained by a div with a hard size (calculated by flex, in this case) that it can fill completely */}
          <Box
            sx={{
              display: "flex",
              flex: "1 1 auto",
              "& .ag-theme-alpine .ag-cell-value": {
                lineHeight: "20px !important",
                wordBreak: "normal",
                paddingTop: "5px",
                paddingBottom: "5px",
              },
              "& .ag-theme-alpine-dark .ag-cell-value": {
                lineHeight: "20px !important",
                wordBreak: "normal",
                paddingTop: "5px",
                paddingBottom: "5px",
              },
            }}
          >
            <div className={gridTheme} style={{ height: "100%", width: "100%" }}>
              <AgGridReact
                ref={gridRef}
                defaultColDef={defaultColDef}
                rowData={configItems}
                columnDefs={columnDefs}
                ensureDomOrder={true}
                enableCellTextSelection={true}
                suppressClickEdit={true}
                suppressCellFocus={true}
                alwaysMultiSort={true}
                scrollbarWidth={0}
                columnMenu="legacy"
              />
            </div>
          </Box>
        </ContentSection>
      </ContentView>

      {/* Add config item */}
      <AddConfigItem
        open={addConfigItemOpen}
        onClose={handleAddConfigItemClose}
        saveConfigItem={onSaveConfigItem}
        defaultSettingValue={addConfigItemDefaultValue}
      />

      {/* Floating Action Button for adding a new config item */}
      <Fab
        color="primary"
        aria-label="add"
        title="Add new NLog configuration"
        onClick={() => onAddItemClicked()}
        disabled={!isOpsCenterWriterMember}
      >
        <AddIcon />
      </Fab>

      {/* This dialog shows the editor for the configuration item.
        It is set to a height independent of the content size so that 
        the editor component can fill the full size of the dialog */}
      <Dialog
        open={editorDialogOpen}
        onClose={handleEditorDialogClose}
        scroll="paper"
        fullWidth={true}
        maxWidth="xl"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperProps={{ sx: { height: "100%" } }}
      >
        <DialogTitle id="scroll-dialog-title">{editingConfigRef.current?.settingId}</DialogTitle>
        <DialogContent dividers={true}>
          <Editor
            height="100%"
            theme={theme.palette.mode === "dark" ? "vs-dark" : "light"}
            defaultLanguage={
              editingConfigRef.current?.settingValue[0] == "[" || editingConfigRef.current?.settingValue[0] == "{"
                ? "json"
                : "plaintext"
            }
            defaultValue={editingConfigRef.current?.settingValue}
            onMount={handleEditorDidMount}
            onValidate={handleEditorValidation}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditorDialogClose}>Close</Button>
          {isOpsCenterWriterMember && (
            <Button onClick={onDialogSaveClicked} color="secondary" disabled={!editorTextIsValid}>
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
