export const keys = {
  all: ["copy-device"] as const,
  list: () => [...keys.all, "list"] as const,
  job: (jobID: number) => [...keys.all, jobID] as const,
  devices: (jobID: number) => [...keys.job(jobID), "devices"] as const,
  tatInfo: (jobID: number) => [...keys.job(jobID), "tatInfo"] as const,
  uomInfo: (jobID: number) => [...keys.job(jobID), "uomInfo"] as const,
  formulaInfo: (jobID: number) => [...keys.job(jobID), "formulaInfo"] as const,
  lookupListInfo: (jobID: number) => [...keys.job(jobID), "lookupListInfo"] as const,
  tatGroupInfo: (jobID: number) => [...keys.job(jobID), "tatGroupInfo"] as const,
};
