import React, { useEffect, useRef, useState } from "react";
import { Box, Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack } from "@mui/material";
import { ISystemJob } from "../../queries/system-jobs/Models";
import { useSystemJobs } from "../../queries/system-jobs/UseSystemJobs";
import ListFilter from "../../components/list-filter/ListFilter";
import { faListAlt, faCopy } from "@elynx/pro-regular-svg-icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PodListDialog from "../../components/pod-list-dialog/PodListDialog";
import { useToastNotification } from "../../contexts/toast-notification/ToastNotificationContext";
import FontAwesomeIconButton from "../font-awesome-icon-button/FontAwesomeIconButton";

export type SystemJobListProps = {
  setLoggerNames: (loggerNames: Array<string>) => void;
};

export default function SystemJobList({ setLoggerNames }: SystemJobListProps) {
  const podListRef = useRef<Array<string>>([]);
  const { displayToast } = useToastNotification();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [checked, setChecked] = useState<Array<string>>([]);
  const { data: newSystemJobs } = useSystemJobs({ autoRefreshSeconds: 60 });
  const [systemJobs, setSystemJobs] = useState<Array<ISystemJob>>(newSystemJobs || []);
  const [currentJobs, setCurrentJobs] = useState<Array<ISystemJob> | undefined>(newSystemJobs);
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    if (newSystemJobs) {
      setSystemJobs(newSystemJobs);
    }
  }, [newSystemJobs]);

  useEffect(() => {
    const filteredJobs = systemJobs?.filter((job) => {
      return job.name.toLowerCase().includes(filterText.toLowerCase());
    });
    if (filteredJobs) {
      setCurrentJobs(filteredJobs);
      setLoggerNames(filteredJobs.map((job) => job.loggerName).filter((loggerName) => checked.includes(loggerName)));
    }
  }, [filterText, systemJobs]);

  const handleToggle = (loggerName: string) => () => {
    const currentIndex = checked.indexOf(loggerName);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(loggerName);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    if (currentJobs) {
      setLoggerNames(currentJobs.map((job) => job.loggerName).filter((loggerName) => newChecked.includes(loggerName)));
    }
  };

  //if the item contains a pod list,
  //put the text into a ref and open the dialog to show it
  const handlePodListClick = (podNames: Array<string> | undefined) => {
    if (podNames) {
      podListRef.current = podNames;
      setDialogOpen(true);
    }
  };

  //hide the dialog
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Stack sx={{ padding: "0 0 0 0" }}>
        <ListFilter onFilterValueChange={(value) => setFilterText(value)} debounceDelay={300} placeholder="filter" />
        <List>
          {currentJobs?.map((job, inx) => {
            const labelId = `checkbox-list-label-${inx}`;

            return (
              <ListItem
                key={inx}
                role={undefined}
                dense
                divider
                sx={{
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  paddingLeft: "2px",
                  paddingRight: "2px",
                }}
              >
                <Box sx={{ opacity: job?.podNames?.length ?? 0 > 0 ? 100 : 0 }}>
                  <FontAwesomeIconButton
                    icon={faListAlt}
                    iconSize={16}
                    onClick={() => handlePodListClick(job.podNames)}
                  />
                </Box>
                <Box sx={{ paddingLeft: "0px" }}>
                  <CopyToClipboard
                    text={job.loggerName}
                    onCopy={() => displayToast({ message: "Copied to clipboard" })}
                  >
                    <FontAwesomeIconButton icon={faCopy} iconSize={16} />
                  </CopyToClipboard>
                </Box>
                <ListItemButton onClick={handleToggle(job.loggerName)} sx={{ paddingLeft: "8px" }}>
                  <ListItemIcon sx={{ minWidth: "20px" }}>
                    <Checkbox
                      size="small"
                      sx={{
                        paddingTop: "2px",
                        paddingBottom: "2px",
                        paddingRight: "4px",
                      }}
                      edge="start"
                      checked={checked.indexOf(job.loggerName) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ whiteSpace: "nowrap" }} id={labelId} primary={job.name} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Stack>

      <PodListDialog podList={podListRef.current} dialogOpen={dialogOpen} onClose={handleDialogClose} />
    </>
  );
}
