import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { DeviceCopyJob, DeviceSelectionInfo } from "./Models";

export type UseDeviceCopyDeviceListParameters = {
  job: DeviceCopyJob;
  enabled: boolean;
};

//custom query hook
export function useDeviceCopyDeviceList({ job, enabled }: UseDeviceCopyDeviceListParameters) {
  const { axios: ax } = useAxios();
  const jobID = job?.jobID ?? 0;
  const customerID = job?.srcCustID ?? 0;
  const orgID = job?.srcOrgID ?? 0;

  const queryKey = keys.devices(jobID);

  return useQuery<DeviceSelectionInfo, Error>(
    queryKey,
    async ({ signal }) => {
      const { data } = await ax.get<DeviceSelectionInfo>("devcpy/objects", {
        signal,
        params: {
          customerID: customerID,
          orgID: orgID,
        },
      });
      return data;
    },
    {
      //we cannot be enabled even if the caller requests it
      //but the parameters are not valid
      enabled: enabled && customerID > 0 && orgID > 0,
      cacheTime: 0,
    }
  );
}
