import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { NonPolledDataConfiguration } from "./Models";

export function useNonPolledDataConfig() {
  const { axios: ax } = useAxios();

  return useQuery<NonPolledDataConfiguration, Error>(keys.config, async ({ signal }) => {
    const { data } = await ax.get<NonPolledDataConfiguration>("nonpolleddataops/config", { signal });
    return data;
  });
}
