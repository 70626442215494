import React, { PropsWithChildren } from "react";
import { Paper } from "@mui/material";

export type StepMenuContainerProps = Record<PropertyKey, unknown>;

export default function StepMenuContainer({ children }: PropsWithChildren<StepMenuContainerProps>) {
  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        overflow: "hidden",
      }}
      elevation={0}
    >
      {children}
    </Paper>
  );
}
