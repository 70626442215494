import { useMutation } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { PublishTagValueRequest } from "./PublishTagValueRequest";

export function usePublishTagValue() {
  const { axios: ax } = useAxios();

  return useMutation<void, Error, PublishTagValueRequest>(async (request) => {
    const parms = {
      customerID: request.customerID,
      tagID: request.tagID,
      value: request.dataValue,
      timestamp: request.timestamp,
      setPointEventType: request.setPointEventType,
      bypassFormula: request.bypassFormula,
      useTimestampsUnaltered: request.useTimestampsUnaltered
    };

    await ax.post("testtagdata/tagvalue", parms);
  });
}
