export type PublishTagValueRequest = {
  customerID: number | null;
  parentID: number | null;
  tagID: number | null;
  dataValue: string | null;
  timestamp: Date | null;
  contractDay: Date | null;
  setPointEventType: number | null;
  bypassFormula: boolean;
  useTimestampsUnaltered: boolean;
  setpointID: number | null;
};

export const defaultPublishTagValueRequest = {
  customerID: null,
  parentID: null,
  tagID: null,
  dataValue: null,
  timestamp: null,
  contractDay: null,
  setPointEventType: null,
  bypassFormula: false,
  useTimestampsUnaltered: false,
  setpointID: null,
} as PublishTagValueRequest;
