import { CustomerTypeEnum } from "../customer/Models";

export enum ReportTypeEnum {
  UserLastAccess = 0,
  ReactAppInfo = 1,
}

export type SystemUsageQueryProperties = {
  reportType: ReportTypeEnum;
  timeframe: number;
  startDate: Date | null;
  endDate: Date | null;
  includeElynx: boolean;
  includeNoUsage: boolean;
  customerType: CustomerTypeEnum;
};

export type SystemUsageData = {
  customerID: number | null;
  customerName: string | null;
  cust_type: string | null;
  userID: number | null;
  userLogin?: string | null | undefined;
  userName: string | null;
  userJobTitle?: string | null | undefined;
  userPhoneNumber?: string | null | undefined;
  userEmail: string | null;
  lastAccess?: Date | null | undefined;
  browserName?: string | null | undefined;
  deviceVendor?: string | null | undefined;
  deviceModel?: string | null | undefined;
  deviceType?: string | null | undefined;
  screenOrientation?: string | null | undefined;
  sl_last_access?: Date | null | undefined;
  m5_last_access?: Date | null | undefined;
  r_last_access?: Date | null | undefined;
  api_last_access?: Date | null | undefined;
};
