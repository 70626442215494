import React from "react";
import { isMobile, isTablet, isMobileSafari } from "react-device-detect";
import { Route, Switch } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { Box, useTheme } from "@mui/material";
import { NavDrawerAction } from "../../components/nav-drawer/NavDrawer";
import AppHeader from "../../components/app-header/AppHeader";
import { routes } from "../../routes/routes";
import { AppSettingsActionTypeEnum, useAppSettings } from "../../contexts/app-context/AppContext";
import AccordionNavDrawer from "../../components/nav-drawer/AccordionNavDrawer";

export default function DefaultLayout() {
  const { instance: msalInstance } = useMsal();
  const isPhone = isMobile && !isTablet;
  const theme = useTheme();
  const [appSettings, dispatchAppSettings] = useAppSettings();

  const onNavDrawerAction = (action: NavDrawerAction) => {
    switch (action) {
      case NavDrawerAction.SignOut:
        signOut();
        break;
    }
  };

  const signOut = () => {
    msalInstance.logout();
  };

  const onChangeDarkMode = (isDarkMode: boolean) => {
    dispatchAppSettings({ type: AppSettingsActionTypeEnum.ThemeSetMode, mode: true === isDarkMode ? "dark" : "light" });
  };

  const onChangeNavBarOpen = (navBarOpen: boolean) => {
    dispatchAppSettings({ type: AppSettingsActionTypeEnum.NavDrawerOpenChanged, open: navBarOpen });
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: isMobileSafari ? "-webkit-fill-available" : "100vh",
        overflow: "hidden",
        position: "relative",
        flexDirection: isPhone ? "column" : "row",
      }}
    >
      {/* <ActionsContextProvider> */}
      <AppHeader
        hideMenu={isPhone}
        onToggleDrawer={() => onChangeNavBarOpen(!appSettings.navDrawerOpen)}
        isDarkMode={appSettings.themeSet.mode === "dark"}
        onChangeDarkMode={onChangeDarkMode}
      />

      <AccordionNavDrawer
        open={appSettings.navDrawerOpen}
        onAction={onNavDrawerAction}
        onClose={() => {
          onChangeNavBarOpen(false);
        }}
      />

      {/* Main Panel */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          overflow: isPhone ? "scroll" : "hidden",
          flexBasis: "auto",
          // marginBottom: isPhone ? theme.spacing(7) : undefined,
        }}
      >
        <Box
          sx={{
            minHeight: 56,
            [theme.breakpoints.up("sm")]: {
              minHeight: 64,
            },
          }}
        />

        <Switch>
          {routes.map((route, index) => (
            <Route key={index} path={`${route.fullPath}`}>
              {<route.component />}
            </Route>
          ))}
        </Switch>
      </Box>
    </Box>
  );
}
