import { Button } from "@mui/material";
import { ICellRendererParams, RowEditingStartedEvent, RowEditingStoppedEvent } from "ag-grid-community";
import React, { useState, useEffect } from "react";
import useComponentWillMount from "../../../hooks/use-component-will-mount/UseComponentWillMount";

export type RowEditingActionsRendererProps = {
  enabled: boolean;
  onUpdate: () => void;
} & ICellRendererParams;

export default function RowEditingActionsRenderer({
  api,
  node,
  colDef,
  value,
  onUpdate,
}: RowEditingActionsRendererProps) {
  const enabled = value as boolean;
  const [editing, setEditing] = useState(false);
  const [disabled, setDisabled] = useState(!enabled);

  // custom hook
  useComponentWillMount(() => {
    const editingCells = api.getEditingCells();
    if (editingCells.length !== 0) {
      setDisabled(true);
    }
  });

  useEffect(() => {
    api.addEventListener("rowEditingStarted", onRowEditingStarted);
    api.addEventListener("rowEditingStopped", onRowEditingStopped);

    return () => {
      api.removeEventListener("rowEditingStarted", onRowEditingStarted);
      api.removeEventListener("rowEditingStopped", onRowEditingStopped);
    };
  }, []);

  function onRowEditingStarted(event: RowEditingStartedEvent) {
    if (node === event.node) {
      setEditing(true);
    } else {
      setDisabled(true);
    }
  }

  function onRowEditingStopped(event: RowEditingStoppedEvent) {
    if (node === event.node) {
      setEditing(false);
    } //else {
      setDisabled(!enabled);
    //}
  }

  function startEditing() {
    if (null !== node.rowIndex) {
      api.startEditingCell({
        rowIndex: node.rowIndex,
        colKey: colDef?.colId ?? "",
      });
    }
  }

  function stopEditing(cancel: boolean) {
    //if cancel is false,
    //call the onUpdate function that was passed in
    if (false === cancel && onUpdate) {
      onUpdate();
    }
    api.stopEditing(cancel);
  }

  return (
    <>
      {editing ? (
        <>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={() => stopEditing(true)}
            disabled={disabled}
          >
            Cancel
          </Button>
          <Button
            sx={{ marginLeft: "5px" }}
            size="small"
            color="secondary"
            variant="contained"
            onClick={() => stopEditing(false)}
            disabled={disabled}
          >
            Update
          </Button>
        </>
      ) : (
        <>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={startEditing}
            disabled={disabled}
          >
            Edit
          </Button>
        </>
      )}
    </>
  );
}
