import { Duration } from "date-fns";

export const ParseTimeSpanString = (ts: string): Duration => {
  const components = ts.split(":");

  if (components.length != 3) {
    throw new Error("TimeSpan string must have three numbers separated by colons, e.g. '01:10:00'");
  }

  return {hours: +components[0], minutes: +components[1], seconds: +components[2]};
};