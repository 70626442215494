import { useMutation } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { ExportTagDataRequest } from "./ExportTagDataRequest";

export function useExportTagData() {
  const { axios: ax } = useAxios();

  return useMutation<void, Error, ExportTagDataRequest>(async (request) => {
    const parms = {
      customerId: request.customerID,
      organizationId: request.organizationID,
      groupId: request.groupID,
      sourceDeviceGuids: request.deviceIDs,
      startDateTimeOffset: request.startDate,
      endDateTimeOffset: request.endDate,
      includeDisabledDevices: request.disabledDevices,
      includeHiddenDevices: request.hiddenDevices,
      compressExportData: request.compressFiles,
      includeDynaCardData: request.includeDynacards,
    };

    await ax.post("exportdata/export2", parms);
  });
}
