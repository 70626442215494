import { ThemeOptions } from "@mui/material";
import { ThemeSet } from "./ThemeSet";

//import these AG-Grid styles here and here alone
//the order is significant!
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

// Dark theme is based on these guidelines
// https://material.io/design/color/dark-theme.html

// Color palettes tool
// https://material.io/inline-tools/color/

// Light Theme Colors
// https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=283854&secondary.color=2ac6b4

// Dark Theme Colors
// https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=b7c0cc&secondary.color=76d7ca

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
    custom: {
      chartColors: {
        color1: string;
        color2: string;
        color3: string;
        color4: string;
        color5: string;
      }
    };
  }

  interface PaletteOptions {
    neutral: PaletteOptions["primary"];
    custom: {
      chartColors: {
        color1: string;
        color2: string;
        color3: string;
        color4: string;
        color5: string;
      }
    };
  }

  interface PaletteColor {
    lowContrast?: string;
    mediumContrast?: string;
    highContrast?: string;
  }

  interface SimplePaletteColorOptions {
    lowContrast?: string;
    mediumContrast?: string;
    highContrast?: string;
  }  
}

const light: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      light: "rgba(84, 99, 130, 1)",
      main: "rgba(41, 57, 85, 1)",
      dark: "rgba(0, 19, 44, 1)",
      contrastText: "#fff",
    },
    secondary: {
      light: "rgba(109, 250, 230, 1)",
      main: "rgba(42, 198, 180, 1)",
      dark: "rgba(0, 149, 132, 1)",
      contrastText: "#000",
    },
    neutral: {
      light: "#e0e0e0",
      main: "#bdbdbd",
      dark: "#9e9e9e",
      lowContrast: "#e0e0e0",
      mediumContrast: "#bdbdbd",
      highContrast: "#9e9e9e",
      contrastText: "#000"
    },
    custom: {
      chartColors: {
        color1: "#4CAF50",
        color2: "#00BCD4",
        color3: "#9E9E9E",
        color4: "#FF9800",
        color5: "#673AB7",
      }
    }
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: "none",
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
};

const dark: ThemeOptions = {
  palette: {
    mode: "dark",
    primary: {
      light: "#e9f3ff",
      main: "#b7c0cc",
      dark: "#87909b",
      contrastText: "#000",
    },
    secondary: {
      light: "#a9fffd",
      main: "#76d7ca",
      dark: "#42a599",
      contrastText: "#000",
    },
    neutral: {
      light: "#757575",
      main: "#616161",
      dark: "#424242",
      highContrast: "#757575",
      mediumContrast: "#616161",
      lowContrast: "#424242",
      contrastText: "#fff"
    },
    custom: {
      chartColors: {
        color1: "#4CAF50",
        color2: "#00BCD4",
        color3: "#9E9E9E",
        color4: "#FF9800",
        color5: "#673AB7",
      }
    }
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: "none",
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
};

const appHeader: ThemeOptions = {
  palette: {
    mode: "dark",
    primary: {
      light: "#e9f3ff",
      main: "#b7c0cc",
      dark: "#87909b",
      contrastText: "#000",
    },
    secondary: {
      light: "#a9fffd",
      main: "#76d7ca",
      dark: "#42a599",
      contrastText: "#000",
    },
    neutral: {
      light: "#757575",
      main: "#616161",
      dark: "#424242",
      highContrast: "#757575",
      mediumContrast: "#616161",
      lowContrast: "#424242",
      contrastText: "#fff"
    },
    custom: {
      chartColors: {
        color1: "#4CAF50",
        color2: "#00BCD4",
        color3: "#9E9E9E",
        color4: "#FF9800",
        color5: "#673AB7",
      }
    }
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: "none",
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
};

export default {
  name: "default",
  mode: "light",
  light: {
    main: light,
    appHeader: appHeader,
    grid: "ag-theme-alpine",
    //grid: "ag-theme-balham"
  },
  dark: {
    main: dark,
    appHeader: appHeader,
    grid: "ag-theme-alpine-dark",
    //grid: "ag-theme-balham-dark"
  },
} as ThemeSet;
