import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { ReportScheduleTesterConfig } from "./Models";

//custom query hook
export function useReportTestingConfig() {
  const { axios: ax } = useAxios();

  const queryKey = keys.testingConfig;

  return useQuery<ReportScheduleTesterConfig, Error>(
    queryKey,
    async () => {
      const { data } = await ax.get<ReportScheduleTesterConfig>("schedulingtester/config");
      return data;
    },
    {
      staleTime: 2 * 60 * 1000,
      refetchInterval: 5 * 60 * 1000,
    }
  );
}
