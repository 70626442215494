import React, { useEffect, useState } from "react";
import { FormulaActionEnum, FormulaInfoItem } from "../../../queries/copy-device/Models";
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { AutocompleteOption } from "../../../components/form-components/FormInputAutocomplete";

export type SelectFormulaProperties = {
  frmInfo: FormulaInfoItem;
  frmList: Array<AutocompleteOption>;
  open: boolean;
  onClose: () => void;
  selectFormula: (frmInfo: FormulaInfoItem) => void;
};

export default function SelectFormula({ frmInfo, frmList, open, onClose, selectFormula }: SelectFormulaProperties) {
  const [selectedFormula, setSelectedFormula] = useState<AutocompleteOption>({ id: -1, label: "" });

  //if we have just been opened, reset our selected UoM
  useEffect(() => {
    setSelectedFormula(
      null != frmInfo.dst_frm_name && null != frmInfo.dst_frm_id
        ? { id: frmInfo.dst_frm_id, label: frmInfo.dst_frm_name }
        : { id: -1, label: "" }
    );
  }, [open]);

  const onClick = () => {
    //create a new FormulaInfoItem with the selected ID
    //and send it to the passed in function and close the dialog
    const newFormulaInfo = {
      ...frmInfo,
      dst_frm_id: selectedFormula.id > 0 ? selectedFormula.id : null,
      dst_frm_name: selectedFormula.label,
      uom_action: selectedFormula.id > -1 ? FormulaActionEnum.Copy : FormulaActionEnum.DoNotCopy,
    } as FormulaInfoItem;
    selectFormula(newFormulaInfo);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} scroll="paper" fullWidth={true} maxWidth="sm">
      <DialogTitle>Select Destination Formula</DialogTitle>
      <DialogContent dividers={true}>
        <Autocomplete
          autoHighlight
          size="small"
          options={frmList}
          getOptionLabel={(option) => (option.id > 0 ? `${option.label} [${option.id}]` : "")}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={selectedFormula}
          onChange={(event, value) => {
            if (value) {
              setSelectedFormula(value);
            } else {
              setSelectedFormula({ id: -1, label: "" });
            }
          }}
          sx={{ width: "50ch" }}
          renderInput={(params) => <TextField {...params} label="Destination Formula" />}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="secondary" onClick={onClick}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
