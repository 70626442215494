import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { ConfigurationSettingModel, EnvironmentConfigurationQueryProperties } from "./Models";

//custom query hook
export function useEnvironmentConfiguration({ configType }: EnvironmentConfigurationQueryProperties) {
  const { axios: ax } = useAxios();

  //create the query key from the supplied query properties
  const queryKey = keys.envConfigQuery(configType);

  return useQuery<Array<ConfigurationSettingModel>, Error>(
    queryKey,
    async () => {
      const { data } = await ax.get<Array<ConfigurationSettingModel>>(`environments/current/${configType}`);
      return data;
    },
    {
      staleTime: 2 * 60 * 1000, //we will use data that is slightly stale because the environment configuration doesn't change too often
      refetchInterval: 60 * 1000, //but let's auto-refresh the data
    }
  );
}
