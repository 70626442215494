import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { AffectedValueData, AffectedValuesRequest } from "./Models";

export type UseAffectedValuesParameters = {
  enabled: boolean;
  affectedValuesRequest: AffectedValuesRequest;
};

export function useAffectedValues({ enabled, affectedValuesRequest }: UseAffectedValuesParameters) {
  const { axios: ax } = useAxios();

  //create the query key from the supplied firstLastTagValueRequest
  const queryKey = keys.affectedValues(affectedValuesRequest);

  return useQuery<Array<AffectedValueData>, Error>(
    queryKey,
    async ({ signal }) => {
      //now make the actual data call
      const { data } = await ax.post<Array<AffectedValueData>>("tagvalueaction/affectedvalues", affectedValuesRequest, {
        signal,
      });
      return data;
    },
    {
      enabled: enabled,
    }
  );
}
