import React from "react";
import { ICellRendererParams } from "ag-grid-community";
import { faEye, faEyeSlash } from "@elynx/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@elynx/react-fontawesome";
import { Typography, useTheme } from "@mui/material";

export type ObjectVisibleRendererProps = {
  shouldDisplay: boolean | ((data: unknown) => boolean);
} & ICellRendererParams;

export default function ObjectVisibleCellRenderer({ shouldDisplay, node, value }: ObjectVisibleRendererProps) {
  const theme = useTheme();

  return (
    <>
      {true === (typeof shouldDisplay === "function" ? shouldDisplay(node.data) : shouldDisplay) && (
        <>
          <FontAwesomeIcon
            icon={true === value ? faEye : faEyeSlash}
            size="lg"
            color={true === value ? theme.palette.success.main : theme.palette.text.primary}
          />
          <Typography
            variant="body2"
            sx={{ paddingLeft: ".5em", display: "inline-block" }}
            color={true === value ? theme.palette.success.main : theme.palette.text.primary}
          >
            {true === value ? "Visible" : "Hidden"}
          </Typography>
        </>
      )}
    </>
  );
}
