import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { DeviceCopyJob } from "./Models";
import { keys } from "./Keys";
import { useQuery } from "react-query";

export type UseDeviceCopyJobStateParameters = {
  enabled: boolean;
  jobID: number;
  autoRefreshSeconds: number;
};

export function useDeviceCopyJobState({ enabled, jobID, autoRefreshSeconds }: UseDeviceCopyJobStateParameters) {
  const { axios: ax } = useAxios();

  //create the query key from the supplied logQueryProperties
  const queryKey = keys.job(jobID);

  return useQuery<DeviceCopyJob, Error>(
    queryKey,
    async ({ signal }) => {
      const { data } = await ax.get<DeviceCopyJob>("devcpy/job", {
        signal,
        params: {
          jobID: jobID,
        },
      });
      return data;
    },
    {
      enabled: enabled,
      refetchInterval: autoRefreshSeconds > 0 ? autoRefreshSeconds * 1000 : false,
      refetchIntervalInBackground: true,
    }
  );
}
