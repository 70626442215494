import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { RunScheduledReportsRequest } from "./RunScheduledReportsRequest";

export type UseRunScheduledReportParameters = {
  enabled: boolean;
  autoRefreshSeconds: number;
  scheduledReportRequest: RunScheduledReportsRequest;
  onSuccess: () => void;
  onError: (err: Error) => void;
};

export function useRunScheduledReport({
  enabled,
  autoRefreshSeconds,
  scheduledReportRequest,
  onSuccess,
  onError,
}: UseRunScheduledReportParameters) {
  const { axios: ax } = useAxios();

  //create the query key from the supplied query properties
  const queryKey = keys.scheduledReportRequest(scheduledReportRequest);

  return useQuery<void, Error>(
    queryKey,
    async () => {
      await ax.post("schedulingtester/run", scheduledReportRequest);
    },
    {
      enabled: enabled,
      refetchInterval: autoRefreshSeconds > 0 ? autoRefreshSeconds * 1000 : false,
      refetchIntervalInBackground: true,
      onSuccess: () => {
        if (onSuccess) onSuccess();
      },
      onError: (err) => {
        if (onError) onError(err);
      },
    }
  );
}
