import React, { useCallback, useEffect, useReducer, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  useTheme,
  Stack,
  Autocomplete,
  TextField,
  MenuItem,
} from "@mui/material";
import { DeviceCopyJob, defaultDeviceCopyJob } from "../../queries/copy-device/Models";
import { CopyDeviceJobActionTypeEnum, copyDeviceJobActionReducer } from "./CopyDeviceJobActions";
import { useCustomerList } from "../../queries/customer/UseCustomerList";
import { AutocompleteOption } from "../../components/form-components/FormInputAutocomplete";
import { ETDOrganizationData } from "../../queries/tag-data-export/Models";
import { useETDOrganizationData } from "../../queries/tag-data-export/UseETDOrganizationData";
import { useSaveDeviceCopyJob } from "../../queries/copy-device/UseSaveDeviceCopyJob";
import { useAlert } from "react-alert";

export type NewCopyJobProps = {
  dialogOpen: boolean;
  onClose: () => void;
  onNewJob: (job: DeviceCopyJob) => void;
};

export default function NewCopyJob({ dialogOpen, onClose, onNewJob }: NewCopyJobProps) {
  const theme = useTheme();
  const alert = useAlert();
  const [customerList, setCustomerList] = useState<Array<AutocompleteOption>>([]);
  const [srcOrganizationList, setSrcOrganizationList] = useState<Array<ETDOrganizationData>>([]);
  const [dstOrganizationList, setDstOrganizationList] = useState<Array<ETDOrganizationData>>([]);
  const [newCopyJob, dispatchNewCopyJob] = useReducer(copyDeviceJobActionReducer, defaultDeviceCopyJob);
  const { data: newCustomerList, isSuccess: isCustomerSuccess } = useCustomerList();
  const { data: newSrcOrganizationList, isSuccess: isSrcOrganizationSuccess } = useETDOrganizationData({
    customerID: newCopyJob.srcCustID ?? 0,
    enabled: newCopyJob.srcCustID != null,
  });
  const { data: newDstOrganizationList, isSuccess: isDstOrganizationSuccess } = useETDOrganizationData({
    customerID: newCopyJob.dstCustID ?? 0,
    enabled: newCopyJob.dstCustID != null,
  });
  const { mutateAsync: saveDeviceCopyJobAsync } = useSaveDeviceCopyJob({});

  const onSaveJob = useCallback(async (job: DeviceCopyJob) => {
    try {
      const savedJob = await saveDeviceCopyJobAsync(job);
      onNewJob(savedJob);
    } catch (error) {
      alert.error(`Error saving new job: ${error}`);
    }
  }, []);

  useEffect(() => {
    //if the dialog is being opened,
    //start with a blank new job
    if (dialogOpen) {
      dispatchNewCopyJob({
        type: CopyDeviceJobActionTypeEnum.Reset,
      });
    }
  }, [dialogOpen]);

  useEffect(() => {
    if (isCustomerSuccess === true && newCustomerList && newCustomerList.length > 0) {
      setCustomerList(
        newCustomerList
          .filter((c) => c.customerEnabled === true)
          .map((c) => {
            return { id: c.customerID, label: `${c.customerID} - ${c.customerName}` };
          })
      );
    }
  }, [isCustomerSuccess, newCustomerList]);

  useEffect(() => {
    if (isSrcOrganizationSuccess === true && newSrcOrganizationList && newSrcOrganizationList.length > 0) {
      setSrcOrganizationList(
        newSrcOrganizationList.sort((a, b) => a.organizationName.localeCompare(b.organizationName))
      );
    }
  }, [newSrcOrganizationList, isSrcOrganizationSuccess]);

  useEffect(() => {
    if (isDstOrganizationSuccess === true && newDstOrganizationList && newDstOrganizationList.length > 0) {
      setDstOrganizationList(
        newDstOrganizationList.sort((a, b) => a.organizationName.localeCompare(b.organizationName))
      );
    }
  }, [newDstOrganizationList, isDstOrganizationSuccess]);

  return (
    <Dialog
      open={dialogOpen}
      onClose={onClose}
      fullWidth={true}
      maxWidth="sm"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Create New Copy Job</DialogTitle>
      <DialogContent dividers={true}>
        <Stack
          direction="column"
          spacing={1}
          sx={{
            backgroundColor: theme.palette.neutral.lowContrast,
            flexWrap: "wrap",
            padding: "6px",
            rowGap: "6px",
            alignItems: "center",
          }}
        >
          <Autocomplete
            autoHighlight
            size="small"
            options={customerList}
            onChange={(event, value) => {
              if (value) {
                dispatchNewCopyJob({
                  type: CopyDeviceJobActionTypeEnum.SetSrcCustomer,
                  customerID: value.id,
                });
              }
            }}
            sx={{ width: "50ch" }}
            renderInput={(params) => <TextField {...params} label="Source Customer" />}
          />
          <TextField
            size="small"
            select
            variant="filled"
            label="Source Organization"
            value={newCopyJob?.srcOrgID ?? ""}
            onChange={(e) =>
              dispatchNewCopyJob({
                type: CopyDeviceJobActionTypeEnum.SetSrcOrganization,
                organizationID: +e.target.value,
              })
            }
            sx={{ width: "50ch" }}
          >
            {srcOrganizationList?.map((o, inx) => (
              <MenuItem key={inx} value={o.organizationID}>
                {o.organizationNameAndID}
              </MenuItem>
            ))}
          </TextField>
          <Autocomplete
            autoHighlight
            size="small"
            options={customerList}
            onChange={(event, value) => {
              if (value) {
                dispatchNewCopyJob({
                  type: CopyDeviceJobActionTypeEnum.SetDstCustomer,
                  customerID: value.id,
                });
              }
            }}
            sx={{ width: "50ch" }}
            renderInput={(params) => <TextField {...params} label="Destination Customer" />}
          />
          <TextField
            size="small"
            select
            variant="filled"
            label="Destination Organization"
            value={newCopyJob?.dstOrgID ?? ""}
            onChange={(e) =>
              dispatchNewCopyJob({
                type: CopyDeviceJobActionTypeEnum.SetDstOrganization,
                organizationID: +e.target.value,
              })
            }
            sx={{ width: "50ch" }}
          >
            {dstOrganizationList?.map((o, inx) => (
              <MenuItem key={inx} value={o.organizationID}>
                {o.organizationNameAndID}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button
          onClick={() => onSaveJob(newCopyJob)}
          color="secondary"
          disabled={
            !(newCopyJob && newCopyJob.srcCustID && newCopyJob.srcOrgID && newCopyJob.dstCustID && newCopyJob.dstOrgID)
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
