import { LogQueryProperties } from "../../queries/log-data/Models";

//these are the strings used by the reducer
export enum LogViewerActionTypeEnum {
  SetMaxCount = "set-max-count",
  SetTimeframe = "set-timeframe",
  SetStartTime = "set-start-time",
  SetEndTime = "set-end-time",
  SetSeverityLevel = "set-severity-level",
  SetSeverityMinLevel = "set-severity-min-level",
  SetMessageText = "set-message-text",
  SetRegexMessage = "set-regex-message",
  SetExcludeMessage = "set-exclude-message",
  SetRegexExcludeMessage = "set-regex-exclude-message",
  SetMachineName = "set-machine-name",
  SetRegexMachineName = "set-regex-machine-name",
  SetRequestDuration = "set-request-duration",
  SetLoggerNames = "set-logger-names",
}

//define a type per action to enforce typing very strictly
export type SetMaxCountAction = {
  type: LogViewerActionTypeEnum.SetMaxCount;
  maxCount: number;
};

export type SetTimeframeAction = {
  type: LogViewerActionTypeEnum.SetTimeframe;
  timeframe: number;
};

export type SetStartTimeAction = {
  type: LogViewerActionTypeEnum.SetStartTime;
  startTime: Date;
};

export type SetEndTimeAction = {
  type: LogViewerActionTypeEnum.SetEndTime;
  endTime: Date;
};

export type SetSeverityLevelAction = {
  type: LogViewerActionTypeEnum.SetSeverityLevel;
  level: number;
};

export type SetSeverityMinLevelAction = {
  type: LogViewerActionTypeEnum.SetSeverityMinLevel;
  isMinLevel: boolean;
};

export type SetMessageTextAction = {
  type: LogViewerActionTypeEnum.SetMessageText;
  messageText: string;
};

export type SetRegexMessageAction = {
  type: LogViewerActionTypeEnum.SetRegexMessage;
  isRegEx: boolean;
};

export type SetExcludeMessageAction = {
  type: LogViewerActionTypeEnum.SetExcludeMessage;
  excludeText: string;
};

export type SetRegexExcludeMessageAction = {
  type: LogViewerActionTypeEnum.SetRegexExcludeMessage;
  isRegEx: boolean;
};

export type SetMachineNameAction = {
  type: LogViewerActionTypeEnum.SetMachineName;
  machineName: string;
};

export type SetRegexMachineNameAction = {
  type: LogViewerActionTypeEnum.SetRegexMachineName;
  isRegEx: boolean;
};

export type SetRequestDurationAction = {
  type: LogViewerActionTypeEnum.SetRequestDuration;
  duration: number;
};

export type SetLoggerNamesAction = {
  type: LogViewerActionTypeEnum.SetLoggerNames;
  loggerNames: Array<string>;
};

//an action sent to the reducer can be any of the above individual actions
export type LogViewerActions =
  | SetMaxCountAction
  | SetTimeframeAction
  | SetStartTimeAction
  | SetEndTimeAction
  | SetSeverityLevelAction
  | SetSeverityMinLevelAction
  | SetMessageTextAction
  | SetRegexMessageAction
  | SetExcludeMessageAction
  | SetRegexExcludeMessageAction
  | SetMachineNameAction
  | SetRegexMachineNameAction
  | SetRequestDurationAction
  | SetLoggerNamesAction;

//and now the reducer itself
export const logViewerActionReducer = (
  state: LogQueryProperties,
  action: LogViewerActions
): LogQueryProperties => {
  switch (action.type) {
    case LogViewerActionTypeEnum.SetMaxCount:
      return {
        ...state,
        maxCount: action.maxCount,
      };
    case LogViewerActionTypeEnum.SetTimeframe:
      return {
        ...state,
        timeframe: action.timeframe,
      };
    case LogViewerActionTypeEnum.SetStartTime:
      return {
        ...state,
        startTime: action.startTime,
      };
    case LogViewerActionTypeEnum.SetEndTime:
      return {
        ...state,
        endTime: action.endTime,
      };
    case LogViewerActionTypeEnum.SetSeverityLevel:
      return {
        ...state,
        severityLevel: action.level,
      };
    case LogViewerActionTypeEnum.SetSeverityMinLevel:
      return {
        ...state,
        severityMinLevel: action.isMinLevel,
      };
    case LogViewerActionTypeEnum.SetMessageText:
      return {
        ...state,
        message: action.messageText,
      };
    case LogViewerActionTypeEnum.SetRegexMessage:
      return {
        ...state,
        regExMessage: action.isRegEx,
      };
    case LogViewerActionTypeEnum.SetExcludeMessage:
      return {
        ...state,
        excludeMessage: action.excludeText,
      };
    case LogViewerActionTypeEnum.SetRegexExcludeMessage:
      return {
        ...state,
        regExExcludeMessage: action.isRegEx,
      };
    case LogViewerActionTypeEnum.SetMachineName:
      return {
        ...state,
        machineName: action.machineName,
      };
    case LogViewerActionTypeEnum.SetRegexMachineName:
      return {
        ...state,
        regExMachineName: action.isRegEx,
      };
    case LogViewerActionTypeEnum.SetRequestDuration:
      return {
        ...state,
        querySeconds: action.duration,
      };
    case LogViewerActionTypeEnum.SetLoggerNames:
      return {
        ...state,
        loggerNames: action.loggerNames,
      };
    default:
      return state;
  }
};
