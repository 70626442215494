import React, { useCallback, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { useToastNotification } from "../../contexts/toast-notification/ToastNotificationContext";
import { useCalcFKVs } from "../../queries/tag-value/UseCalcFKVs";
import { FormInputCheckbox } from "../../components/form-components/FormInputCheckbox";

export type CalcFKVDialogProperties = {
  open: boolean;
  onClose: () => void;
  customerId: number;
};

type CalcFKVDialogFormInput = {
  replaceExisting: boolean;
};

export default function CalcFKVDialog({ open, onClose, customerId }: CalcFKVDialogProperties) {
  const { control, handleSubmit, reset } = useForm<CalcFKVDialogFormInput>();
  const { displayToast } = useToastNotification();
  const { mutate: calcFKVs } = useCalcFKVs();

  //whenever the user property changes, reset the values of our fields
  useEffect(() => {
    reset();
  }, [customerId]);

  const onClick = useCallback(
    (data: CalcFKVDialogFormInput) => {
      calcFKVs(
        { customerId: customerId, replaceExisting: data?.replaceExisting ?? false },
        {
          onSuccess: () =>
            displayToast({
              message: `Started First Known Value calculation job for Customer ID ${customerId}`,
              severity: "success",
            }),
          onError: (error) =>
            displayToast({
              message: `Error running First Known Value calculation job for Customer ID ${customerId}: ${error}`,
              severity: "error",
              duration: 15000,
            }),
        }
      );
      onClose();
    },
    [customerId]
  );

  return (
    <Dialog open={open} onClose={onClose} scroll="paper" fullWidth={true} maxWidth="sm">
      <DialogTitle>Recalculate First Known Values</DialogTitle>
      <DialogContent dividers={true}>
        <FormInputCheckbox name="replaceExisting" label="Replace existing values" control={control} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="secondary" onClick={handleSubmit(onClick)}>
          Run
        </Button>
      </DialogActions>
    </Dialog>
  );
}
