import React from "react";
import ContentHeader from "../../components/content-header/ContentHeader";
import ContentView from "../../components/content-view/ContentView";
import LogDataViewer from "../../components/log-data-viewer/LogDataViewer";

export default function LogViewer() {
  return (
    <ContentView>
      <ContentHeader title={"Log Viewer"} />
      <LogDataViewer />
    </ContentView>
  );
}
