import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, CircularProgress, MenuItem, Stack, TextField, useTheme } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import ContentHeader from "../../components/content-header/ContentHeader";
import ContentView from "../../components/content-view/ContentView";
import { AgGridReact } from "ag-grid-react";
import { useAppSettings } from "../../contexts/app-context/AppContext";
import { ColDef } from "ag-grid-community";
import { useCacheConfigurations } from "../../queries/cache-configuration/UseCacheConfigurations";
import { CacheConfiguration } from "../../queries/cache-configuration/Models";
import ActionButtonCellRenderer, {
  ActionButtonRendererProps,
} from "../../components/ag-grid-extensions/renderers/ActionButtonCellRenderer";
import { useUserRole } from "../../contexts/user-role-context/UserRoleContext";
import ClearCache from "./ClearCache";
import { useCustomerList } from "../../queries/customer/UseCustomerList";
import { AutocompleteOption } from "../../components/form-components/FormInputAutocomplete";

export default function CacheConfigurationList() {
  const theme = useTheme();
  const gridRef = useRef<AgGridReact>(null);
  const [appSettings] = useAppSettings();
  const gridTheme = appSettings.themeSet[appSettings.themeSet.mode].grid;
  const { isOpsCenterWriterMember } = useUserRole();
  const [cacheConfigurations, setCacheConfigurations] = useState<Array<CacheConfiguration>>([]);
  const [autoRefreshSeconds, setAutoRefreshSeconds] = useState(0);
  const currentCacheConfigurationRef = useRef<CacheConfiguration | null>(null);
  const [clearCacheDialogOpen, setClearCacheDialogOpen] = useState(false);
  const {
    data: newCacheConfigurations,
    isSuccess,
    isFetching: isFetchingCacheConfigurations,
    refetch: refetchCacheConfigurations,
  } = useCacheConfigurations({ autoRefreshSeconds: autoRefreshSeconds });
  const [customerList, setCustomerList] = useState<Array<AutocompleteOption>>();
  const { data: newCustomerList, isSuccess: isCustomerSuccess } = useCustomerList();

  //if we get a new configuration list, put it in our state
  useEffect(() => {
    if (newCacheConfigurations) {
      setCacheConfigurations(newCacheConfigurations);
    }
  }, [isSuccess, newCacheConfigurations]);

  useEffect(() => {
    if (isCustomerSuccess === true && newCustomerList && newCustomerList.length > 0) {
      setCustomerList(
        newCustomerList
          .filter((c) => c.customerEnabled === true)
          .map((c) => {
            return { id: c.customerID, label: `${c.customerID} - ${c.customerName}` };
          })
      );
    }
  }, [isCustomerSuccess, newCustomerList]);

  const onClearButtonClicked = useCallback((config: CacheConfiguration) => {
    currentCacheConfigurationRef.current = config;
    setClearCacheDialogOpen(true);
  }, []);

  const columnDefs = useMemo<Array<ColDef>>(() => {
    return [
      {
        colId: "clearCache",
        headerName: "Clear Cache",
        cellRenderer: ActionButtonCellRenderer,
        cellRendererParams: {
          disabled: !isOpsCenterWriterMember,
          buttonText: "Clear",
          shouldDisplay: (data: CacheConfiguration) => {
            return (data.managed && data.managed === true)
                    || (data.connectionString && data.connectionString.length > 0)
                    || data.cacheID.startsWith("group-functionality");
          },
          onClick: onClearButtonClicked,
        } as ActionButtonRendererProps,
      },
      {
        headerName: "Cache ID",
        field: "cacheID",
        sort: "asc",
      },
      {
        headerName: "Managed",
        field: "managed",
      },
      {
        headerName: "Can Fetch All Subkeys",
        field: "canFetchAllSubkeys",
      },
      {
        headerName: "L1 Cache TTL",
        field: "l1CacheTTL",
      },
      {
        headerName: "L2 Cache TTL",
        field: "l2CacheTTL",
      },
    ];
  }, [isOpsCenterWriterMember]);

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: false,
      resizable: true,
    };
  }, []);

  const autoSizeColumns = useCallback(() => {
    gridRef.current?.api.autoSizeAllColumns();
  }, []);

  return (
    <ContentView>
      <ContentHeader title={"Cache Configuration"} />
      <Stack
        direction="row"
        spacing={3}
        sx={{
          backgroundColor: theme.palette.neutral.lowContrast,
          flexWrap: "wrap",
          padding: "6px",
          rowGap: "6px",
          alignItems: "center",
        }}
      >
        <Box sx={{ "& > button": { m: 1 }, display: "flex", width: "14ch", marginLeft: "auto !important" }}>
          <Button
            onClick={() => refetchCacheConfigurations()}
            endIcon={isFetchingCacheConfigurations ? <CircularProgress color="inherit" size="1em" /> : <RefreshIcon />}
            variant="contained"
          >
            Refresh
          </Button>
        </Box>
        <TextField
          size="small"
          select
          variant="filled"
          label="Auto-refresh"
          value={autoRefreshSeconds}
          onChange={(e) => setAutoRefreshSeconds(+e.target.value)}
          sx={{ width: "10ch", marginRight: ".5em !important" }}
        >
          <MenuItem value={0}>Off</MenuItem>
          <MenuItem value={30}>30s</MenuItem>
          <MenuItem value={60}>1m</MenuItem>
          <MenuItem value={300}>5m</MenuItem>
          <MenuItem value={900}>15m</MenuItem>
        </TextField>
      </Stack>

      {/* It appears that in order to make the grid fit into a flex scheme 
      it needs to be contained by a div with a hard size (calculated by flex, in this case) that it can fill completely */}
      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          "& .ag-theme-alpine .ag-cell-value": {
            lineHeight: "20px !important",
            wordBreak: "normal",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
          "& .ag-theme-alpine-dark .ag-cell-value": {
            lineHeight: "20px !important",
            wordBreak: "normal",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
        }}
      >
        <div className={gridTheme} style={{ display: "flex", flex: "1 1 auto" }}>
          <AgGridReact
            ref={gridRef}
            defaultColDef={defaultColDef}
            rowData={cacheConfigurations}
            columnDefs={columnDefs}
            ensureDomOrder={true}
            enableCellTextSelection={true}
            onFirstDataRendered={autoSizeColumns}
            onGridReady={autoSizeColumns}
            onModelUpdated={autoSizeColumns}
            containerStyle={{ width: "100%" }}
          />
        </div>
      </Box>

      {/* This dialog shows the clear cache dialog */}
      {currentCacheConfigurationRef.current && customerList && (
        <ClearCache
          cacheConfiguration={currentCacheConfigurationRef.current}
          open={clearCacheDialogOpen}
          onClose={() => setClearCacheDialogOpen(false)}
          customers={customerList}
        />
      )}
    </ContentView>
  );
}
