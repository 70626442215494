import { useMutation, useQueryClient } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { CustomerModel } from "./Models";

export type UpdateCustomerParameters = {
  customer: CustomerModel
};

export function useUpdateCustomer() {
  const { axios: ax } = useAxios();
  const queryClient = useQueryClient();

  return useMutation<boolean, Error, CustomerModel>(
    async (customer) => {
      const { data } = await ax.post<boolean>("customer", customer);
      return data;
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(keys.all);
      }
    }
  );
}