import React, { createContext, PropsWithChildren, useContext, useEffect, useReducer } from "react";
import useLocalStorage from "../../hooks/use-local-storage/UseLocalStorage";
import themeSet from "../../themes/Default";
import { ThemeMode, ThemeSet } from "../../themes/ThemeSet";

const appSettingsVersion = 1;

export type AppSettings = {
  version: number;
  themeSet: ThemeSet;
  maximizeContent: boolean;
  sideSheetOpen: boolean;
  navDrawerOpen: boolean;
  singleAccordionOnly: boolean;
};

export enum AppSettingsActionTypeEnum {
  ThemeSet = "themeSet",
  ThemeSetMode = "themeSet.mode",
  MaximizeContent = "maximizeContent",
  SideSheetOpenChanged = "SIDE_SHEET_OPEN_CHANGED",
  NavDrawerOpenChanged = "NavBarOpenChanged",
  SingleAccordionOnlyChanged = "SingleAccordionOnlyChanged",
}

export type ThemeSetAction = {
  type: AppSettingsActionTypeEnum.ThemeSet;
  themeSet: ThemeSet;
};

export type ThemeSetModeAction = {
  type: AppSettingsActionTypeEnum.ThemeSetMode;
  mode: ThemeMode;
};

export type MaximizeAction = {
  type: AppSettingsActionTypeEnum.MaximizeContent;
  on: boolean;
};

export type SideSheetOpenChangedAction = {
  type: AppSettingsActionTypeEnum.SideSheetOpenChanged;
  open: boolean;
};

export type NavDrawerOpenChangedAction = {
  type: AppSettingsActionTypeEnum.NavDrawerOpenChanged;
  open: boolean;
};

export type SingleAccordionOnlyChangedAction = {
  type: AppSettingsActionTypeEnum.SingleAccordionOnlyChanged;
  singleOnly: boolean;
};

export type AppSettingsAction =
  | ThemeSetAction
  | ThemeSetModeAction
  | MaximizeAction
  | SideSheetOpenChangedAction
  | NavDrawerOpenChangedAction
  | SingleAccordionOnlyChangedAction;

const reducer = (state: AppSettings, action: AppSettingsAction): AppSettings => {
  switch (action.type) {
    case AppSettingsActionTypeEnum.ThemeSet:
      return {
        ...state,
        themeSet: action.themeSet,
      };
    case AppSettingsActionTypeEnum.ThemeSetMode:
      return {
        ...state,
        themeSet: {
          ...state.themeSet,
          mode: action.mode,
        },
      };

    case AppSettingsActionTypeEnum.MaximizeContent:
      return {
        ...state,
        maximizeContent: action.on,
      };

    case AppSettingsActionTypeEnum.SideSheetOpenChanged:
      return {
        ...state,
        sideSheetOpen: action.open,
      };

    case AppSettingsActionTypeEnum.NavDrawerOpenChanged:
      return {
        ...state,
        navDrawerOpen: action.open,
      };

      case AppSettingsActionTypeEnum.SingleAccordionOnlyChanged:
        return {
          ...state,
          singleAccordionOnly: action.singleOnly,
        };
  
      default:
      return state;
  }
};

// export type AppContextProviderProps = {
//   children: ReactNode,
// };

export const defaultAppSettings: AppSettings = {
  version: appSettingsVersion,
  themeSet: themeSet,
  maximizeContent: false,
  sideSheetOpen: false,
  navDrawerOpen: false,
  singleAccordionOnly: false,
};

const AppContext = createContext<[AppSettings, (action: AppSettingsAction) => void] | undefined>(undefined);

export function AppContextProvider({ children }: PropsWithChildren<unknown>) {
  const [currentAppSettings, setCurrentAppSettings] = useLocalStorage<AppSettings>("appSettings", defaultAppSettings);

  const [appSettings, dispatchAppSettings] = useReducer(reducer, currentAppSettings);

  // Whenever appSettings changes store it in storage
  useEffect(() => {
    setCurrentAppSettings(appSettings);
  }, [appSettings]);

  return <AppContext.Provider value={[appSettings, dispatchAppSettings]}>{children}</AppContext.Provider>;
}

export function useAppSettings() {
  const context = useContext<[AppSettings, (action: AppSettingsAction) => void] | undefined>(AppContext);
  if (!context) {
    throw new Error("useAppSettings must be used within a AppContextProvider");
  }
  return context;
}
