import React, { PropsWithChildren } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export type SideSheetHeaderProps = {
  title: string;
  showCloseButton: boolean;
  onClose?: () => void;
};

export default function SideSheetHeader({
  title,
  showCloseButton,
  onClose,
}: PropsWithChildren<SideSheetHeaderProps>) {
  return (
    <Stack
      alignItems="center"
      direction="row"
      sx={{ marginTop: "auto", flexGrow: 0 }}
    >
      <Typography variant="h6">{title}</Typography>
      {showCloseButton && (
        <IconButton
          sx={{
            marginLeft: "auto",
          }}
          onClick={() => {
            onClose && onClose();
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Stack>
  );
}
