import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import { Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import {
  DeviceCopyJob,
  FormulaActionEnum,
  LookupListActionEnum,
  TatGroupActionEnum,
  UomActionEnum,
  defaultDeviceCopyJob,
} from "../../../queries/copy-device/Models";
import StepMenu, { StepDefinition } from "../../../components/step-menu/StepMenu";
import {
  SetButtonDisabledFunctionType,
  StepMenuEditModeEnum,
  useStepMenuState,
} from "../../../components/step-menu/StepMenuState";
import { useSaveDeviceCopyJob } from "../../../queries/copy-device/UseSaveDeviceCopyJob";
import { useAlert } from "react-alert";
import { CopyDeviceJobActionTypeEnum, CopyDeviceJobActions, copyDeviceJobActionReducer } from "../CopyDeviceJobActions";
import { useRunDeviceCopyJob } from "../../../queries/copy-device/UseRunDeviceCopyJob";
import { useDeviceCopyJobState } from "../../../queries/copy-device/UseDeviceCopyJobState";
import ContentContainer from "../../../components/content-container/ContentContainer";
import SelectDevicesStep from "./SelectDevicesStep";
import MapTatsStep from "./MapTatsStep";
import MapUomsStep from "./MapUomsStep";
import MapFormulaeStep from "./MapFormulaeStep";
import MapLookupListsStep from "./MapLookupListsStep";
import MapTatGroupsStep from "./MapTatGroupStep";

export type ConfigureCopyJobProps = {
  dialogOpen: boolean;
  jobID: number;
  onClose: () => void;
  onJobStarted: () => void;
};

export type ConfigureJobStepProps = {
  dialogOpen: boolean;
  job: DeviceCopyJob;
  dispatchJob: React.Dispatch<CopyDeviceJobActions>;
  setNextButtonDisabled: SetButtonDisabledFunctionType;
};

export enum StepEnum {
  SelectDevices = 1,
  MapTats = 2,
  MapUoMs = 3,
  MapFormulae = 4,
  MapLookupLists = 5,
  MapTatGroups = 6,
}

export default function ConfigureCopyJob({ dialogOpen, jobID, onClose, onJobStarted }: ConfigureCopyJobProps) {
  const alert = useAlert();
  const [job, dispatchJob] = useReducer(copyDeviceJobActionReducer, defaultDeviceCopyJob);
  const { data: newJob, isSuccess: isJobSuccess } = useDeviceCopyJobState({
    enabled: dialogOpen && jobID > 0,
    jobID: jobID,
    autoRefreshSeconds: 0,
  });
  const { mutateAsync: saveDeviceCopyJobAsync } = useSaveDeviceCopyJob({});
  const { mutateAsync: runDeviceCopyJobAsync } = useRunDeviceCopyJob({});

  //some objects must be selected, as must some TATs
  //all other maps must be complete
  const devicesValid = (job?.objects?.length ?? 0) > 0;
  const tatMapValid = (job?.tatMappings?.length ?? 0) > 0;
  const uomMapValid =
    (job?.uomMappings?.length ?? 0) > 0 && !job.uomMappings.some((u) => UomActionEnum.DoNotCopy === u.uomAction);
  const formulaMapValid =
    (job?.formulaMappings?.length ?? 0) > 0 &&
    !job.formulaMappings.some((u) => FormulaActionEnum.DoNotCopy === u.frmAction);
  const listMapValid =
    (job?.lookupListMappings?.length ?? 0) > 0 &&
    !job.lookupListMappings.some((u) => LookupListActionEnum.DoNotCopy === u.listAction);
  const tatGroupMapValid =
    (job?.tatGroupMappings?.length ?? 0) > 0 &&
    !job.tatGroupMappings.some((u) => TatGroupActionEnum.DoNotCopy === u.tgAction);

  const stepDefinitions: StepDefinition[] = useMemo(() => {
    return [
      {
        key: StepEnum.SelectDevices,
        title: "Select Devices",
        valid: devicesValid,
      },
      {
        key: StepEnum.MapTats,
        title: "Map TATs",
        disabled: !devicesValid,
        valid: tatMapValid,
      },
      {
        key: StepEnum.MapUoMs,
        title: "Map UoMs",
        disabled: !devicesValid,
        valid: uomMapValid,
      },
      {
        key: StepEnum.MapFormulae,
        title: "Map Formulae",
        disabled: !devicesValid,
        valid: formulaMapValid,
      },
      {
        key: StepEnum.MapLookupLists,
        title: "Map Lookup Lists",
        disabled: !devicesValid,
        valid: listMapValid,
      },
      {
        key: StepEnum.MapTatGroups,
        title: "Map TAT Groups",
        disabled: !devicesValid,
        valid: tatGroupMapValid,
      },
    ];
  }, [devicesValid, tatMapValid, uomMapValid, formulaMapValid, listMapValid, tatGroupMapValid]);

  const {
    state: { landing, steps, activeStep, cancelButtonState, prevButtonState, nextButtonState, saveChangesButtonState },
    setActiveStep,
    setNextButtonDisabled,
    setSaveChangesButtonLabel,
    setSaveChangesButtonDisabled,
    setSaveChangesButtonVisible,
  } = useStepMenuState(StepMenuEditModeEnum.Edit, undefined, stepDefinitions);

  const updateButtonStates = useCallback(() => {
    switch (activeStep) {
      case StepEnum.SelectDevices:
        setNextButtonDisabled(!devicesValid);
        break;

      case StepEnum.MapTats:
        setNextButtonDisabled(!(devicesValid && tatMapValid));
        break;

      case StepEnum.MapUoMs:
        setNextButtonDisabled(!(devicesValid && tatMapValid && uomMapValid));
        break;

      case StepEnum.MapFormulae:
        setNextButtonDisabled(!(devicesValid && tatMapValid && uomMapValid && formulaMapValid));
        break;

      case StepEnum.MapLookupLists:
        setNextButtonDisabled(!(devicesValid && tatMapValid && uomMapValid && formulaMapValid && listMapValid));
        break;

      case StepEnum.MapTatGroups:
        setSaveChangesButtonVisible(true);
        setSaveChangesButtonDisabled(
          !(devicesValid && tatMapValid && uomMapValid && formulaMapValid && listMapValid && tatGroupMapValid)
        );
        setSaveChangesButtonLabel("Run Job");
        break;
    }
  }, [
    setNextButtonDisabled,
    setSaveChangesButtonDisabled,
    setSaveChangesButtonVisible,
    setSaveChangesButtonLabel,
    activeStep,
    job.objects?.length,
    devicesValid,
    tatMapValid,
    uomMapValid,
    formulaMapValid,
    listMapValid,
    tatGroupMapValid,
  ]);

  //if we were (re-)opened, make sure to start at the first step
  useEffect(() => {
    if (dialogOpen) {
      setActiveStep(steps[0].key);
    }
  }, [dialogOpen]);

  //if we get a new device copy job, put it in our state
  useEffect(() => {
    if (newJob) {
      dispatchJob({ type: CopyDeviceJobActionTypeEnum.Reset, newState: newJob });
    }
  }, [isJobSuccess, newJob]);

  //this effect updates step menu button states whenever the job values change
  useEffect(() => {
    updateButtonStates();
  }, [job.objects?.length, updateButtonStates]);

  async function onStepChange(fromStep: number, toStep: number) {
    //before changing steps, save the job to the cloud actor
    //and don't change the state if there is an error
    try {
      await saveDeviceCopyJobAsync(job);
      setActiveStep(toStep);
    } catch (error) {
      alert.error(`Error saving job: ${error}`);
    }
  }

  async function onSaveAndClose() {
    try {
      await saveDeviceCopyJobAsync(job);
      onClose();
    } catch (error) {
      alert.error(`Error saving job: ${error}`);
    }
  }

  async function onRunJob() {
    try {
      await saveDeviceCopyJobAsync(job);
      await runDeviceCopyJobAsync(job);
      onJobStarted();
    } catch (error) {
      alert.error(`Error running job: ${error}`);
    }
  }

  return (
    <Dialog
      open={dialogOpen}
      onClose={onClose}
      fullWidth={true}
      maxWidth={false}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      // sx={{ height: "100%", "& .MuiPaper-root": { height: "100%" } }}
    >
      <DialogTitle id="scroll-dialog-title">{`Configuring job ${job?.jobID}: ${job.jobDescription}`}</DialogTitle>
      <DialogContent dividers={true} sx={{ height: "1200px" }}>
        <Stack direction="column" height="100%">
          <StepMenu
            landing={landing}
            steps={steps}
            title=""
            nonLinear={true}
            activeStep={activeStep}
            cancelButtonState={{ ...cancelButtonState, label: "Save & Close" }}
            prevButtonState={prevButtonState}
            nextButtonState={nextButtonState}
            saveChangesButtonState={saveChangesButtonState}
            onCancel={onSaveAndClose}
            //onSaveAsNew={handleSubmit(() => {} /*onSaveAsNew()*/)}
            onSaveChanges={() => onRunJob()}
            //onCancelStep={onCancelStep}
            onStepChange={onStepChange}
            onStepClick={(step) => onStepChange(activeStep, step.key)}
          >
            <ContentContainer>
              {activeStep === StepEnum.SelectDevices && (
                <SelectDevicesStep
                  dialogOpen={dialogOpen}
                  job={job}
                  dispatchJob={dispatchJob}
                  setNextButtonDisabled={setNextButtonDisabled}
                />
              )}

              {activeStep === StepEnum.MapTats && (
                <MapTatsStep
                  dialogOpen={dialogOpen}
                  job={job}
                  dispatchJob={dispatchJob}
                  setNextButtonDisabled={setNextButtonDisabled}
                />
              )}

              {activeStep === StepEnum.MapUoMs && (
                <MapUomsStep
                  dialogOpen={dialogOpen}
                  job={job}
                  dispatchJob={dispatchJob}
                  setNextButtonDisabled={setNextButtonDisabled}
                />
              )}

              {activeStep === StepEnum.MapFormulae && (
                <MapFormulaeStep
                  dialogOpen={dialogOpen}
                  job={job}
                  dispatchJob={dispatchJob}
                  setNextButtonDisabled={setNextButtonDisabled}
                />
              )}

              {activeStep === StepEnum.MapLookupLists && (
                <MapLookupListsStep
                  dialogOpen={dialogOpen}
                  job={job}
                  dispatchJob={dispatchJob}
                  setNextButtonDisabled={setNextButtonDisabled}
                />
              )}

              {activeStep === StepEnum.MapTatGroups && (
                <MapTatGroupsStep
                  dialogOpen={dialogOpen}
                  job={job}
                  dispatchJob={dispatchJob}
                  setNextButtonDisabled={setNextButtonDisabled}
                />
              )}
            </ContentContainer>
          </StepMenu>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
