import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { UserRoleModel } from "./Models";

//custom query hook
export function useUserRoles() {
  const { axios: ax } = useAxios();

  return useQuery<Array<UserRoleModel>, Error>(
    keys.all,
    async () => {
      const { data } = await ax.get<Array<UserRoleModel>>("userroles");
      return data;
    },
    {
      staleTime: 2 * 60 * 1000, //we will use data that is slightly stale because the user role list doesn't change often
      refetchInterval: 600 * 1000,
    }
  );
}
