import React, { forwardRef } from "react";
import { SvgIcon, SvgIconPropsSizeOverrides, SxProps } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { IconDefinition } from "@elynx/fontawesome-svg-core";

type FontAwesomeSvgIconProps = {
  icon: IconDefinition;
  fontSize?: OverridableStringUnion<"inherit" | "large" | "medium" | "small", SvgIconPropsSizeOverrides>;
  sx?: SxProps;
};

const FontAwesomeSvgIcon = forwardRef<SVGSVGElement, FontAwesomeSvgIconProps>(function FontAwesomeSvgIcon(
  { icon: iconProp, fontSize, sx },
  ref
) {
  const {
    icon: [width, height, , , svgPathData],
  } = iconProp;

  return (
    (<SvgIcon ref={ref} viewBox={`0 0 ${width} ${height}`} fontSize={fontSize} sx={sx}>
      {typeof svgPathData === "string" ? (
        <path d={svgPathData} />
      ) : (
        /**
         * A multi-path Font Awesome icon seems to imply a duotune icon. The 0th path seems to
         * be the faded element (referred to as the "secondary" path in the Font Awesome docs)
         * of a duotone icon. 40% is the default opacity.
         *
         * @see https://fontawesome.com/how-to-use/on-the-web/styling/duotone-icons#changing-opacity
         */
        (svgPathData.map(
          (d: string, i: number) => <path key={i} style={{ opacity: i === 0 ? 0.4 : 1 }} d={d} />
        ))
      )}
    </SvgIcon>)
  );
});

export default FontAwesomeSvgIcon;
