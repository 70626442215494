import { CustomerTypeEnum } from "../customer/Models";

export enum ClientAcessTypeEnum {
  SCADALynx = 1,
  HTML5Mobile = 3,
  ReactApp = 8,
  PublicAPI = 9,
}

export type PageUsageQueryProperties = {
  clientAccessType: ClientAcessTypeEnum;
  timeframe: number;
  startDate: Date | null;
  endDate: Date | null;
  includeElynx: boolean;
  customerType: CustomerTypeEnum;
};

export type PageUsageData = {
  viewName: string;
  tabName?: string | null;
  customerID: number | null;
  customerName: string | null;
  customerType: string | null;
  userLogin: string | null;
  accessCount: number;
  lastAccess: Date | null;
};

export type PageUsageSummary = {
  viewName: string;
  accessCount: number;
  distinctUserCount: number;
};

export type PageUsageResult = {
  pageUsageData: Array<PageUsageData>;
  pageUsageSummary: Array<PageUsageSummary>;
};
