import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Stack,
  Box,
  TextField,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import { TagActionEnum } from "./TagActionEnum";
import {
  AffectedValue,
  AffectedValueData,
  FirstLastTagValueData,
  TagValueChangeRequest,
  TagValueOp,
} from "../../queries/tag-value/Models";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useAppSettings } from "../../contexts/app-context/AppContext";
import { DateTimePicker } from "@mui/x-date-pickers";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useAffectedValues } from "../../queries/tag-value/UseAffectedValues";
import useDebounce from "../../hooks/use-debounce/UseDebounce";
import { useRecalcFormulas } from "../../queries/tag-value/UseRecalcFormulas";
import { useRecalcContractDays } from "../../queries/tag-value/UseRecalcContractDays";
import { useToastNotification } from "../../contexts/toast-notification/ToastNotificationContext";
import { DataTypeEnum } from "../../queries/tag-value/DataTypeEnum";
import { useResetTagValues } from "../../queries/tag-value/UseResetTagValues";
import { FormatTimestampLocal } from "../../utilities/FormatTimestampLocal";
import PreFormattedCellRenderer from "../../components/ag-grid-extensions/renderers/PreFormattedCellRenderer";

export type TagActionDialogProperties = {
  open: boolean;
  onClose: () => void;
  action: TagActionEnum;
  customerId: number;
  tags: Array<FirstLastTagValueData>;
  defaultColDef: ColDef;
  columnDefs: Array<ColDef>;
};

type AffectedValueGridRow = FirstLastTagValueData &
  AffectedValueData & {
    first_values_text: string;
    last_values_text: string;
  };

export default function TagActionDialog({
  open,
  onClose,
  action,
  customerId,
  tags,
  defaultColDef,
  columnDefs,
}: TagActionDialogProperties) {
  const tagGridRef = useRef<AgGridReact>(null);
  const valueGridRef = useRef<AgGridReact>(null);
  const [appSettings] = useAppSettings();
  const gridTheme = appSettings.themeSet[appSettings.themeSet.mode].grid;
  const [newTagValue, setNewTagValue] = useState<string>("");
  const [startTime, setStartTime] = useState<Date | null>(null);
  const debouncedStartTime = useDebounce(startTime, 2000);
  const [endTime, setEndTime] = useState<Date | null>(null);
  const debouncedEndTime = useDebounce(endTime, 2000);
  const [tatDataType, setTatDataType] = useState<DataTypeEnum>(DataTypeEnum.Numeric);
  const [retrieveData, setRetrieveData] = useState(false);
  const [affectedValues, setAffectedValues] = useState<Array<AffectedValueGridRow>>([]);
  const {
    data: newAffectedValues,
    isFetching,
    isSuccess,
  } = useAffectedValues({
    enabled: retrieveData,
    affectedValuesRequest: {
      customerId: customerId,
      tagIDs: tags.map((t) => t.tag_id),
      startTime: debouncedStartTime ?? new Date(),
      endTime: debouncedEndTime ?? new Date(),
    },
  });
  const { displayToast } = useToastNotification();
  const { mutate: recalcFormulas } = useRecalcFormulas();
  const { mutate: recalcContractDays } = useRecalcContractDays();
  const { mutate: resetTagValues } = useResetTagValues();

  const formatValues = useCallback((values: Array<AffectedValue>) => {
    const lines = values.map(
      (v) => `${v.value}\t${FormatTimestampLocal(v.timestamp)}\t${FormatTimestampLocal(v.contractDay)}`
    );
    return lines?.join("\n") ?? "";
  }, []);

  //safety precaution:
  //do not allow the start and end times to persist if either
  //the action or the collection of tags has changed
  useDeepCompareEffect(() => {
    setStartTime(null);
    setEndTime(null);
    setNewTagValue("");

    //set the data type we are dealing with as well
    if (tags?.length > 0) {
      setTatDataType(tags[0].tat_datatype);
    }
  }, [action, customerId, tags]);

  //if the customer, selected tags, or timespan have changed
  //clear out the affected tags so there is no confusion
  //then only enable retrieving affected values
  //if we have everything we need
  useEffect(() => {
    setAffectedValues([]);
    setRetrieveData(open && tags.length > 0 && customerId > 0 && null != startTime && null != endTime);
  }, [open, tags.length, customerId, debouncedStartTime, debouncedEndTime]);

  //save the new data
  useEffect(() => {
    if (newAffectedValues) {
      //join the affected values to the selected tags
      const gridRows = newAffectedValues.map((v) => {
        const tag = tags.find((t) => t.tag_id === v.tagID);
        return {
          ...v,
          ...tag,
          first_values_text: formatValues(v.firstValues),
          last_values_text: formatValues(v.lastValues),
        } as AffectedValueGridRow;
      });
      setAffectedValues(gridRows);
    }
  }, [isSuccess, newAffectedValues, open]);

  const valueColumnDefs = useMemo<Array<ColDef>>(() => {
    const colDefs = columnDefs.filter(
      (c) =>
        c.field !== "fkv_data_value" &&
        c.field !== "fkv_timestamp" &&
        c.field !== "lkv_data_value" &&
        c.field !== "lkv_timestamp"
    );

    return colDefs.concat([
      {
        headerName: "Value Count",
        field: "valueCount",
        minWidth: 120,
        maxWidth: 150,
      },
      {
        headerName: "First Values (value, timestamp, contract day)",
        field: "first_values_text",
        cellRenderer: PreFormattedCellRenderer,
        cellRendererParams: { maxLines: 8 },
        autoHeight: true,
        wrapText: true,
        cellStyle: { wordBreak: "normal", whiteSpace: "pre-wrap" },
      },
      {
        headerName: "Last Values (value, timestamp, contract day)",
        field: "last_values_text",
        cellRenderer: PreFormattedCellRenderer,
        cellRendererParams: { maxLines: 8 },
        autoHeight: true,
        wrapText: true,
        cellStyle: { wordBreak: "normal", whiteSpace: "pre-wrap" },
      },
    ]);
  }, [columnDefs]);

  const autoSizeTagColumns = useCallback(() => {
    tagGridRef.current?.api.autoSizeAllColumns();
  }, []);

  const autoSizeValueColumns = useCallback(() => {
    valueGridRef.current?.api.autoSizeAllColumns();
  }, []);

  const onClick = () => {
    //build request object
    //get unique device IDs
    const deviceIDs = [...new Set(tags.map((t) => t.obj_id))];
    const request = {
      tagValueOp:
        TagActionEnum.RecalcFormulas === action
          ? TagValueOp.ReCalculateFormula
          : TagActionEnum.RecalcContractDays === action
          ? TagValueOp.ReCalculateContractDay
          : TagActionEnum.ResetDataValues === action
          ? TagValueOp.ResetTagValues
          : -1,
      customerID: customerId,
      deviceIDs: deviceIDs,
      tagIDs: tags.map((t) => t.tag_id),
      startTime: debouncedStartTime,
      endTime: debouncedEndTime,
      newTagValue: TagActionEnum.ResetDataValues === action && DataTypeEnum.Numeric === tatDataType ? +newTagValue : 0,
      newTextValue: TagActionEnum.ResetDataValues === action && DataTypeEnum.String === tatDataType ? newTagValue : "",
    } as TagValueChangeRequest;

    const method =
      TagActionEnum.RecalcFormulas === action
        ? recalcFormulas
        : TagActionEnum.RecalcContractDays === action
        ? recalcContractDays
        : TagActionEnum.ResetDataValues === action
        ? resetTagValues
        : null;

    if (null != method) {
      const msg = `${action} for ${tags.length} tags in Customer ID ${customerId} from ${debouncedStartTime} to ${debouncedEndTime}.`;
      method(request, {
        onSuccess: () =>
          displayToast({
            message: `Queued ${msg}`,
            severity: "success",
          }),
        onError: (error) =>
          displayToast({
            message: `Error queueing ${msg}: ${error}`,
            severity: "error",
            duration: 15000,
          }),
      });
    }
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      fullWidth={true}
      maxWidth={false}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{ height: "100%", "& .MuiPaper-root": { height: "100%" } }}
    >
      <DialogTitle id="scroll-dialog-title">
        {action}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogActions>
        {TagActionEnum.ResetDataValues === action && (
          <TextField
            variant="filled"
            size="small"
            label="Data value to be set"
            type={DataTypeEnum.Numeric === tatDataType ? "number" : "text"}
            value={newTagValue}
            onChange={(e) => {
              setNewTagValue(e.target.value);
            }}
            sx={{ width: "20ch" }}
          />
        )}
        <DateTimePicker
          slotProps={{ textField: { variant: "filled", size: "small", sx: { width: "26ch", marginRigth: "2em" } } }}
          label="Start Time"
          value={startTime}
          disabled={tags.length < 1}
          onChange={(newValue: Date | null | undefined) => {
            if (newValue) {
              setStartTime(newValue);
            }
          }}
        />
        <DateTimePicker
          disabled={tags.length < 1}
          slotProps={{ textField: { variant: "filled", size: "small", sx: { width: "26ch", marginRight: "4em" } } }}
          label="End Time"
          value={endTime}
          onChange={(newValue: Date | null | undefined) => {
            if (newValue) {
              setEndTime(newValue);
            }
          }}
        />
        <Box sx={{ marginRight: "1em" }}>
          {isFetching ? <CircularProgress color="inherit" size="1em" /> : <RefreshIcon />}
        </Box>
        {/* <Button onClick={onClose}>Cancel</Button> */}
        <Button
          color="secondary"
          onClick={onClick}
          disabled={
            null == startTime ||
            null == endTime ||
            affectedValues.length == 0 ||
            (newTagValue.length == 0 && TagActionEnum.ResetDataValues === action)
          }
        >
          {action}
        </Button>
      </DialogActions>
      <DialogContent dividers={true}>
        <Stack direction="column" height="100%">
          <Box
            sx={{
              display: "flex",
              // flex: "1 1 auto",
            }}
          >
            {`${tags.length} tags selected`}
          </Box>
          {/* It appears that in order to make the grid fit into a flex scheme 
      it needs to be contained by a div with a hard size (calculated by flex, in this case) that it can fill completely */}
          <Box
            sx={{
              display: "flex",
              flex: "1 1 auto",
              "& .ag-theme-alpine .ag-cell-value": {
                lineHeight: "20px !important",
                wordBreak: "normal",
                paddingTop: "5px",
                paddingBottom: "5px",
              },
              "& .ag-theme-alpine-dark .ag-cell-value": {
                lineHeight: "20px !important",
                wordBreak: "normal",
                paddingTop: "5px",
                paddingBottom: "5px",
              },
            }}
          >
            <div className={gridTheme} style={{ height: "100%", width: "100%" }}>
              <AgGridReact
                ref={tagGridRef}
                defaultColDef={defaultColDef}
                rowData={tags}
                columnDefs={columnDefs}
                ensureDomOrder={true}
                enableCellTextSelection={true}
                onFirstDataRendered={autoSizeTagColumns}
                suppressClickEdit={true}
                suppressCellFocus={true}
                alwaysMultiSort={true}
              />
            </div>
          </Box>
          <Box
            sx={{
              display: "flex",
              marginTop: "2em",
              // flex: "1 1 auto",
            }}
          >
            {"First and last affected tag values"}
          </Box>
          {/* It appears that in order to make the grid fit into a flex scheme 
      it needs to be contained by a div with a hard size (calculated by flex, in this case) that it can fill completely */}
          <Box
            sx={{
              display: "flex",
              flex: "1 1 auto",
              "& .ag-theme-alpine .ag-cell-value": {
                lineHeight: "20px !important",
                wordBreak: "normal",
                paddingTop: "5px",
                paddingBottom: "5px",
              },
              "& .ag-theme-alpine-dark .ag-cell-value": {
                lineHeight: "20px !important",
                wordBreak: "normal",
                paddingTop: "5px",
                paddingBottom: "5px",
              },
            }}
          >
            <div className={gridTheme} style={{ height: "100%", width: "100%" }}>
              <AgGridReact
                ref={valueGridRef}
                defaultColDef={defaultColDef}
                rowData={affectedValues}
                columnDefs={valueColumnDefs}
                ensureDomOrder={true}
                enableCellTextSelection={true}
                onFirstDataRendered={autoSizeValueColumns}
                suppressClickEdit={true}
                suppressCellFocus={true}
                alwaysMultiSort={true}
              />
            </div>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
