import React, { useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { Label, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { EventHubChartData } from "../../queries/event-hub-status/Models";
import { EventHubProcessingMonitorData } from "../../queries/metrics-data/Models";
import { Payload } from "recharts/types/component/DefaultLegendContent";
import { DataKey } from "recharts/types/util/types";

export type EventProcessingChartProps = {
  chartLabel: string;
  valuesProcessed: Array<EventHubChartData>;
  metricsData: Array<EventHubProcessingMonitorData>;
  timestampFormatter: (ts: number) => string;
};

type LineDisplayDescription = {
  axisId: string;
  dataKey: string;
  data: Array<EventHubChartData> | Array<EventHubProcessingMonitorData>;
  color: string;
  name: string;
};

export default function EventProcessingChart({
  chartLabel,
  valuesProcessed,
  metricsData,
  timestampFormatter,
}: EventProcessingChartProps) {
  const theme = useTheme();
  //this array that describes all the lines must be built inside the function
  //since it uses props to specify the data parameters
  const lineDisplayDescriptions: Array<LineDisplayDescription> = [
    {
      axisId: "values",
      dataKey: "value",
      data: valuesProcessed,
      color: theme.palette.custom.chartColors.color1,
      name: "Values Processed",
    },
    {
      axisId: "minutes",
      dataKey: "maxMinutesBehind",
      data: metricsData,
      color: theme.palette.custom.chartColors.color2,
      name: "Max Min Behind",
    },
    {
      axisId: "minutes",
      dataKey: "avgMinutesBehind",
      data: metricsData,
      color: theme.palette.custom.chartColors.color3,
      name: "Avg Min Behind",
    },
    {
      axisId: "messages",
      dataKey: "maxMessagesBehind",
      data: metricsData,
      color: theme.palette.custom.chartColors.color4,
      name: "Max Msgs Behind",
    },
    {
      axisId: "messages",
      dataKey: "avgMessagesBehind",
      data: metricsData,
      color: theme.palette.custom.chartColors.color5,
      name: "Avg Msgs Behind",
    },
  ];

  //build the type DataKeys that is the union of all dataKey strings
  //from our line description array
  const dataKeys = lineDisplayDescriptions.map((d) => d.dataKey);
  type DataKeys = typeof dataKeys[number];

  //extend a Record of DataKeys keys with a hoverKey property
  interface ILineDisplayOptions extends Record<DataKeys, boolean | string | null> {
    hoverKey: string | null;
  }

  //declare a manageable type for the params passed to the legend's mouse even handlers
  type LegendEventHandlerParams = Payload & { dataKey?: DataKey<string> | undefined };

  //the initial state object has a property for each dataKey plus the hoverKey property
  const [lineDisplayOptions, setLineDisplayOptions] = useState<ILineDisplayOptions>(
    lineDisplayDescriptions.reduce<ILineDisplayOptions>(
      (a, { dataKey }) => {
        a[dataKey] = false;
        return a;
      },
      { hoverKey: null }
    )
  );

  //if the line whose dataKey was hovered over is not hidden
  //set it as the hover item
  const handleLegendMouseEnter = (e: LegendEventHandlerParams) => {
    if (e.dataKey && !lineDisplayOptions[e.dataKey.toString()]) {
      setLineDisplayOptions({ ...lineDisplayOptions, hoverKey: e.dataKey.toString() });
    }
  };

  //clear the hover item
  const handleLegendMouseLeave = () => {
    setLineDisplayOptions({ ...lineDisplayOptions, hoverKey: null });
  };

  //toggle the hide value of the line whose legend item was clicked
  //and clear the hover item as well
  const selectLine = (e: LegendEventHandlerParams) => {
    if (e.dataKey) {
      setLineDisplayOptions({
        ...lineDisplayOptions,
        [e.dataKey.toString()]: !lineDisplayOptions[e.dataKey.toString()],
        hoverKey: null,
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: "1 1 auto",
          borderWidth: "3px",
          borderStyle: "solid",
          borderColor: theme.palette.neutral.mediumContrast,
          marginBottom: "5px",
        }}
      >
        <Typography variant="body2" color="#2196F3" sx={{ display: "flex", flex: "0 0 auto", margin: "auto" }}>
          {chartLabel}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
          }}
        >
          <div style={{ width: "100%", height: "100%", position: "relative" }}>
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            >
              <ResponsiveContainer height="100%" width="100%" debounce={100}>
                <LineChart>
                  <Label position="top" value={chartLabel} />
                  <Tooltip
                    wrapperStyle={{ fontSize: ".7rem" }}
                    contentStyle={{ backgroundColor: theme.palette.background.default }}
                    labelFormatter={(value: number) => timestampFormatter(value)}
                  />
                  <Legend
                    onClick={selectLine}
                    onMouseOver={handleLegendMouseEnter}
                    onMouseOut={handleLegendMouseLeave}
                    wrapperStyle={{ fontSize: ".7rem" }}
                  />
                  <XAxis
                    dataKey="tsEpoch"
                    type="number"
                    scale="utc"
                    domain={["dataMin", "dataMax"]}
                    tickFormatter={timestampFormatter}
                    allowDuplicatedCategory={false}
                    interval="preserveStartEnd"
                    padding={{ left: 10, right: 10 }}
                    style={{ fontSize: ".7rem" }}
                  />
                  <YAxis
                    yAxisId="values"
                    dataKey="value"
                    stroke={theme.palette.custom.chartColors.color1}
                    axisLine={true}
                    tickCount={2}
                    interval="preserveStartEnd"
                    allowDecimals={false}
                    orientation="right"
                    style={{ fontSize: ".7rem" }}
                  >
                    <Label
                      value="Values"
                      stroke="#4CAF50"
                      angle={90}
                      position="right"
                      offset={-5}
                      style={{ fontSize: ".7rem" }}
                    />
                  </YAxis>
                  <YAxis
                    yAxisId="minutes"
                    dataKey="maxMinutesBehind"
                    stroke={theme.palette.custom.chartColors.color2}
                    axisLine={true}
                    tickCount={2}
                    interval="preserveStartEnd"
                    allowDecimals={false}
                    style={{ fontSize: ".7rem" }}
                  >
                    <Label
                      value="Minutes"
                      stroke="#00BCD4"
                      angle={-90}
                      position="left"
                      offset={-20}
                      style={{ fontSize: ".7rem" }}
                    />
                  </YAxis>
                  <YAxis
                    yAxisId="messages"
                    dataKey="maxMessagesBehind"
                    stroke={theme.palette.custom.chartColors.color4}
                    axisLine={true}
                    tickCount={2}
                    interval="preserveStartEnd"
                    allowDecimals={false}
                    style={{ fontSize: ".7rem" }}
                  >
                    <Label
                      value="Messages"
                      stroke="#FF9800"
                      angle={-90}
                      position="left"
                      offset={-5}
                      style={{ fontSize: ".7rem" }}
                    />
                  </YAxis>
                  {
                    //generate the lines from the line description array
                    lineDisplayDescriptions.map((desc, inx) => (
                      <Line
                        key={inx}
                        yAxisId={desc.axisId}
                        dataKey={desc.dataKey}
                        data={desc.data}
                        name={desc.name}
                        stroke={desc.color}
                        dot={false}
                        activeDot={{ r: 5 }}
                        hide={true === lineDisplayOptions[desc.dataKey]}
                        strokeOpacity={
                          null === lineDisplayOptions.hoverKey || lineDisplayOptions.hoverKey === desc.dataKey
                            ? 1.0
                            : 0.3
                        }
                      />
                    ))
                  }
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
}
