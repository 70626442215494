import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { ETDDeviceData } from "./Models";

export type UseETDDeviceDataParameters = {
  customerID: number;
  enabled: boolean;
};

//custom query hook
export function useETDDeviceData({ customerID, enabled }: UseETDDeviceDataParameters) {
  const { axios: ax } = useAxios();

  const queryKey = keys.devices(customerID);

  return useQuery<Array<ETDDeviceData>, Error>(
    queryKey,
    async () => {
      const { data } = await ax.get<Array<ETDDeviceData>>(`exportdata/devices/${customerID}`);
      return data;
    },
    {
      enabled: enabled,
    }
  );
}
