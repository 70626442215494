import { useMutation } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";

export type UseResetScheduleStatusParameters = {
  scheduleID: number;
};

export function useResetScheduleStatus() {
  const { axios: ax } = useAxios();

  return useMutation<void, Error, UseResetScheduleStatusParameters>(async (parameters) => {
    await ax.post(`schedulingtester/${parameters.scheduleID}/resetstatus`, {});
  });
}
