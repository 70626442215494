import React from "react";
import {
  faBarsStaggered,
  faTable,
  faTableList,
  faUsers,
  faUsersGear,
  faUserCheck,
  faBoxCheck,
  faTableRows,
  faUsersBetweenLines,
  faCircleNodes,
  faMoneyBill1Wave,
  faPhoneArrowUpRight,
  faFileExport,
  faChartBar,
  faMonitorWaveform,
  faGears,
  faBinary,
  faFileCircleInfo,
  faChartNetwork,
  faShareFromSquare,
  faFileInvoiceDollar,
  faPhoneXmark,
  faWallet,
  faClipboardListCheck,
  faFiles,
  faClipboardList,
  faBookOpenReader,
  faTally,
  faValueAbsolute,
  faCalendarDays,
  faBookCopy,
  faPaperPlane,
} from "@elynx/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@elynx/react-fontawesome";
import LogViewer from "../views/log-viewer/LogViewer";
import CustomerList from "../views/customer-list/CustomerList";
import AuditTrail from "../views/audit-trail/AuditTrail";
import ElynxUserList from "../views/elynx-user-list/ElynxUserList";
import EventProcessingMonitor from "../views/event-processing-monitor/EventProcessingMonitor";
import AllUserList from "../views/all-user-list/AllUserList";
import EnvironmentConfiguration from "../views/environment-configuration/EnvironmentConfiguration";
import SystemServices from "../views/system-services/SystemServices";
import SqlClusterNames from "../views/sql-cluster-names/SqlClusterNames";
import CacheConfigurationList from "../views/cache-configuration/CacheConfigurationList";
import OutboundMsgMonitor from "../views/outbound-msg-monitor/OutboundMsgMonitor";
import ExportTagData from "../views/export-tag-data/ExportTagData";
import PageUsage from "../views/page-usage/PageUsage";
import NonPolledData from "../views/non-polled-data/NonPolledData";
import SystemUsage from "../views/system-usage/SystemUsage";
import TestTagData from "../views/test-tag-data/TestTagData";
import AccountingInvoices from "../views/accounting-invoices/AccountingInvoices";
import MessagingReports from "../views/messaging-reports/MessagingReports";
import CacheDataViewer from "../views/cache-data/CacheDataViewer";
import ReportMonitor from "../views/report-monitor/ReportMonitor";
import TestReporting from "../views/test-reporting/TestReporting";
import NLogConfiguration from "../views/nlog-configuration/NLogConfiguration";
import TagdataReaderConfiguration from "../views/tagdata-reader-configuration/TagdataReaderConfiguration";
import MetricsViewer from "../views/metrics-viewer/MetricsViewer";
import TagValue from "../views/tag-value/TagValue";
import ScheduledReports from "../views/scheduled-reports/ScheduledReports";
import SupportCenterAuditTrail from "../views/support-center-audit-trail/SupportCenterAuditTrail";
import CopyDevice from "../views/copy-device/CopyDevice";
import CallUrl from "../views/call-url/CallUrl";

export enum NavCategoryEnum {
  Monitoring = "Monitoring",
  Configuration = "Configuration",
  Data = "Data",
  Customer = "Customer",
}

export type NavCategory = {
  name: NavCategoryEnum;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
};

export type IRoute = {
  category: NavCategoryEnum;
  layout: string;
  path: string;
  fullPath: string;
  exact?: boolean;
  component: (props?: unknown) => JSX.Element;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: any;
};

export const navCategories: Array<NavCategory> = [
  {
    name: NavCategoryEnum.Monitoring,
    icon: () => <FontAwesomeIcon icon={faMonitorWaveform} size="lg" />,
  },
  {
    name: NavCategoryEnum.Configuration,
    icon: () => <FontAwesomeIcon icon={faGears} size="lg" />,
  },
  {
    name: NavCategoryEnum.Data,
    icon: () => <FontAwesomeIcon icon={faBinary} size="lg" />,
  },
  {
    name: NavCategoryEnum.Customer,
    icon: () => <FontAwesomeIcon icon={faUsers} size="lg" />,
  },
];

export const routes: IRoute[] = [
  {
    category: NavCategoryEnum.Data,
    layout: "default",
    path: "accounting-invoices",
    fullPath: "/accounting-invoices",
    exact: true,
    component: AccountingInvoices,
    name: "Accounting Invoices",
    icon: () => <FontAwesomeIcon icon={faFileInvoiceDollar} size="lg" />,
  },
  {
    category: NavCategoryEnum.Configuration,
    layout: "default",
    path: "all-user-list",
    fullPath: "/all-user-list",
    exact: true,
    component: AllUserList,
    name: "All Users",
    icon: () => <FontAwesomeIcon icon={faUsersBetweenLines} size="lg" />,
  },
  {
    category: NavCategoryEnum.Monitoring,
    layout: "default",
    path: "audit-trail",
    fullPath: "/audit-trail",
    exact: true,
    component: AuditTrail,
    name: "Audit Trail",
    icon: () => <FontAwesomeIcon icon={faBoxCheck} size="lg" />,
  },
  {
    category: NavCategoryEnum.Configuration,
    layout: "default",
    path: "cache-configuration",
    fullPath: "/cache-configuration",
    exact: true,
    component: CacheConfigurationList,
    name: "Cache Configuration",
    icon: () => <FontAwesomeIcon icon={faMoneyBill1Wave} size="lg" />,
  },
  {
    category: NavCategoryEnum.Monitoring,
    layout: "default",
    path: "cache-data",
    fullPath: "/cache-data",
    exact: true,
    component: CacheDataViewer,
    name: "Cache Data Viewer",
    icon: () => <FontAwesomeIcon icon={faWallet} size="lg" />,
  },
  {
    category: NavCategoryEnum.Data,
    layout: "default",
    path: "call-url",
    fullPath: "/call-url",
    exact: true,
    component: CallUrl,
    name: "Call URL",
    icon: () => <FontAwesomeIcon icon={faPaperPlane} size="lg" />,
  },
  {
    category: NavCategoryEnum.Customer,
    layout: "default",
    path: "copy-device",
    fullPath: "/copy-device",
    exact: true,
    component: CopyDevice,
    name: "Copy Device",
    icon: () => <FontAwesomeIcon icon={faBookCopy} size="lg" />,
  },
  {
    category: NavCategoryEnum.Configuration,
    layout: "default",
    path: "customer-list",
    fullPath: "/customer-list",
    exact: true,
    component: CustomerList,
    name: "Customers",
    icon: () => <FontAwesomeIcon icon={faUsersGear} size="lg" />,
  },
  {
    category: NavCategoryEnum.Configuration,
    layout: "default",
    path: "elynx-user-list",
    fullPath: "/elynx-user-list",
    exact: true,
    component: ElynxUserList,
    name: "eLynx Users",
    icon: () => <FontAwesomeIcon icon={faUserCheck} size="lg" />,
  },
  {
    category: NavCategoryEnum.Configuration,
    layout: "default",
    path: "env-configuration",
    fullPath: "/env-configuration",
    exact: true,
    component: EnvironmentConfiguration,
    name: "Environment Configuration",
    icon: () => <FontAwesomeIcon icon={faTableList} size="lg" />,
  },
  {
    category: NavCategoryEnum.Monitoring,
    layout: "default",
    path: "event-processing-monitor",
    fullPath: "/event-processing-monitor",
    exact: true,
    component: EventProcessingMonitor,
    name: "Event Processing Monitor",
    icon: () => <FontAwesomeIcon icon={faBarsStaggered} size="lg" />,
  },
  {
    category: NavCategoryEnum.Data,
    layout: "default",
    path: "export-tag-data",
    fullPath: "/export-tag-data",
    exact: true,
    component: ExportTagData,
    name: "Export Tag Data (CSV)",
    icon: () => <FontAwesomeIcon icon={faFileExport} size="lg" />,
  },
  {
    category: NavCategoryEnum.Monitoring,
    layout: "default",
    path: "log-viewer",
    fullPath: "/log-viewer",
    exact: true,
    component: LogViewer,
    name: "Log Viewer",
    icon: () => <FontAwesomeIcon icon={faTable} size="lg" />,
  },
  {
    category: NavCategoryEnum.Data,
    layout: "default",
    path: "messaging-reports",
    fullPath: "/messaging-reports",
    exact: true,
    component: MessagingReports,
    name: "Messaging Reports",
    icon: () => <FontAwesomeIcon icon={faPhoneXmark} size="lg" />,
  },
  {
    category: NavCategoryEnum.Monitoring,
    layout: "default",
    path: "metrics-viewer",
    fullPath: "/metrics-viewer",
    exact: true,
    component: MetricsViewer,
    name: "Metrics Viewer",
    icon: () => <FontAwesomeIcon icon={faTally} size="lg" />,
  },
  {
    category: NavCategoryEnum.Configuration,
    layout: "default",
    path: "nlog-configuration",
    fullPath: "/nlog-configuration",
    exact: true,
    component: NLogConfiguration,
    name: "NLog Configurations",
    icon: () => <FontAwesomeIcon icon={faClipboardList} size="lg" />,
  },
  {
    category: NavCategoryEnum.Data,
    layout: "default",
    path: "non-polled-data",
    fullPath: "/non-polled-data",
    exact: true,
    component: NonPolledData,
    name: "Non-Polled Data Operations",
    icon: () => <FontAwesomeIcon icon={faFileCircleInfo} size="lg" />,
  },
  {
    category: NavCategoryEnum.Monitoring,
    layout: "default",
    path: "outbound-msg-monitor",
    fullPath: "/outbound-msg-monitor",
    exact: true,
    component: OutboundMsgMonitor,
    name: "Outbound Msg Monitor",
    icon: () => <FontAwesomeIcon icon={faPhoneArrowUpRight} size="lg" />,
  },
  {
    category: NavCategoryEnum.Data,
    layout: "default",
    path: "page-usage",
    fullPath: "/page-usage",
    exact: true,
    component: PageUsage,
    name: "Page Usage Data",
    icon: () => <FontAwesomeIcon icon={faChartBar} size="lg" />,
  },
  {
    category: NavCategoryEnum.Monitoring,
    layout: "default",
    path: "report-monitor",
    fullPath: "/report-monitor",
    exact: true,
    component: ReportMonitor,
    name: "Report Scheduler Monitor",
    icon: () => <FontAwesomeIcon icon={faClipboardListCheck} size="lg" />,
  },
  {
    category: NavCategoryEnum.Customer,
    layout: "default",
    path: "scheduled-reports",
    fullPath: "/scheduled-reports",
    exact: true,
    component: ScheduledReports,
    name: "Scheduled Reports",
    icon: () => <FontAwesomeIcon icon={faCalendarDays} size="lg" />,
  },
  {
    category: NavCategoryEnum.Data,
    layout: "default",
    path: "sql-cluster-names",
    fullPath: "/sql-cluster-names",
    exact: true,
    component: SqlClusterNames,
    name: "Sql Cluster Names",
    icon: () => <FontAwesomeIcon icon={faCircleNodes} size="lg" />,
  },
  {
    category: NavCategoryEnum.Monitoring,
    layout: "default",
    path: "sc-audit-trail",
    fullPath: "/sc-audit-trail",
    exact: true,
    component: SupportCenterAuditTrail,
    name: "SupportCenter Audit Trail",
    icon: () => <FontAwesomeIcon icon={faBoxCheck} size="lg" />,
  },
  {
    category: NavCategoryEnum.Monitoring,
    layout: "default",
    path: "system-services",
    fullPath: "/system-services",
    exact: true,
    component: SystemServices,
    name: "System Services",
    icon: () => <FontAwesomeIcon icon={faTableRows} size="lg" />,
  },
  {
    category: NavCategoryEnum.Data,
    layout: "default",
    path: "system-usage",
    fullPath: "/system-usage",
    exact: true,
    component: SystemUsage,
    name: "System Usage Data",
    icon: () => <FontAwesomeIcon icon={faChartNetwork} size="lg" />,
  },
  {
    category: NavCategoryEnum.Customer,
    layout: "default",
    path: "tag-value",
    fullPath: "/tag-value",
    exact: true,
    component: TagValue,
    name: "Tag Values and Actions",
    icon: () => <FontAwesomeIcon icon={faValueAbsolute} size="lg" />,
  },
  {
    category: NavCategoryEnum.Configuration,
    layout: "default",
    path: "test-reporting",
    fullPath: "/test-reporting",
    exact: true,
    component: TestReporting,
    name: "Report Scheduler Test Utility",
    icon: () => <FontAwesomeIcon icon={faFiles} size="lg" />,
  },
  {
    category: NavCategoryEnum.Customer,
    layout: "default",
    path: "test-tag-data",
    fullPath: "/test-tag-data",
    exact: true,
    component: TestTagData,
    name: "Test Tag Data",
    icon: () => <FontAwesomeIcon icon={faShareFromSquare} size="lg" />,
  },
  {
    category: NavCategoryEnum.Configuration,
    layout: "default",
    path: "tagdata-reader-configuration",
    fullPath: "/tagdata-reader-configuration",
    exact: true,
    component: TagdataReaderConfiguration,
    name: "Tagdata Reader Configurations",
    icon: () => <FontAwesomeIcon icon={faBookOpenReader} size="lg" />,
  },
];
