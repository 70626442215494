import React from "react";
import { useHistory } from "react-router-dom";
import { isMobile, isTablet, useMobileOrientation } from "react-device-detect";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Tooltip,
  useTheme,
  Box,
} from "@mui/material";
import { ExitToApp } from "@mui/icons-material";
import { IRoute, routes } from "../../routes/routes";
import { phoneRoutes } from "../../routes/phoneRoutes";

export type NavDrawerProps = {
  open: boolean;
  onClose?: () => void;
  onAction?: (action: NavDrawerAction) => void;
};

//OpsCenter doesn't need most of these,
//but keep what we stole from elynx-application for now
export enum NavDrawerAction {
  ToggleHierarchy = 1,
  ShowAccount = 2,
  ChangeCustomer = 3,
  ShowHelp = 4,
  SignOut = 5,
}

export default function NavDrawer({ open, onClose, onAction }: NavDrawerProps) {
  const theme = useTheme();
  const isPhone = isMobile && !isTablet;
  const { isLandscape } = useMobileOrientation();
  const history = useHistory();

  const onClick = (route: IRoute) => {
    //close the var drawer if this is a phone
    if (isPhone && onClose) {
      onClose();
    }
    history.push(route.fullPath);
  };

  const navRoutes = isPhone ? phoneRoutes : routes;

  return (
    <Drawer
      variant={!isPhone ? "permanent" : "temporary"}
      PaperProps={{
        sx: {
          position: "relative",
          overflowX: "hidden",
          whiteSpace: "nowrap",
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: open ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
          }),
          marginTop: isPhone ? "56px" : undefined,
          width: !open
            ? // Rail = 56px (spec for dense layout)
              theme.spacing(7)
            : !isPhone
            ? // Standard Drawer = 256 px
              theme.spacing(34)
            : // Modal Drawer = Width - 56 px
              `calc(100% - ${theme.spacing(7)})`,
        },
      }}
      open={open}
      onClose={onClose}
    >
      {/* Toolbar spacer - desktop only */}
      {!isPhone && <Toolbar />}

      <Stack sx={{ height: 1, overflow: "hidden" }}>
        {/* Navigation Items */}
        <List
          sx={{
            paddingTop: 0,
            overflowX: "hidden",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            flex: "1 1 auto",
          }}
        >
          {navRoutes.map((route, index) => (
            <ListItem key={index} button onClick={() => onClick && onClick(route)}>
              <ListItemIcon>
                <Tooltip arrow placement="right" title={!open ? route.name : ""}>
                  <Box>{<route.icon />}</Box>
                </Tooltip>
              </ListItemIcon>

              <ListItemText primary={route.name} />
            </ListItem>
          ))}
        </List>

        {/* Bottom section */}
        {!(isPhone && isLandscape) && (
          <List dense={false} sx={{ display: "flex", flexDirection: "column" }}>
            <Divider />

            {/* Sign Out Item */}
            <ListItem
              button
              onClick={() => {
                onAction && onAction(NavDrawerAction.SignOut);
              }}
            >
              <ListItemIcon>
                <Tooltip arrow placement="right" title={!open ? "Sign Out" : ""}>
                  <ExitToApp />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary="Sign Out" />
            </ListItem>
          </List>
        )}
      </Stack>
    </Drawer>
  );
}
