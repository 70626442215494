import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./app/App";
//import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import settings from "./app-settings.json";
import { CssBaseline } from "@mui/material";

//MSAL
import { MsalProvider } from "@azure/msal-react";
import { createPCA } from "./services/msal-service/MSALService";

//React Query
import { QueryClientProvider } from "react-query";
import { queryClient } from "./services/query-client-service/QueryClientService";

//Axios Provider
import { AxiosProvider } from "./contexts/axios-context/AxiosContext";

//App Context Provider
import { AppContextProvider } from "./contexts/app-context/AppContext";

//setup MSAL as early as possible
const pca = createPCA(settings.aadClientID);

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
      <QueryClientProvider client={queryClient}>
        <CssBaseline />
        <AxiosProvider baseUrl={settings.apiBaseUrl}>
          <AppContextProvider>
            <App />
          </AppContextProvider>
        </AxiosProvider>
      </QueryClientProvider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
