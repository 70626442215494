export type DeviceCopyJobListQueryProperties = {
  start_timestamp: Date | null;
  end_timestamp: Date | null;
};

export enum DeviceCopyJobStatusEnum {
  Configuring = 0,
  Preparing = 1,
  Running = 2,
  Paused = 3,
  Stopped = 4,
  Completed = 5,
  Failed = 6,
  Restarted = 7,
}

export type DeviceCopyJobListItem = {
  dcjl_id: number;
  dcjl_dcjse_id: DeviceCopyJobStatusEnum;
  dcjl_start_dto: Date;
  dcjl_end_dto: Date | null;
  dcjl_src_cust_id: number;
  src_cust_name: string;
  dcjl_dst_cust_id: number;
  dst_cust_name: string;
  dcjl_username: string;
  dcjl_dev_count: number;
};

export const defaultDeviceCopyJobListQueryProperties: DeviceCopyJobListQueryProperties = {
  start_timestamp: null,
  end_timestamp: null,
};

//thise is actually a bitfield ( [Flags] attribute in C#)
export enum SingleObjectCopyStatusEnum {
  NotStarted = 0,
  CopyDevice = 1,
  CopyTags = 2,
  CopySetPoints = 4,
  CopyEventLogs = 8,
  CopyTagValues = 16,
  Finished = 65536,
}

export const GetFurthestStatusString = (status: SingleObjectCopyStatusEnum): string =>
  (status & SingleObjectCopyStatusEnum.Finished) === SingleObjectCopyStatusEnum.Finished
    ? "Finished"
    : (status & SingleObjectCopyStatusEnum.CopyTagValues) === SingleObjectCopyStatusEnum.CopyTagValues
    ? "Copying tag values"
    : (status & SingleObjectCopyStatusEnum.CopyEventLogs) === SingleObjectCopyStatusEnum.CopyEventLogs
    ? "Copying event logs"
    : (status & SingleObjectCopyStatusEnum.CopySetPoints) === SingleObjectCopyStatusEnum.CopySetPoints
    ? "Copying setpoints"
    : (status & SingleObjectCopyStatusEnum.CopyTags) === SingleObjectCopyStatusEnum.CopyTags
    ? "Copying tags"
    : (status & SingleObjectCopyStatusEnum.CopyDevice) === SingleObjectCopyStatusEnum.CopyDevice
    ? "Copying device"
    : "Not started";

export type SingleObject = {
  objectID: number;
  objectType: number;
};

export type SingleObjectCopy = {
  objectID: number;
  objectTypeID: number;
  objectType: string;
  objectName: string;
  objectCopyStatus: SingleObjectCopyStatusEnum;
  selectLocked: boolean;
};

export type DeviceCopyJob = {
  jobID: number;
  jobStatus: DeviceCopyJobStatusEnum;
  srcCustID: number | null;
  srcCustName: string;
  srcOrgID: number | null;
  dstCustID: number | null;
  dstCustName: string;
  dstOrgID: number | null;
  startTimestamp: Date;
  endTimestamp: Date | null;
  username: string;
  objects: Array<SingleObjectCopy>;
  copySetpoints: boolean;
  copyEventLog: boolean;
  copyTagValues: boolean;
  dataStartTime: Date | null;
  dataEndTime: Date | null;
  jobDescription: string;
  tatMappings: Array<TatMapItem>;
  uomMappings: Array<UomMapItem>;
  formulaMappings: Array<FormulaMapItem>;
  lookupListMappings: Array<LookupListMapItem>;
  tatGroupMappings: Array<TatGroupMapItem>;
};

export const defaultDeviceCopyJob: DeviceCopyJob = {
  jobID: 0,
  jobStatus: DeviceCopyJobStatusEnum.Configuring,
  srcCustID: null,
  srcCustName: "",
  srcOrgID: null,
  dstCustID: null,
  dstCustName: "",
  dstOrgID: null,
  startTimestamp: new Date(),
  endTimestamp: null,
  username: "",
  objects: [],
  copySetpoints: false,
  copyEventLog: false,
  copyTagValues: false,
  dataStartTime: null,
  dataEndTime: null,
  jobDescription: "",
  tatMappings: [],
  uomMappings: [],
  formulaMappings: [],
  lookupListMappings: [],
  tatGroupMappings: [],
};

export type DeviceCopyDevice = {
  obj_type_id: number;
  obj_type: string;
  obj_id: number;
  obj_guid: string;
  obj_name: string;
  obj_external_id: string;
  obj_enabled: boolean;
  obj_visible: boolean;
};

export type WellDeviceLink = {
  well_id: number;
  dev_id: number;
};

export type BatteryDeviceLink = {
  tnkb_id: number;
  dev_id: number;
};

export type BatteryTankLink = {
  tnkb_id: number;
  tank_id: number;
};

export type BatteryPadLink = {
  tnkp_id: number;
  tnkb_id: number;
};

export type DeviceSelectionInfo = {
  objects: Array<DeviceCopyDevice>;
  well_links: Array<WellDeviceLink>;
  battery_devices: Array<BatteryDeviceLink>;
  battery_tanks: Array<BatteryTankLink>;
  pad_batteries: Array<BatteryPadLink>;
};

export type Tat = {
  tat_id: number;
  tat_name: string;
  tat_global: boolean;
};

export enum TatActionEnum {
  DoNotCopy = 0,
  Copy = 1,
  CreateAndCopy = 2,
}

export type TatInfoItem = {
  src_tat_id: number;
  src_tat_name: string;
  src_tat_global: boolean;
  dst_tat_id: number | null;
  dst_tat_name: string;
  dst_tat_global: boolean;
  tat_action: TatActionEnum;
};

export type TatInfo = {
  dst_tat_list: Array<Tat>;
  tat_info_list: Array<TatInfoItem>;
};

export type TatMapItem = {
  srcTatID: number;
  dstTatID: number | null;
  tatAction: TatActionEnum;
};

export type Uom = {
  uom_id: number;
  uom_name: string;
  uom_global: boolean;
};

export enum UomActionEnum {
  DoNotCopy = 0,
  Copy = 1,
  CreateAndCopy = 2,
}

export type UomInfoItem = {
  src_uom_id: number;
  src_uom_name: string;
  src_uom_global: boolean;
  dst_uom_id: number | null;
  dst_uom_name: string;
  dst_uom_global: boolean;
  uom_action: UomActionEnum;
};

export type UomInfo = {
  dst_uom_list: Array<Uom>;
  uom_info_list: Array<UomInfoItem>;
};

export type UomMapItem = {
  srcUomID: number;
  dstUomID: number | null;
  uomAction: UomActionEnum;
};

export type Formula = {
  frm_id: number;
  frm_name: string;
  frm_global: boolean;
};

export enum FormulaActionEnum {
  DoNotCopy = 0,
  Copy = 1,
  CreateAndCopy = 2,
}

export type FormulaInfoItem = {
  src_frm_id: number;
  src_frm_name: string;
  src_frm_global: boolean;
  dst_frm_id: number | null;
  dst_frm_name: string;
  dst_frm_global: boolean;
  frm_action: FormulaActionEnum;
};

export type FormulaInfo = {
  dst_frm_list: Array<Formula>;
  frm_info_list: Array<FormulaInfoItem>;
};

export type FormulaMapItem = {
  srcFrmID: number;
  dstFrmID: number | null;
  frmAction: FormulaActionEnum;
};

export type LookupList = {
  lst_id: number;
  lst_name: string;
};

export enum LookupListActionEnum {
  DoNotCopy = 0,
  Copy = 1,
  CreateAndCopy = 2,
}

export type LookupListInfoItem = {
  src_lst_id: number;
  src_lst_name: string;
  dst_lst_id: number | null;
  dst_lst_name: string;
  lst_action: LookupListActionEnum;
};

export type LookupListInfo = {
  dst_lst_list: Array<LookupList>;
  lst_info_list: Array<LookupListInfoItem>;
};

export type LookupListMapItem = {
  srcListID: number;
  dstListID: number | null;
  listAction: LookupListActionEnum;
};

export type TatGroup = {
  tg_id: number;
  tg_name: string;
  isTatGroup: boolean;
};

export enum TatGroupActionEnum {
  DoNotCopy = 0,
  Copy = 1,
  CreateAndCopy = 2,
}

export type TatGroupInfoItem = {
  src_tg_id: number;
  src_tg_name: string;
  src_isTatGroup: boolean;
  dst_tg_id: number | null;
  dst_tg_name: string;
  dst_isTatGroup: boolean;
  tg_action: TatGroupActionEnum;
};

export type TatGroupInfo = {
  dst_tg_list: Array<TatGroup>;
  tg_info_list: Array<TatGroupInfoItem>;
};

export type TatGroupMapItem = {
  srcTgID: number;
  srcIsTatGroup: boolean;
  dstTgID: number | null;
  tgAction: TatGroupActionEnum;
};
