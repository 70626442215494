import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { TDTSetpoint } from "./Models";

export type UseTDTSetpointParameters = {
  customerID: number;
  tagID: number;
  setpointID: number;
  enabled: boolean;
  autoRefreshSeconds: number;
};

//custom query hook
export function useTDTSetpoint({
  customerID,
  tagID,
  setpointID,
  enabled,
  autoRefreshSeconds,
}: UseTDTSetpointParameters) {
  const { axios: ax } = useAxios();

  const queryKey = keys.setpoint(customerID, setpointID);

  return useQuery<TDTSetpoint, Error>(
    queryKey,
    async () => {
      const { data } = await ax.get<TDTSetpoint>(`testtagdata/setpoint/${customerID}/${tagID}/${setpointID}`);
      return data;
    },
    {
      enabled: enabled,
      refetchInterval: autoRefreshSeconds > 0 ? autoRefreshSeconds * 1000 : false,
      refetchIntervalInBackground: true,
    }
  );
}
