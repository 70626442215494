import React, { useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { isMobile, isTablet, useMobileOrientation } from "react-device-detect";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Tooltip,
  useTheme,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Switch,
  Menu,
  MenuItem,
} from "@mui/material";
import { ExitToApp } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IRoute, navCategories, NavCategoryEnum, routes } from "../../routes/routes";
import { phoneRoutes } from "../../routes/phoneRoutes";
import { AppSettingsActionTypeEnum, useAppSettings } from "../../contexts/app-context/AppContext";

export type AccordionNavDrawerProps = {
  open: boolean;
  onClose?: () => void;
  onAction?: (action: NavDrawerAction) => void;
};

//OpsCenter doesn't need most of these,
//but keep what we stole from elynx-application for now
export enum NavDrawerAction {
  ToggleHierarchy = 1,
  ShowAccount = 2,
  ChangeCustomer = 3,
  ShowHelp = 4,
  SignOut = 5,
}

export default function AccordionNavDrawer({ open, onClose, onAction }: AccordionNavDrawerProps) {
  const theme = useTheme();
  const isPhone = isMobile && !isTablet;
  const { isLandscape } = useMobileOrientation();
  const history = useHistory();
  const location = useLocation();
  const defaultSectionInxRef = useRef(-1);
  const [appSettings, dispatchAppSettings] = useAppSettings();

  //we cannot flip the accordions between controlled and uncontrolled
  //so we have to keep track of what is expanded ourselves
  //regardless of whether the user wants a single section or
  //multiple sections open at once
  const [expanded, setExpanded] = useState<Array<boolean>>([]);
  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
    path: string;
  } | null>(null);

  const navRoutes = isPhone ? phoneRoutes : routes;

  //open the correct navbar section on initial load
  //we only need to process the location to set the default section index on the first call
  //if the default section index is -1 this is the first call
  if (defaultSectionInxRef.current == -1) {
    console.log(`location: ${location.pathname}`);
    //if the location is root, the index is 0
    if (location.pathname == "/") {
      defaultSectionInxRef.current = 0;
    }
    //else look up the location in the routes
    else {
      const foundRoute = navRoutes.find((r) => r.fullPath == location.pathname);

      //if we found a route, the section open by default is the index of that route's category
      //if not, just open the first section
      defaultSectionInxRef.current = foundRoute ? Object.keys(NavCategoryEnum).indexOf(foundRoute.category) : 0;
    }

    //since this is the initial load, just set the expanded state
    //without worrying about any previous expanded state info
    const newState = new Array<boolean>();
    newState[defaultSectionInxRef.current] = true;
    setExpanded(newState);
  }

  const onClick = (route: IRoute) => {
    //close the var drawer if this is a phone
    if (isPhone && onClose) {
      onClose();
    }
    history.push(route.fullPath);
  };

  const handleExpandChange = (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    //create a copy we can modify
    const newState = { ...expanded };

    //if there can be only one, set the state for all categories accordingly
    if (appSettings.singleAccordionOnly) {
      navCategories.map((_, inx) => {
        newState[inx] = newExpanded ? panel == inx : false;
      });
    } else {
      //else just change the state as indicated for the specified panel
      newState[panel] = newExpanded;
    }
    setExpanded(newState);
  };

  const handleContextMenu = (event: React.MouseEvent, path: string) => {
    event.preventDefault();
    console.info(`onContextMenu: ${path}`);
    setContextMenu(
      {
        mouseX: event.clientX + 2,
        mouseY: event.clientY - 6,
        path: path,
      }
      // contextMenu === null
      //   ? {
      //       mouseX: event.clientX + 2,
      //       mouseY: event.clientY - 6,
      //       path: path
      //     }
      //   : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
      //     // Other native context menus might behave different.
      //     // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
      //     null,
    );
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const handleOpenPath = (openInNewWindow: boolean) => {
    if (contextMenu !== null) {
      window.open(contextMenu.path, openInNewWindow ? "" : "_blank", openInNewWindow ? "left=20" : undefined);
      setContextMenu(null);
    }
  };

  return (
    <Drawer
      variant={!isPhone ? "permanent" : "temporary"}
      PaperProps={{
        sx: {
          position: "relative",
          overflowX: "hidden",
          whiteSpace: "nowrap",
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: open ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
          }),
          marginTop: isPhone ? "56px" : undefined,
          width: !open
            ? // Rail = 56px (spec for dense layout)
              theme.spacing(12)
            : !isPhone
            ? // Standard Drawer = 256 px
              theme.spacing(40)
            : // Modal Drawer = Width - 56 px
              `calc(100% - ${theme.spacing(7)})`,
        },
      }}
      open={open}
      onClose={onClose}
    >
      {/* Toolbar spacer - desktop only */}
      {!isPhone && <Toolbar />}

      <Stack sx={{ height: 1, overflow: "hidden" }}>
        {/* Navigation Items */}
        <Box sx={{ overflowY: "auto", scrollbarWidth: "thin" }}>
          {
            //iterate the navigation categories
            navCategories.map((cat, inx) => {
              const categoryRoutes = navRoutes.filter((r) => r.category == cat.name);
              return (
                categoryRoutes.length > 0 && (
                  <Accordion
                    key={inx}
                    defaultExpanded={inx == defaultSectionInxRef.current}
                    expanded={expanded[inx] === true}
                    onChange={handleExpandChange(inx)}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Tooltip arrow placement="right" title={!open ? cat.name : ""}>
                        <Box>{<cat.icon />}</Box>
                      </Tooltip>
                      {open && <Typography sx={{ marginLeft: theme.spacing(2) }}>{cat.name}</Typography>}
                    </AccordionSummary>
                    <AccordionDetails>
                      <List
                        sx={{
                          paddingTop: 0,
                          overflowX: "hidden",
                          overflowY: "auto",
                          display: "flex",
                          flexDirection: "column",
                          flex: "1 1 auto",
                        }}
                      >
                        {categoryRoutes.map((route, index) => (
                          <ListItem
                            key={index}
                            button
                            onClick={() => onClick && onClick(route)}
                            onContextMenu={(e) => handleContextMenu(e, route.path)}
                          >
                            <ListItemIcon>
                              <Tooltip arrow placement="right" title={!open ? route.name : ""}>
                                <Box>{<route.icon />}</Box>
                              </Tooltip>
                            </ListItemIcon>
                            <ListItemText primary={route.name} />
                          </ListItem>
                        ))}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                )
              );
            })
          }
        </Box>

        {/* Bottom section */}
        {!(isPhone && isLandscape) && (
          <List dense={false} sx={{ marginTop: "auto", marginBottom: "0px" }}>
            <Divider />

            {/* Single accordion only switch */}
            <ListItem>
              {open && <ListItemText primary="Single Section Only" />}
              <Switch
                edge="end"
                checked={appSettings.singleAccordionOnly}
                onChange={(_, checked) =>
                  dispatchAppSettings({
                    type: AppSettingsActionTypeEnum.SingleAccordionOnlyChanged,
                    singleOnly: checked,
                  })
                }
              />
            </ListItem>

            {/* Sign Out Item */}
            <ListItem
              button
              onClick={() => {
                onAction && onAction(NavDrawerAction.SignOut);
              }}
            >
              <ListItemIcon>
                <Tooltip arrow placement="right" title={!open ? "Sign Out" : ""}>
                  <ExitToApp />
                </Tooltip>
              </ListItemIcon>
              {open && <ListItemText primary="Sign Out" />}
            </ListItem>
          </List>
        )}
      </Stack>

      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
      >
        <MenuItem onClick={() => handleOpenPath(false)}>Open in new tab</MenuItem>
        <MenuItem onClick={() => handleOpenPath(true)}>Open in new window</MenuItem>
      </Menu>
    </Drawer>
  );
}
