import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { ETDOrganizationData } from "./Models";

export type UseETDOrganizationDataParameters = {
  customerID: number;
  enabled: boolean;
};

//custom query hook
export function useETDOrganizationData({ customerID, enabled }: UseETDOrganizationDataParameters) {
  const { axios: ax } = useAxios();

  const queryKey = keys.organizations(customerID);

  return useQuery<Array<ETDOrganizationData>, Error>(
    queryKey,
    async () => {
      const { data } = await ax.get<Array<ETDOrganizationData>>(`exportdata/organizations/${customerID}`);
      return data;
    },
    {
      enabled: enabled,
    }
  );
}
