import React, { useCallback, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useForm } from "react-hook-form";
import { CacheConfiguration } from "../../queries/cache-configuration/Models";
import { useClearCache } from "../../queries/cache-configuration/UseClearCache";
import { useToastNotification } from "../../contexts/toast-notification/ToastNotificationContext";
import { AutocompleteOption, FormInputAutocomplete } from "../../components/form-components/FormInputAutocomplete";

export type ClearCacheProperties = {
  open: boolean;
  onClose: () => void;
  cacheConfiguration: CacheConfiguration;
  customers: Array<AutocompleteOption>;
};

type ClearCacheFormInput = {
  customer: AutocompleteOption;
};

export default function ClearCache({ open, onClose, cacheConfiguration, customers }: ClearCacheProperties) {
  const { control, handleSubmit, reset } = useForm<ClearCacheFormInput>();
  const { displayToast } = useToastNotification();
  const { mutate: clearCache } = useClearCache();

  //whenever the cache configuration changes, reset the values of our fields
  useEffect(() => {
    reset();
  }, [cacheConfiguration]);

  const onClick = useCallback(
    (data: ClearCacheFormInput) => {
      if (data) {
        const customerID = data.customer.id;

        clearCache(
          { customerID: customerID, cacheConfiguration: cacheConfiguration },
          {
            onSuccess: (data) =>
              data === true
                ? displayToast({
                    message: `Cache ID ${cacheConfiguration.cacheID} successfully cleared for Customer ${customerID}`,
                    severity: "success",
                  })
                : displayToast({
                    message: `Cache ID ${cacheConfiguration.cacheID} did not exist for Customer ${customerID}`,
                    severity: "success",
                  }),
            onError: (error) =>
              displayToast({
                message: `Error clearing cache ${cacheConfiguration.cacheID} for Customer ${customerID}: ${error}`,
                severity: "error",
                duration: 15000,
              }),
          }
        );
        reset();
        onClose();
      }
    },
    [cacheConfiguration]
  );

  return (
    <Dialog open={open} onClose={onClose} scroll="paper" fullWidth={true} maxWidth="sm">
      <DialogTitle>Clear Cache {cacheConfiguration.cacheID}</DialogTitle>
      <DialogContent dividers={true}>
        <FormInputAutocomplete
          name="customer"
          label="Customer"
          control={control}
          rules={{
            required: { value: true, message: "Customer ID is required" },
          }}
          options={customers}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="secondary" onClick={handleSubmit(onClick)}>
          Clear
        </Button>
      </DialogActions>
    </Dialog>
  );
}
