import { CustomerTypeEnum } from "../customer/Models";

export const keys = {
  all: ["all-user-list"],
  userListQuery: (includeDisabled: boolean, includeElynx: boolean, customerType: CustomerTypeEnum) => [
    ...keys.all,
    includeDisabled,
    includeElynx,
    customerType,
  ],
};
