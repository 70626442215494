import React, {
  ReactNode,
  createContext,
  useMemo,
  useContext,
  useRef,
} from "react";
import Axios, { AxiosInstance } from "axios";
import { useAccessToken } from "../../queries/access-token/UseAccessToken";

//input and output types
export type AxiosContextType = {
  axios: AxiosInstance;
  hasAccessToken: boolean;
};

export type AxiosContextProviderProps = {
  children: ReactNode;
  baseUrl: string;
};

//the context itself
const AxiosContext = createContext<AxiosContextType | undefined>(undefined);

//the provider component
export function AxiosProvider({
  children,
  baseUrl,
}: AxiosContextProviderProps) {

  //use a ref that React Query can easily update
  const accessTokenRef = useRef<string>();
  
  //start by trying to get the access token from React Query
  //we will use the immediate data value (token) if there is one
  //and also pass a function that will be called to updat the ref
  //every time React Query gets a new token
  const { isLoading, isError, data } = useAccessToken((data) => {
    if (data)
    {
      accessTokenRef.current = data;
    }
  });

  //set a flag indicating whether or not we have an access token
  //and update the ref if we do
  const hasAccessToken = !isLoading && !isError && !!data;
  if (hasAccessToken) {
    accessTokenRef.current = data;
  }

  //memoize the Axios instance and only update it when the access token changes
  const memoizedAxiosInstance = useMemo(() => {
    const axiosInstance = Axios.create({
      baseURL: baseUrl,
      headers: {
        "Content-Type": "application/json",
      },
    });

    axiosInstance.interceptors.request.use((config) => {
      if (hasAccessToken && config?.headers) {
        config.headers.Authorization = `Bearer ${accessTokenRef.current}`;
      }

      return config;
    });

    return axiosInstance;
  }, [data]);

  const providerValue = {
    axios: memoizedAxiosInstance,
    hasAccessToken: hasAccessToken,
  } as AxiosContextType;

  return (
    <AxiosContext.Provider value={providerValue}>
      {children}
    </AxiosContext.Provider>
  );
}

//the hook to use the AxiosContext
export function useAxios() {
  const context = useContext(AxiosContext);
  if (!context) {
    throw new Error("useAxios must be used within an AxiosProvider");
  }

  return context;
}
