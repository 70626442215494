import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { useAppSettings } from "../../../contexts/app-context/AppContext";
import { Box } from "@mui/system";
import { ConfigureJobStepProps } from "./ConfigureCopyJob";
import { ColDef, ValueFormatterParams } from "ag-grid-community";
import { UomActionEnum, UomInfoItem } from "../../../queries/copy-device/Models";
import { useDeviceCopyUomInfo } from "../../../queries/copy-device/UseDeviceCopyUomInfo";
import IconCellRenderer, { IconRendererProps } from "../../../components/ag-grid-extensions/renderers/IconCellRenderer";
import { faFileImport, faCircleY } from "@elynx/pro-regular-svg-icons";
import { faCircleN } from "@elynx/pro-solid-svg-icons";
import CheckboxCellRenderer, {
  CheckboxRendererProps,
} from "../../../components/ag-grid-extensions/renderers/CheckboxCellRenderer";
import { CopyDeviceJobActionTypeEnum } from "../CopyDeviceJobActions";
import SideSheet from "../../../components/side-sheet/side-sheet/SideSheet";
import CopyOptions from "./CopyOptions";
import { FormControlLabel, Typography } from "@mui/material";
import FontAwesomeIconButton from "../../../components/font-awesome-icon-button/FontAwesomeIconButton";
import { AutocompleteOption } from "../../../components/form-components/FormInputAutocomplete";
import ActionButtonCellRenderer, {
  ActionButtonRendererProps,
} from "../../../components/ag-grid-extensions/renderers/ActionButtonCellRenderer";
import SelectUom from "./SelectUom";

export default function MapUomsStep({ dialogOpen, job, dispatchJob, setNextButtonDisabled }: ConfigureJobStepProps) {
  const gridRef = useRef<AgGridReact>(null);
  const [appSettings] = useAppSettings();
  const gridTheme = appSettings.themeSet[appSettings.themeSet.mode].grid;
  const [destinationUoms, setDestinationUoms] = useState<Array<AutocompleteOption>>([]);
  const [sourceUoms, setSourceUoms] = useState<Array<UomInfoItem>>([]);
  const currentUomInfoRef = useRef<UomInfoItem | null>(null);
  const [selectUomOpen, setSelectUomOpen] = useState(false);
  const { data: newUomInfo, isSuccess } = useDeviceCopyUomInfo({
    enabled: dialogOpen && (job?.objects?.length ?? 0) > 0,
    jobID: job?.jobID ?? 0,
  });

  //if we have just been opened, clear our data
  useEffect(() => {
    setDestinationUoms([]);
    setSourceUoms([]);
  }, [dialogOpen]);

  //store source and destination UoMs if they change
  useEffect(() => {
    if (newUomInfo?.uom_info_list) {
      setSourceUoms(newUomInfo.uom_info_list);
    }
  }, [dialogOpen, isSuccess, newUomInfo?.uom_info_list]);

  useEffect(() => {
    if (newUomInfo?.dst_uom_list) {
      const noUom = { id: -1, label: "" } as AutocompleteOption;
      const uomList = newUomInfo.dst_uom_list.map((t) => {
        return { id: t.uom_id, label: t.uom_name } as AutocompleteOption;
      });
      setDestinationUoms([noUom, ...uomList]);
    }
  }, [dialogOpen, isSuccess, newUomInfo?.dst_uom_list]);

  useEffect(() => {
    //if this is our first visit to this step,
    //i.e. dialog is open, job.uomMappings is empty)
    //and we have sourceUoms,
    //setup the initial mapping in the job
    if (dialogOpen && sourceUoms.length > 0 && (job?.uomMappings?.length ?? 0) === 0) {
      //then create a new mapping to be saved into the job
      const newUomMapping = sourceUoms.map((t) => {
        return {
          srcUomID: t.src_uom_id,
          dstUomID: t.dst_uom_id,
          uomAction: t.uom_action,
        };
      });
      dispatchJob({
        type: CopyDeviceJobActionTypeEnum.SetUomMap,
        uomMap: newUomMapping,
      });
    }
  }, [dialogOpen, sourceUoms]);

  //enable the Next button if we have mapped all UoMs
  useEffect(() => {
    setNextButtonDisabled(
      (job?.uomMappings?.length ?? 0) == 0 || job.uomMappings.some((u) => UomActionEnum.DoNotCopy === u.uomAction)
    );
  }, [job?.uomMappings]);

  const autoSizeColumns = useCallback(() => {
    gridRef.current?.api.autoSizeAllColumns();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onDisabledClicked = useCallback(() => {}, []);

  const onCheckChanged = useCallback(
    (data: UomInfoItem, checked: boolean) => {
      //first create a new set of source UoMs so our grid can be updated
      const newSourceUoms = sourceUoms.map((t) => {
        //if the array item matches the one being changed, handle that
        if (t.src_uom_id === data.src_uom_id) {
          return {
            ...t,
            uom_action: checked
              ? null == t.dst_uom_id
                ? UomActionEnum.CreateAndCopy
                : UomActionEnum.Copy
              : UomActionEnum.DoNotCopy,
          };
        }
        //else just return the array item
        else {
          return t;
        }
      });

      setSourceUoms(newSourceUoms);

      //then create a new mapping to be saved into the job
      const newUomMapping = newSourceUoms.map((t) => {
        return {
          srcUomID: t.src_uom_id,
          dstUomID: t.dst_uom_id,
          uomAction: t.uom_action,
        };
      });
      dispatchJob({
        type: CopyDeviceJobActionTypeEnum.SetUomMap,
        uomMap: newUomMapping,
      });
    },
    [sourceUoms]
  );

  const onSelectUomClick = useCallback((uomInfo: UomInfoItem) => {
    currentUomInfoRef.current = uomInfo;
    setSelectUomOpen(true);
  }, []);

  const shouldDisplaySelectUom = useCallback((uomInfo: UomInfoItem) => {
    return !uomInfo.dst_uom_global;
  }, []);

  //setup all the columns
  const columnDefs = useMemo<Array<ColDef>>(() => {
    const columns = [
      {
        headerName: "Global",
        field: "src_uom_global",
        width: 120,
        sort: "asc",
        sortIndex: 1,
        cellRenderer: IconCellRenderer,
        cellRendererParams: {
          state: (data: UomInfoItem) => data?.src_uom_global ?? false,
          trueIcon: faCircleY,
          falseIcon: faCircleN,
          size: "medium",
          falseTooltipText: "Local UoM",
          trueTooltipText: "Global UoM",
          disabled: false,
          onClick: onDisabledClicked,
          shouldDisplay: true,
        } as IconRendererProps,
      },
      {
        headerName: "Source ID",
        field: "src_uom_id",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Source UoM",
        field: "src_uom_name",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        sort: "asc",
        sortIndex: 2,
      },
      {
        headerName: "Destination UoM",
        field: "dst_uom_name",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        valueFormatter: ({ data }: ValueFormatterParams<UomInfoItem, string>) => {
          return null !== data && null !== data?.dst_uom_id
            ? data?.dst_uom_global
              ? `${data?.dst_uom_name}`
              : `${data?.dst_uom_name} [${data?.dst_uom_id}]`
            : "";
        },
      },
      {
        colId: "selectUom",
        headerName: "Select UoM",
        minWidth: 170,
        cellRenderer: ActionButtonCellRenderer,
        cellRendererParams: {
          disabled: false,
          buttonText: "Select UoM",
          shouldDisplay: shouldDisplaySelectUom,
          onClick: onSelectUomClick,
        } as ActionButtonRendererProps,
      },
      {
        headerName: "Copy Uom?",
        field: "uom_action",
        minWidth: 200,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        cellRenderer: CheckboxCellRenderer,
        cellRendererParams: {
          disabled: false,
          onChecked: onCheckChanged,
          label: (data: UomInfoItem) => {
            return data
              ? UomActionEnum.Copy === data.uom_action
                ? "Copy"
                : UomActionEnum.CreateAndCopy === data.uom_action
                ? "Create and Copy"
                : ""
              : "";
          },
        } as CheckboxRendererProps,
      },
    ] as Array<ColDef>;

    return columns;
  }, [onCheckChanged]);

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  const createMissingUoms = useCallback(() => {
    //first create a new set of source UoMs so our grid can be updated
    const newSourceUoms = sourceUoms.map((t) => {
      const newAction = null == t.dst_uom_id ? UomActionEnum.CreateAndCopy : t.uom_action;
      return {
        ...t,
        uom_action: newAction,
      };
    });

    setSourceUoms(newSourceUoms);

    //then create a new mapping to be saved into the job
    const newUomMapping = newSourceUoms.map((t) => {
      return {
        srcUomID: t.src_uom_id,
        dstUomID: t.dst_uom_id,
        uomAction: t.uom_action,
      };
    });
    dispatchJob({
      type: CopyDeviceJobActionTypeEnum.SetUomMap,
      uomMap: newUomMapping,
    });
  }, [sourceUoms]);

  //called when user selects a UoM in the popup dialog
  const onUomSelected = useCallback(
    (uomInfo: UomInfoItem) => {
      //first create a new set of source UoMs so our grid can be updated
      const newSourceUoms = sourceUoms.map((t) => {
        //if the array item matches the one being changed, handle that
        if (t.src_uom_id === uomInfo.src_uom_id) {
          return {
            ...t,
            dst_uom_id: uomInfo.dst_uom_id,
            dst_uom_name: uomInfo.dst_uom_name,
            uom_action: uomInfo.uom_action,
          };
        }
        //else just return the array item
        else {
          return t;
        }
      });

      setSourceUoms(newSourceUoms);

      //then create a new mapping to be saved into the job
      const newUomMapping = newSourceUoms.map((t) => {
        return {
          srcUomID: t.src_uom_id,
          dstUomID: t.dst_uom_id,
          uomAction: t.uom_action,
        };
      });
      dispatchJob({
        type: CopyDeviceJobActionTypeEnum.SetUomMap,
        uomMap: newUomMapping,
      });
    },
    [sourceUoms]
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          "& .ag-theme-alpine .ag-cell-value": {
            lineHeight: "20px !important",
            wordBreak: "normal",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
          "& .ag-theme-alpine-dark .ag-cell-value": {
            lineHeight: "20px !important",
            wordBreak: "normal",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
        }}
      >
        <div className={gridTheme} style={{ height: "100%", width: "100%" }}>
          <AgGridReact
            ref={gridRef}
            defaultColDef={defaultColDef}
            rowData={sourceUoms}
            columnDefs={columnDefs}
            ensureDomOrder={true}
            enableCellTextSelection={true}
            onFirstDataRendered={autoSizeColumns}
            suppressClickEdit={true}
            suppressCellFocus={true}
            alwaysMultiSort={true}
            suppressScrollOnNewData={true}
          />
        </div>
      </Box>
      <SideSheet title="Options" open={true}>
        <CopyOptions job={job} dispatchJob={dispatchJob} />
        <>
          <Typography variant="h6" sx={{ marginLeft: "-11px", marginTop: "10px" }}>
            {"Actions"}
          </Typography>
          <FormControlLabel
            control={<FontAwesomeIconButton icon={faFileImport} iconSize={24} />}
            onClick={() => createMissingUoms()}
            label="Create Missing UoMs"
            labelPlacement="end"
          />
        </>
      </SideSheet>

      {/* Select UoM */}
      {currentUomInfoRef.current && (
        <SelectUom
          uomInfo={currentUomInfoRef.current}
          uomList={destinationUoms}
          open={selectUomOpen}
          onClose={() => setSelectUomOpen(false)}
          selectUom={onUomSelected}
        />
      )}
    </>
  );
}
