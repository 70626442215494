import React, { useEffect, useMemo } from "react";
import { AlertColor } from "@mui/material";
import { useToastNotification } from "../../contexts/toast-notification/ToastNotificationContext";

export type QueryToastProps = {
  toastOpen: boolean;
  isLoading: boolean;
  loadingText?: string;
  isSuccess: boolean;
  successText?: string;
  isError: boolean;
  errorText?: string;
};

export default function QueryToast({
  toastOpen,
  isLoading,
  loadingText,
  isSuccess,
  successText,
  isError,
  errorText,
}: QueryToastProps) {
  const { displayToast } = useToastNotification();

  const toastText = useMemo(() => {
    return isLoading ? loadingText ?? "" : isSuccess ? successText ?? "" : isError ? errorText ?? "" : "";
  }, [isLoading, isSuccess, isError]);

  const alertSeverity = useMemo<AlertColor>(() => {
    return isLoading ? "info" : isSuccess ? "success" : isError ? "error" : "info";
  }, [isLoading, isSuccess, isError]);

  useEffect(() => {
    if (toastOpen === true) {
      displayToast({ message: toastText, severity: alertSeverity, duration: null });
    }
  }, [toastOpen, toastText, alertSeverity]);

  return <></>;
}
