import React from "react";
import { ICellRendererParams } from "ag-grid-community";
import { IconDefinition } from "@elynx/fontawesome-svg-core";
import { SvgIconPropsSizeOverrides } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import FontAwesomeIconButton from "../../font-awesome-icon-button/FontAwesomeIconButton";

export type IconRendererProps = {
  disabled?: boolean;
  state: boolean | ((data: unknown) => boolean);
  trueIcon?: IconDefinition | null;
  falseIcon?: IconDefinition | null;
  fontSize?: OverridableStringUnion<"inherit" | "large" | "medium" | "small", SvgIconPropsSizeOverrides>;
  iconSize?: number;
  trueTooltipText?: string;
  falseTooltipText?: string;
  shouldDisplay: boolean | ((data: unknown) => boolean);
  onClick: (data: unknown) => void;
};

export type IconCellRendererProps = IconRendererProps & ICellRendererParams;

export default function IconCellRenderer({
  disabled,
  state,
  trueIcon,
  falseIcon,
  fontSize,
  iconSize,
  trueTooltipText,
  falseTooltipText,
  node,
  shouldDisplay,
  onClick,
}: IconCellRendererProps) {
  const shouldDisplayResult = true === (typeof shouldDisplay === "function" ? shouldDisplay(node.data) : shouldDisplay);
  const stateResult = true === (typeof state === "function" ? state(node.data) : state);
  const displayTrueIcon = shouldDisplayResult && stateResult && trueIcon;
  const displayFalseIcon = shouldDisplayResult && !stateResult && falseIcon;

  return (
    <>
      {displayTrueIcon && (
        <FontAwesomeIconButton
          icon={trueIcon}
          fontSize={fontSize}
          iconSize={iconSize}
          onClick={() => onClick(node.data)}
          disabled={disabled}
          title={trueTooltipText}
        />
      )}
      {displayFalseIcon && (
        <FontAwesomeIconButton
          icon={falseIcon}
          fontSize={fontSize}
          iconSize={iconSize}
          onClick={() => onClick(node.data)}
          disabled={disabled}
          title={falseTooltipText}
        />
      )}
    </>
  );
}
