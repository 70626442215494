import { addHours } from "date-fns";
import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { ILogQueryResult, LogQueryProperties } from "./Models";

export type UseLogDataParameters = {
  enabled: boolean;
  logQueryProperties: LogQueryProperties;
  autoRefreshSeconds: number
};

export function makeQueryKey(logQueryProperties: LogQueryProperties) {
  return [keys.all, logQueryProperties];
}

export function useLogData({ enabled, logQueryProperties, autoRefreshSeconds }: UseLogDataParameters) {
  const { axios: ax } = useAxios();

  //create the query key from the supplied logQueryProperties
  const queryKey = makeQueryKey(logQueryProperties);

  return useQuery<ILogQueryResult, Error>(
    queryKey,
    async ({ signal }) => {
      //we have to setup the timestamp part of the query inside this function
      //so that it will be updated every time a refetch is done
      let queryProperties = {...logQueryProperties} as LogQueryProperties;

      //unless the user selected "Custom" as the timeframe
      //create the date range from the timeframe and clone a new object
      if (logQueryProperties && logQueryProperties.timeframe && logQueryProperties.timeframe > 0) {
        const endTime = new Date();
        const startTime = addHours(endTime, 0 - logQueryProperties.timeframe);
        queryProperties = {
          ...logQueryProperties,
          startTime: startTime,
          endTime: endTime,
        };
      }

      //now make the actual data call
      const { data } = await ax.post<ILogQueryResult>("log/query2", queryProperties, { signal });
      return data;
    },
    {
      enabled: enabled,
      refetchInterval: autoRefreshSeconds > 0 ? autoRefreshSeconds * 1000 : false, 
      refetchIntervalInBackground: true
    }
  );
}
