import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Button, CircularProgress, MenuItem, Stack, TextField, useTheme } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { AutocompleteOption } from "../../components/form-components/FormInputAutocomplete";
import { useTDTCustomerList } from "../../queries/tag-data-testing/UseTDTCustomerList";
import ContentView from "../../components/content-view/ContentView";
import ContentHeader from "../../components/content-header/ContentHeader";
import { defaultCacheDataQueryProperties } from "../../queries/cache-data/Models";
import { useQueryClient } from "react-query";
import { keys } from "../../queries/cache-data/Keys";
import isEqual from "react-fast-compare";
import { useCacheData } from "../../queries/cache-data/UseCacheData";
import Editor from "@monaco-editor/react";

export default function CacheDataViewer() {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [customerList, setCustomerList] = useState<Array<AutocompleteOption>>([]);
  const [cacheDataQueryProperties, setCacheDataQueryProperties] = useState(defaultCacheDataQueryProperties);
  const [runningQueryProperties, setRunningQueryProperties] = useState(defaultCacheDataQueryProperties);
  const [autoRefreshSeconds, setAutoRefreshSeconds] = useState(0);
  const [retrieveData, setRetrieveData] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [cacheData, setCacheData] = useState<any>(undefined);
  const { data: newCustomerList, isSuccess: isCustomerSuccess } = useTDTCustomerList();

  //use the cache data query to retrieve data for the currently running set of properties
  const {
    data: newCacheData,
    isFetching,
    isSuccess,
    refetch,
  } = useCacheData({
    enabled: retrieveData,
    autoRefreshSeconds: autoRefreshSeconds,
    cacheDataQueryProperties: runningQueryProperties,
  });

  useEffect(() => {
    if (isCustomerSuccess === true && newCustomerList && newCustomerList.length > 0) {
      setCustomerList(
        newCustomerList.map((c) => {
          return { id: c.customerID, label: `${c.customerID} - ${c.customerName}` };
        })
      );
    }
  }, [isCustomerSuccess, newCustomerList]);

  useEffect(() => {
    if (isSuccess && newCacheData) {
      setCacheData(newCacheData);
    }
  }, [isSuccess, newCacheData]);

  //we only want to fetch data when the refresh button is clicked,
  //as opposed to automatically every time a user control is changed,
  //so handle both initiating and canceling the query here
  const handleRefreshButtonClick = () => {
    //if we are currently fetching data, cancel the current query
    if (isFetching) {
      queryClient.cancelQueries(keys.cacheDataQuery(runningQueryProperties));
      setRetrieveData(false);
    }
    //if not, either refetch the current query or start a new one
    else {
      //if the query properties have changed,
      //save them and start a new query
      if (false === isEqual(runningQueryProperties, cacheDataQueryProperties)) {
        setRunningQueryProperties(cacheDataQueryProperties);
        setRetrieveData(true);
      }
      //if not, just refetch
      else {
        refetch();
      }
    }
  };

  return (
    <ContentView>
      <ContentHeader title="Cache Data Viewer" />
      <Stack
        direction="row"
        spacing={3}
        sx={{
          backgroundColor: theme.palette.neutral.lowContrast,
          flexWrap: "wrap",
          padding: "6px",
          rowGap: "6px",
          alignItems: "center",
        }}
      >
        <Autocomplete
          autoHighlight
          size="small"
          options={customerList}
          onChange={(event, value) => {
            if (value) {
              setCacheDataQueryProperties({ ...cacheDataQueryProperties, customerID: value.id });
            }
          }}
          sx={{ width: "40ch" }}
          renderInput={(params) => <TextField {...params} label="Customer" />}
        />
        <TextField
          size="small"
          select
          variant="filled"
          label="Cache"
          value={cacheDataQueryProperties.cacheName ?? ""}
          onChange={(e) => setCacheDataQueryProperties({ ...cacheDataQueryProperties, cacheName: e.target.value })}
          sx={{ width: "25ch" }}
        >
          <MenuItem value={"attribute"}>Attribute</MenuItem>
          <MenuItem value={"formula"}>Formula</MenuItem>
          <MenuItem value={"group-contents"}>Group Contents</MenuItem>
          <MenuItem value={"lookuplist"}>Lookup List</MenuItem>
          <MenuItem value={"organization"}>Organization</MenuItem>
          <MenuItem value={"tat"}>Tag Association Type</MenuItem>
          <MenuItem value={"uifeature"}>UI Feature</MenuItem>
          <MenuItem value={"unitconversion"}>Unit Conversion</MenuItem>
          <MenuItem value={"ac-usergroup"}>User Group Permission</MenuItem>
          <MenuItem value={"user"}>User</MenuItem>
          <MenuItem value={"tagGuid"}>Tag Guid</MenuItem>
        </TextField>
        <Box sx={{ "& > button": { m: 1 }, display: "flex", width: "14ch", marginLeft: "auto !important" }}>
          <Button
            onClick={() => handleRefreshButtonClick()}
            endIcon={isFetching ? <CircularProgress color="inherit" size="1em" /> : <RefreshIcon />}
            variant="contained"
          >
            Refresh
          </Button>
        </Box>
        <TextField
          size="small"
          select
          variant="filled"
          label="Auto-refresh"
          value={autoRefreshSeconds}
          onChange={(e) => setAutoRefreshSeconds(+e.target.value)}
          sx={{ width: "10ch", marginRight: ".5em !important" }}
        >
          <MenuItem value={0}>Off</MenuItem>
          <MenuItem value={30}>30s</MenuItem>
          <MenuItem value={60}>1m</MenuItem>
          <MenuItem value={300}>5m</MenuItem>
          <MenuItem value={900}>15m</MenuItem>
        </TextField>
      </Stack>
      <Box height="100%" padding={1}>
        <Editor
          height="100%"
          theme={theme.palette.mode === "dark" ? "vs-dark" : "light"}
          defaultLanguage={"json"}
          path={runningQueryProperties.cacheName ?? undefined}
          value={JSON.stringify(cacheData, null, "  ")}
        />
      </Box>
    </ContentView>
  );
}
