import React from "react";
import { ICellRendererParams } from "ag-grid-community";
import { faUserCheck, faUserSlash } from "@elynx/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@elynx/react-fontawesome";
import { Typography, useTheme } from "@mui/material";

export default function ElynxUserStatusCellRenderer({ value }: ICellRendererParams) {
  const theme = useTheme();

  return (
    <>
      <FontAwesomeIcon
        icon={true === value ? faUserCheck : faUserSlash}
        size="lg"
        color={true === value ? theme.palette.success.main : theme.palette.text.primary}
      />
      <Typography
        variant="body2"
        sx={{ paddingLeft: ".5em", display: "inline-block" }}
        color={true === value ? theme.palette.success.main : theme.palette.text.primary}
      >
        {true === value ? "Enabled" : "Disabled"}
      </Typography>
    </>
  );
}
