import { useMutation } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { AccountingInvoiceDescription } from "./Models";

export function useGetBlobUrl() {
  const { axios: ax } = useAxios();

  return useMutation<string, Error, AccountingInvoiceDescription>(async (item) => {
    const queryParams = {
      blobName: item.blobName,
    };

    const { data } = await ax.get<string>("accounting/blobs/url", {
      params: queryParams,
    });

    return data;
  });
}
