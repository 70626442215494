import React, { useEffect, useState } from "react";
import { LookupListActionEnum, LookupListInfoItem } from "../../../queries/copy-device/Models";
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { AutocompleteOption } from "../../../components/form-components/FormInputAutocomplete";

export type SelectLookupListProperties = {
  lookupListInfo: LookupListInfoItem;
  lookupListList: Array<AutocompleteOption>;
  open: boolean;
  onClose: () => void;
  selectLookupList: (lookupListInfo: LookupListInfoItem) => void;
};

export default function SelectLookupList({
  lookupListInfo,
  lookupListList,
  open,
  onClose,
  selectLookupList,
}: SelectLookupListProperties) {
  const [selectedLookupList, setSelectedLookupList] = useState<AutocompleteOption>({ id: -1, label: "" });

  //if we have just been opened, reset our selected UoM
  useEffect(() => {
    setSelectedLookupList(
      null != lookupListInfo.dst_lst_name && null != lookupListInfo.dst_lst_id
        ? { id: lookupListInfo.dst_lst_id, label: lookupListInfo.dst_lst_name }
        : { id: -1, label: "" }
    );
  }, [open]);

  const onClick = () => {
    //create a new LookupListInfoItem with the selected ID
    //and send it to the passed in function and close the dialog
    const newLookupListInfo = {
      ...lookupListInfo,
      dst_lst_id: selectedLookupList.id > 0 ? selectedLookupList.id : null,
      dst_lst_name: selectedLookupList.label,
      lookupList_action: selectedLookupList.id > -1 ? LookupListActionEnum.Copy : LookupListActionEnum.DoNotCopy,
    } as LookupListInfoItem;
    selectLookupList(newLookupListInfo);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} scroll="paper" fullWidth={true} maxWidth="sm">
      <DialogTitle>Select Destination Lookup List</DialogTitle>
      <DialogContent dividers={true}>
        <Autocomplete
          autoHighlight
          size="small"
          options={lookupListList}
          getOptionLabel={(option) => (option.id > 0 ? `${option.label} [${option.id}]` : "")}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={selectedLookupList}
          onChange={(event, value) => {
            if (value) {
              setSelectedLookupList(value);
            } else {
              setSelectedLookupList({ id: -1, label: "" });
            }
          }}
          sx={{ width: "50ch" }}
          renderInput={(params) => <TextField {...params} label="Destination Lookup List" />}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="secondary" onClick={onClick}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
