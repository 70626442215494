import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Autocomplete, TextField, Button, CircularProgress } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Stack, Box, useTheme } from "@mui/system";
import { AgGridReact } from "ag-grid-react";
import { CellClickedEvent, ColDef } from "ag-grid-community";
import ContentHeader from "../../components/content-header/ContentHeader";
import ContentView from "../../components/content-view/ContentView";
import { AutocompleteOption } from "../../components/form-components/FormInputAutocomplete";
import { useAppSettings } from "../../contexts/app-context/AppContext";
import { useCustomerList } from "../../queries/customer/UseCustomerList";
import FontAwesomeIconButton from "../../components/font-awesome-icon-button/FontAwesomeIconButton";
import { faFileSlash, faLineColumns, faUsersSlash } from "@elynx/pro-regular-svg-icons";
import IconCellRenderer, { IconRendererProps } from "../../components/ag-grid-extensions/renderers/IconCellRenderer";
import { useScheduledReports } from "../../queries/scheduled-reports/UseScheduledReports";
import {
  ScheduledReport,
  ScheduledReportDelivery,
  ScheduledReportSchedule,
} from "../../queries/scheduled-reports/Models";
import { queryClient } from "../../services/query-client-service/QueryClientService";
import { keys } from "../../queries/scheduled-reports/Keys";
import AutoWrapHeaderGrid from "../../components/wrap-header-grid/AutoWrapHeaderGrid";
import PreFormattedCellRenderer from "../../components/ag-grid-extensions/renderers/PreFormattedCellRenderer";
import { parseISO } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { CentralTimestampFormatter } from "../../components/ag-grid-extensions/utilities/CenteralTimestampFormatter";
import PreformattedGridTextDialog from "../../components/preformatted-grid-text-dialog/PreformattedGridTextDialog";
import { FormatTimestampTZ } from "../../utilities/FormatTimestampTZ";

export default function ScheduledReports() {
  const theme = useTheme();
  const gridRef = useRef<AgGridReact>(null);
  const [appSettings] = useAppSettings();
  const gridTheme = appSettings.themeSet[appSettings.themeSet.mode].grid;
  const [customerList, setCustomerList] = useState<Array<AutocompleteOption>>([]);
  const { data: newCustomerList, isSuccess: isCustomerSuccess } = useCustomerList();
  const [customerID, setCustomerID] = useState<number | undefined>();
  const { data: newScheduledReports, isFetching, isSuccess, refetch } = useScheduledReports(customerID);
  const [scheduledReports, setScheduledReports] = useState<Array<ScheduledReport>>([]);
  const [hasOverrideTats, setHasOverrideTats] = useState(false);
  const gridTextRef = useRef<string | null>(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const formatDeliveries = useCallback((deliveries: Array<ScheduledReportDelivery>) => {
    const lines = deliveries.map((d) => `     ${d.delivery_type} to ${d.delivery_address}`);

    if (lines?.length) {
      lines.splice(0, 0, `${lines.length} Delivery Recipient${lines.length > 1 ? "s" : ""}:`);
    }

    return lines?.join("\n") ?? "";
  }, []);

  const formatSchedules = useCallback((schedules: Array<ScheduledReportSchedule>) => {
    const lines = schedules.map((s) => {
      let atString = "";
      if (null != s.schedule_next_run_time) {
        const ts =
          typeof s.schedule_next_run_time === "string" ? parseISO(s.schedule_next_run_time) : s.schedule_next_run_time;
        if (s.schedule_repeat_unit.toLowerCase() == "minute") {
          atString = ` (next at ${formatInTimeZone(ts, "America/Chicago", "pp zzz")})`;
        } else if (s.schedule_repeat_unit.toLowerCase() == "hour") {
          atString = ` at ${formatInTimeZone(ts, "America/Chicago", "mm:ss")} after the hour`;
        } else if (s.schedule_repeat_unit.toLowerCase() == "day") {
          atString = ` at ${formatInTimeZone(ts, "America/Chicago", "pp zzz")}`;
        } else if (s.schedule_repeat_unit.toLowerCase() == "month") {
          atString = ` at ${formatInTimeZone(ts, "America/Chicago", "pp zzz")} on day ${formatInTimeZone(
            ts,
            "America/Chicago",
            "dd"
          )}`;
        } else if (s.schedule_repeat_unit.toLowerCase() == "week") {
          atString = ` on ${formatInTimeZone(ts, "America/Chicago", "eee")} at ${formatInTimeZone(
            ts,
            "America/Chicago",
            "pp zzz"
          )}`;
        }
      }
      return `     ${s.schedule_active ? "" : "(inactive) "}Execute every ${s.schedule_repeat_interval} ${
        s.schedule_repeat_unit
      }${s.schedule_repeat_interval > 1 ? "s" : ""}${atString}`;
    });

    if (lines?.length) {
      lines.splice(0, 0, `${lines.length} Schedule${lines.length > 1 ? "s" : ""}:`);
    }

    return lines?.join("\n") ?? "";
  }, []);

  useEffect(() => {
    if (isCustomerSuccess === true && newCustomerList && newCustomerList.length > 0) {
      setCustomerList(
        newCustomerList
          .filter((c) => c.customerEnabled === true)
          .map((c) => {
            return { id: c.customerID, label: `${c.customerID} - ${c.customerName}` };
          })
      );
    }
  }, [isCustomerSuccess, newCustomerList]);

  //save the new data
  useEffect(() => {
    if (newScheduledReports) {
      setScheduledReports(
        newScheduledReports.map((r) => {
          return {
            ...r,
            delivery_text: formatDeliveries(r.deliveries),
            schedule_text: formatSchedules(r.schedules),
            disabled: r.schedules.filter((s) => s.schedule_active).length == 0,
            no_deliveries: r.deliveries.length == 0,
          };
        })
      );

      if (newScheduledReports.length > 0) {
        setHasOverrideTats(newScheduledReports.filter((r) => null != r.tat_id).length > 0);
      } else {
        setHasOverrideTats(false);
      }
    }
  }, [isSuccess, newScheduledReports]);

  //if the row contains data,
  //put the data into a ref and open the dialog to show it
  const handlePreformattedCellClick = (event: CellClickedEvent<ScheduledReport, string>) => {
    const selection = document.getSelection();
    if (selection && selection.type === "Range") {
      event.event?.stopPropagation();
    } else if (event.value && event.value.length > 0) {
      gridTextRef.current = event.value;
      setDialogOpen(true);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onDisabledClicked = useCallback(() => {}, []);

  const columnDefs = useMemo<Array<ColDef>>(() => {
    return [
      {
        headerName: "Disabled",
        field: "disabled",
        width: 120,
        cellRenderer: IconCellRenderer,
        cellRendererParams: {
          state: (data: ScheduledReport) => data?.disabled ?? false,
          trueIcon: faFileSlash,
          size: "medium",
          trueTooltipText: "No active schedules",
          disabled: false,
          onClick: onDisabledClicked,
          shouldDisplay: (data: ScheduledReport) => data?.disabled ?? false,
        } as IconRendererProps,
      },
      {
        headerName: "Recipients",
        field: "no_deliveries",
        width: 120,
        cellRenderer: IconCellRenderer,
        cellRendererParams: {
          state: (data: ScheduledReport) => data?.no_deliveries ?? false,
          trueIcon: faUsersSlash,
          size: "medium",
          trueTooltipText: "No deliveries configured",
          disabled: false,
          onClick: onDisabledClicked,
          shouldDisplay: (data: ScheduledReport) => data?.no_deliveries ?? false,
        } as IconRendererProps,
      },
      {
        headerName: "Report Type",
        field: "task_type",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Report Task Name",
        field: "task_name",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Report Name (DRCX)",
        field: "drcx_collection_name",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Organization ID",
        field: "org_id",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        minWidth: 120,
        maxWidth: 150,
      },
      {
        headerName: "Organization Name",
        field: "org_name",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Override Group ID",
        field: "group_id",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        minWidth: 120,
        maxWidth: 150,
      },
      {
        headerName: "Override Group Name",
        field: "group_name",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Override TAT ID",
        field: "tat_id",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        minWidth: 120,
        maxWidth: 150,
        hide: !hasOverrideTats,
      },
      {
        headerName: "Override TAT Name",
        field: "tat_name",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        hide: !hasOverrideTats,
      },
      {
        headerName: "DRCX Object Configuration Description",
        field: "drcx_object_description",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        cellRenderer: PreFormattedCellRenderer,
        cellRendererParams: { maxLines: 8 },
        onCellClicked: handlePreformattedCellClick,
        autoHeight: true,
        wrapText: true,
        cellStyle: { wordBreak: "normal", whiteSpace: "pre-wrap" },
      },
      {
        headerName: "Last Run Time",
        field: "task_last_run_time",
        valueFormatter: CentralTimestampFormatter,
      },
      {
        headerName: "Deliveries",
        field: "delivery_text",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        // valueFormatter: (params: ValueFormatterParams<ScheduledReport, Array<ScheduledReportDelivery>>) => {
        //   const lines = params.value.map((d) => `     ${d.delivery_type} to ${d.delivery_address}`);

        //   if (lines?.length) {
        //     lines.splice(0, 0, `${lines.length} Delivery Recipient${lines.length > 1 ? "s" : ""}:`);
        //   }

        //   return lines?.join("\n") ?? "";
        // },
        cellRenderer: PreFormattedCellRenderer,
        cellRendererParams: { maxLines: 8 },
        onCellClicked: handlePreformattedCellClick,
        autoHeight: true,
        wrapText: true,
        cellStyle: { wordBreak: "normal", whiteSpace: "pre-wrap" },
      },
      {
        headerName: "Schedules",
        field: "schedule_text",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        // valueFormatter: (params: ValueFormatterParams<ScheduledReport, Array<ScheduledReportSchedule>>) => {
        //   const lines = params.value.map((s) => {
        //     let atString = "";
        //     if (null != s.schedule_next_run_time) {
        //       const ts =
        //         typeof s.schedule_next_run_time === "string"
        //           ? parseISO(s.schedule_next_run_time)
        //           : s.schedule_next_run_time;
        //       if (s.schedule_repeat_unit.toLowerCase() == "minute") {
        //         atString = ` (next at ${formatInTimeZone(ts, "America/Chicago", "pp zzz")})`;
        //       } else if (s.schedule_repeat_unit.toLowerCase() == "hour") {
        //         atString = ` at ${formatInTimeZone(ts, "America/Chicago", "mm:ss")} after the hour`;
        //       } else if (s.schedule_repeat_unit.toLowerCase() == "day") {
        //         atString = ` at ${formatInTimeZone(ts, "America/Chicago", "pp zzz")}`;
        //       } else if (s.schedule_repeat_unit.toLowerCase() == "month") {
        //         atString = ` at ${formatInTimeZone(ts, "America/Chicago", "pp zzz")} on day ${formatInTimeZone(
        //           ts,
        //           "America/Chicago",
        //           "dd"
        //         )}`;
        //       } else if (s.schedule_repeat_unit.toLowerCase() == "week") {
        //         atString = ` on ${formatInTimeZone(ts, "America/Chicago", "eee")} at ${formatInTimeZone(
        //           ts,
        //           "America/Chicago",
        //           "pp zzz"
        //         )}`;
        //       }
        //     }
        //     return `     ${s.schedule_active ? "" : "(inactive) "}Execute every ${s.schedule_repeat_interval} ${
        //       s.schedule_repeat_unit
        //     }${s.schedule_repeat_interval > 1 ? "s" : ""}${atString}`;
        //   });

        //   if (lines?.length) {
        //     lines.splice(0, 0, `${lines.length} Schedule${lines.length > 1 ? "s" : ""}:`);
        //   }

        //   return lines?.join("\n") ?? "";
        // },
        cellRenderer: PreFormattedCellRenderer,
        cellRendererParams: { maxLines: 8 },
        onCellClicked: handlePreformattedCellClick,
        autoHeight: true,
        wrapText: true,
        cellStyle: { wordBreak: "normal", whiteSpace: "pre-wrap" },
      },
    ];
  }, [hasOverrideTats]);

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  //we want to fetch data when the customer is changed or the refresh button is clicked,
  //so handle both initiating and canceling the query here
  const handleRefreshButtonClick = () => {
    //if we are currently fetching data, cancel the current query
    if (isFetching) {
      queryClient.cancelQueries(keys.queryKey(customerID));
    }
    //if not, either refetch the current query or start a new one
    else {
      refetch();
    }
  };

  const autoSizeColumns = useCallback(() => {
    gridRef.current?.api.autoSizeAllColumns(true);
  }, []);

  const getExportFileName = useCallback(() => {
    return `FirstAndLastValues_CID${customerID}`;
  }, [customerID]);

  const handleExportCSVClick = useCallback(() => {
    const defaultFileName = `${getExportFileName()}.csv`;
    gridRef.current?.api.exportDataAsCsv({
      fileName: defaultFileName,
      //we need to convert datetime strings for export like we do for display
      processCellCallback: (params) => {
        const colId = params.column.getColId();
        if (params.value) {
          return colId == "task_last_run_time"
            ? FormatTimestampTZ(params.value, "America/Chicago")
            : params.value?.toString();
        }
      },
    });
  }, [customerID]);

  //hide the dialog
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <ContentView>
      <ContentHeader title="Scheduled Reports" />
      <Stack
        direction="row"
        spacing={3}
        sx={{
          backgroundColor: theme.palette.neutral.lowContrast,
          flexWrap: "wrap",
          padding: "6px",
          rowGap: "6px",
          alignItems: "center",
        }}
      >
        <Autocomplete
          autoHighlight
          size="small"
          options={customerList}
          onChange={(event, value) => {
            setCustomerID(value?.id);
          }}
          sx={{ width: "42ch" }}
          renderInput={(params) => <TextField {...params} label="Customer" />}
        />
        <FontAwesomeIconButton
          icon={faLineColumns}
          iconSize={32}
          title="Size all columns to fit data"
          onClick={() => autoSizeColumns()}
          sx={{ marginLeft: "24px !important" }}
        />
        <Box
          sx={{
            "& > button": { m: 1 },
            display: "flex",
            width: "20ch",
            marginLeft: "24px",
          }}
        >
          <Button
            onClick={handleExportCSVClick}
            variant="contained"
            disabled={!(scheduledReports && scheduledReports.length > 0)}
          >
            Export CSV
          </Button>
        </Box>
        <Box
          sx={{
            "& > button": { m: 1 },
            display: "flex",
            width: "14ch",
            marginLeft: "auto !important",
            marginRight: "1em !important",
          }}
        >
          <Button
            onClick={() => handleRefreshButtonClick()}
            endIcon={isFetching ? <CircularProgress color="inherit" size="1em" /> : <RefreshIcon />}
            variant="contained"
          >
            Refresh
          </Button>
        </Box>
      </Stack>

      {/* It appears that in order to make the grid fit into a flex scheme 
      it needs to be contained by a div with a hard size (calculated by flex, in this case) that it can fill completely */}
      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          "& .ag-theme-alpine .ag-cell-value": {
            lineHeight: "20px !important",
            wordBreak: "normal",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
          "& .ag-theme-alpine-dark .ag-cell-value": {
            lineHeight: "20px !important",
            wordBreak: "normal",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
        }}
      >
        <div className={gridTheme} style={{ height: "100%", width: "100%" }}>
          <AutoWrapHeaderGrid
            ref={gridRef}
            defaultColDef={defaultColDef}
            rowData={scheduledReports}
            columnDefs={columnDefs}
            ensureDomOrder={true}
            enableCellTextSelection={true}
            onFirstDataRendered={autoSizeColumns}
            suppressClickEdit={true}
            suppressCellFocus={true}
            alwaysMultiSort={true}
          />
        </div>
      </Box>

      {/* This dialog shows the preformatted text if a preformatted cell is clicked */}
      {gridTextRef.current && gridTextRef.current.length > 0 && (
        <PreformattedGridTextDialog
          gridText={gridTextRef.current}
          dialogOpen={dialogOpen}
          onClose={handleDialogClose}
        />
      )}
    </ContentView>
  );
}
