import React from "react";
import { Box } from "@mui/material";

export type StepMenuLandingTitleProps = {
  title: string;
};

export default function StepMenuLandingTitle({ title }: StepMenuLandingTitleProps) {
  return (
    <>
      <Box
        sx={{
          padding: 1,
          paddingTop: 2,
          paddingBottom: 2,
        }}
      >
        {title}
      </Box>
      {/* <Divider orientation="vertical" flexItem variant="middle" /> */}
    </>
  );
}
