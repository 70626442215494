import React, { useCallback } from "react";
import VirtualizedList, { VirtualizedListItem } from "../../components/virtualized-list/VirtualizedList";
import ServicePanel, { ServicePanelProps } from "./ServicePanel";

export type ServicePanelListItemType = {
  key: string;
} & ServicePanelProps &
  VirtualizedListItem;

export type ServicePanelListProps = {
  items: Array<ServicePanelListItemType>;
};

export function ServicePanelList({ items }: ServicePanelListProps) {
  const renderItem = useCallback((index: number, item: ServicePanelListItemType) => {
    return (
      <ServicePanel
        key={index}
        job={item.job}
        smallRowCount={item.smallRowCount}
        openLogItemDialog={item.openLogItemDialog}
        maxLinesPerLogRow={item.maxLinesPerLogRow}
        autoRefreshSeconds={item.autoRefreshSeconds}
        openPodListDialog={item.openPodListDialog}
        openLogDataViewer={item.openLogDataViewer}
        openRunJobDialog={item.openRunJobDialog}
      />
    );
  }, []);

  return <VirtualizedList items={items} renderItem={renderItem} increaseViewportPixels={600} />;
}
