import React from "react";
import { Checkbox } from "@mui/material";
import { CustomCellEditorProps } from "ag-grid-react";

// eslint-disable-next-line react/display-name
export default ({ value, onValueChange }: CustomCellEditorProps<never, boolean>) => {
  const isChecked = value ?? false;

  return (
    <Checkbox size="small" checked={isChecked} onChange={() => onValueChange(!isChecked)} />
  );
};
