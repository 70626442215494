import { getEmptyMetricFilterQueryParam, MetricsQueryProperties } from "../../queries/metrics-data/Models";

//these are the strings used by the reducer
export enum MetricsActionTypeEnum {
  SetMetricType = "set-metric-type",
  SetPrimaryKey = "set-primary-key",
  SetMaxCount = "set-max-count",
  SetTimeframe = "set-timeframe",
  SetStartTime = "set-start-time",
  SetEndTime = "set-end-time",
  SetFilterField = "set-filter-field",
  SetFilterType = "set-filter-type",
  SetFilterOperator = "set-filter-operator",
  SetFilterValue = "set-filter-value",
  AddFilterItem = "add-filter-item",
  RemoveFilterItem = "remove-filter-item",
}

//define a type per action to enforce typing very strictly
export type SetMetricTypeAction = {
  type: MetricsActionTypeEnum.SetMetricType;
  metricType: string;
};

export type SetPrimaryKeyAction = {
  type: MetricsActionTypeEnum.SetPrimaryKey;
  primaryKey: string;
};

export type SetMaxCountAction = {
  type: MetricsActionTypeEnum.SetMaxCount;
  maxCount: number;
};

export type SetTimeframeAction = {
  type: MetricsActionTypeEnum.SetTimeframe;
  timeframe: number;
};

export type SetStartTimeAction = {
  type: MetricsActionTypeEnum.SetStartTime;
  startTime: Date;
};

export type SetEndTimeAction = {
  type: MetricsActionTypeEnum.SetEndTime;
  endTime: Date;
};

export type SetFilterField = {
  type: MetricsActionTypeEnum.SetFilterField;
  filterID: number;
  field: string;
};

export type SetFilterType = {
  type: MetricsActionTypeEnum.SetFilterType;
  filterID: number;
  fieldType: string;
};

export type SetFilterOperator = {
  type: MetricsActionTypeEnum.SetFilterOperator;
  filterID: number;
  operator: string;
};

export type SetFilterValue = {
  type: MetricsActionTypeEnum.SetFilterValue;
  filterID: number;
  value: string;
};

export type AddFilterItem = {
  type: MetricsActionTypeEnum.AddFilterItem;
  filterID: number;
};

export type RemoveFilterItem = {
  type: MetricsActionTypeEnum.RemoveFilterItem;
  filterID: number;
};

//an action sent to the reducer can be any of the above individual actions
export type MetricsViewerActions =
  | SetMetricTypeAction
  | SetPrimaryKeyAction
  | SetMaxCountAction
  | SetTimeframeAction
  | SetStartTimeAction
  | SetEndTimeAction
  | SetFilterField
  | SetFilterType
  | SetFilterOperator
  | SetFilterValue
  | AddFilterItem
  | RemoveFilterItem;

//and now the reducer itself
export const auditTrailActionReducer = (
  state: MetricsQueryProperties,
  action: MetricsViewerActions
): MetricsQueryProperties => {
  //because of nested array of filter params
  //we have to do one extra step to copy the state
  const newState: MetricsQueryProperties = structuredClone(state);
  
  switch (action.type) {
    case MetricsActionTypeEnum.SetMetricType:
      newState.metricType = action.metricType;
      newState.primaryKey = null;
      newState.filterItems = [getEmptyMetricFilterQueryParam()];
      return newState;
    case MetricsActionTypeEnum.SetPrimaryKey:
      newState.primaryKey = action.primaryKey;
      return newState;
    case MetricsActionTypeEnum.SetMaxCount:
      newState.maxCount = action.maxCount;
      return newState;
    case MetricsActionTypeEnum.SetTimeframe:
      newState.timeframe = action.timeframe;
      return newState;
    case MetricsActionTypeEnum.SetStartTime:
      newState.startTime = action.startTime;
      return newState;
    case MetricsActionTypeEnum.SetEndTime:
      newState.endTime = action.endTime;
      return newState;
    case MetricsActionTypeEnum.SetFilterField:
      //make sure we can get the filter item we need
      if (newState.filterItems.length > action.filterID) {
        newState.filterItems[action.filterID].field = action.field;
      }
      return newState;
    case MetricsActionTypeEnum.SetFilterType:
      //make sure we can get the filter item we need
      if (newState.filterItems.length > action.filterID) {
        newState.filterItems[action.filterID].type = action.fieldType;
      }
      return newState;
    case MetricsActionTypeEnum.SetFilterOperator:
      //make sure we can get the filter item we need
      if (newState.filterItems.length > action.filterID) {
        newState.filterItems[action.filterID].operator = action.operator;
      }
      return newState;
    case MetricsActionTypeEnum.SetFilterValue:
      //make sure we can get the filter item we need
      if (newState.filterItems.length > action.filterID) {
        newState.filterItems[action.filterID].value = action.value;
      }
      return newState;
    case MetricsActionTypeEnum.AddFilterItem:
      //make sure the filter ID is valid
      if (newState.filterItems.length > action.filterID) {
        newState.filterItems.splice(action.filterID + 1, 0, getEmptyMetricFilterQueryParam());
      }
      return newState;
    case MetricsActionTypeEnum.RemoveFilterItem:
      //make sure the filter ID is valid
      if (newState.filterItems.length > action.filterID) {
        //if the last item was "deleted" replace it with a blank item
        if (newState.filterItems.length == 1 && action.filterID == 0) {
          newState.filterItems.splice(action.filterID, 1, getEmptyMetricFilterQueryParam());
        } else {
          newState.filterItems.splice(action.filterID, 1);
        }
      }
      return newState;
    default:
      return state;
  }
};
