import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, CircularProgress, MenuItem, Stack, TextField, useTheme } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import ContentHeader from "../../components/content-header/ContentHeader";
import ContentView from "../../components/content-view/ContentView";
import { SqlClusterName } from "../../queries/sql-cluster-names/Models";
import { useSqlClusterNames } from "../../queries/sql-cluster-names/UseSqlClusterNames";
import { AgGridReact } from "ag-grid-react";
import { useAppSettings } from "../../contexts/app-context/AppContext";
import { ColDef } from "ag-grid-community";

export default function SqlClusterNames() {
  const theme = useTheme();
  const gridRef = useRef<AgGridReact>(null);
  const [appSettings] = useAppSettings();
  const gridTheme = appSettings.themeSet[appSettings.themeSet.mode].grid;
  const [clusterNames, setClusterNames] = useState<Array<SqlClusterName>>([]);
  const [autoRefreshSeconds, setAutoRefreshSeconds] = useState(0);
  const {
    data: newClusterNames,
    isSuccess,
    isFetching: isFetchingData,
    refetch: refetchData,
  } = useSqlClusterNames({ autoRefreshSeconds: autoRefreshSeconds });

  //if we get a new user list, put it in our state
  useEffect(() => {
    if (newClusterNames) {
      setClusterNames(newClusterNames);
    }
  }, [isSuccess, newClusterNames]);

  const columnDefs = useMemo<Array<ColDef>>(() => {
    return [
      {
        headerName: "Server Name",
        field: "serverName",
      },
      {
        headerName: "Cluster Name for Open",
        field: "clusterOpen",
      },
      {
        headerName: "Cluster Name for Query",
        field: "clusterQuery",
      },
    ];
  }, []);

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: false,
      resizable: true,
    };
  }, []);

  const autoSizeColumns = useCallback(() => {
    gridRef.current?.api.autoSizeAllColumns();
  }, []);

  return (
    <ContentView>
      <ContentHeader title={"SQL Cluster Names"} />
      <Stack
        direction="row"
        spacing={3}
        sx={{
          backgroundColor: theme.palette.neutral.lowContrast,
          flexWrap: "wrap",
          padding: "6px",
          rowGap: "6px",
          alignItems: "center",
        }}
      >
        <Box sx={{ "& > button": { m: 1 }, display: "flex", width: "14ch", marginLeft: "auto !important" }}>
          <Button
            onClick={() => refetchData()}
            endIcon={isFetchingData ? <CircularProgress color="inherit" size="1em" /> : <RefreshIcon />}
            variant="contained"
          >
            Refresh
          </Button>
        </Box>
        <TextField
          size="small"
          select
          variant="filled"
          label="Auto-refresh"
          value={autoRefreshSeconds}
          onChange={(e) => setAutoRefreshSeconds(+e.target.value)}
          sx={{ width: "10ch", marginRight: ".5em !important" }}
        >
          <MenuItem value={0}>Off</MenuItem>
          <MenuItem value={30}>30s</MenuItem>
          <MenuItem value={60}>1m</MenuItem>
          <MenuItem value={300}>5m</MenuItem>
          <MenuItem value={900}>15m</MenuItem>
        </TextField>
      </Stack>

      {/* It appears that in order to make the grid fit into a flex scheme 
      it needs to be contained by a div with a hard size (calculated by flex, in this case) that it can fill completely */}
      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          "& .ag-theme-alpine .ag-cell-value": {
            lineHeight: "20px !important",
            wordBreak: "normal",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
          "& .ag-theme-alpine-dark .ag-cell-value": {
            lineHeight: "20px !important",
            wordBreak: "normal",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
        }}
      >
        <div className={gridTheme} style={{ height: "100%", width: "100%" }}>
          <AgGridReact
            ref={gridRef}
            defaultColDef={defaultColDef}
            rowData={clusterNames}
            columnDefs={columnDefs}
            ensureDomOrder={true}
            enableCellTextSelection={true}
            onFirstDataRendered={autoSizeColumns}
            onGridReady={autoSizeColumns}
            onModelUpdated={autoSizeColumns}
          />
        </div>
      </Box>
    </ContentView>
  );
}
