import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  MenuItem,
  Stack,
  TextField,
  useTheme,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useQueryClient } from "react-query";
import { AutocompleteOption } from "../../components/form-components/FormInputAutocomplete";
import { useTDTCustomerList } from "../../queries/tag-data-testing/UseTDTCustomerList";
import ContentView from "../../components/content-view/ContentView";
import ContentHeader from "../../components/content-header/ContentHeader";
import { useReportTasks } from "../../queries/report-scheduler/UseReportTasks";
import {
  defaultReportTaskQueryProperties,
  ReportSchedule,
  ReportTask,
  ReportTaskStatusEnum,
} from "../../queries/report-scheduler/Models";
import { keys } from "../../queries/report-scheduler/Keys";
import isEqual from "react-fast-compare";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { useAppSettings } from "../../contexts/app-context/AppContext";
import { useResetScheduleStatus } from "../../queries/report-scheduler/UseResetScheduleStatus";
import { faFileSlash } from "@elynx/pro-regular-svg-icons";
import IconsCellRenderer, {
  IconsCellRendererProps,
} from "../../components/ag-grid-extensions/renderers/IconsCellRenderer";
import { useToastNotification } from "../../contexts/toast-notification/ToastNotificationContext";
import { LocalTimestampFormatter } from "../../components/ag-grid-extensions/utilities/LocalTimestampFormatter";

type TaskSchedulePair = {
  task: ReportTask;
  schedule: ReportSchedule;
};

export default function ReportMonitor() {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const gridRef = useRef<AgGridReact>(null);
  const [appSettings] = useAppSettings();
  const gridTheme = appSettings.themeSet[appSettings.themeSet.mode].grid;
  const { displayToast } = useToastNotification();
  const [customerList, setCustomerList] = useState<Array<AutocompleteOption>>([]);
  const [autoRefreshSeconds, setAutoRefreshSeconds] = useState(0);
  const [retrieveData, setRetrieveData] = useState(false);
  const [reportTaskQueryProperties, setReportTaskQueryProperties] = useState(defaultReportTaskQueryProperties);
  const [runningQueryProperties, setRunningQueryProperties] = useState(defaultReportTaskQueryProperties);
  const [includeCompletedIdle, setIncludeCompletedIdle] = useState(false);
  const [reportTaskList, setReportTaskList] = useState<Array<TaskSchedulePair>>([]);
  const unfilteredReportTaskListRef = useRef<Array<TaskSchedulePair>>([]);
  const { data: newCustomerList, isSuccess: isCustomerSuccess } = useTDTCustomerList();
  const { mutate: resetScheduleStatus } = useResetScheduleStatus();

  //use the report tasks query to retrieve data for the currently running set of properties
  const {
    data: newTaskList,
    isFetching,
    isSuccess,
    refetch,
  } = useReportTasks({
    enabled: retrieveData,
    autoRefreshSeconds: autoRefreshSeconds,
    reportTaskQueryProperties: runningQueryProperties,
  });

  const filterTasks = useCallback(
    (tasks: Array<TaskSchedulePair>) => {
      return includeCompletedIdle
        ? tasks
        : tasks.filter(
            (t) =>
              t.schedule.status == ReportTaskStatusEnum.Pending ||
              t.schedule.status == ReportTaskStatusEnum.Running ||
              t.schedule.status == ReportTaskStatusEnum.TimedOut ||
              t.schedule.status == ReportTaskStatusEnum.Error
          );
    },
    [includeCompletedIdle]
  );

  useEffect(() => {
    if (isCustomerSuccess === true && newCustomerList && newCustomerList.length > 0) {
      //prepend a special entry for all customers
      const allCustomers = [{ id: -1, label: "-- All Customers --" }];
      const newCustomers = newCustomerList.map((c) => {
        return { id: c.customerID, label: `${c.customerID} - ${c.customerName}` };
      });

      setCustomerList([...allCustomers, ...newCustomers]);
    }
  }, [isCustomerSuccess, newCustomerList]);

  useEffect(() => {
    if (isSuccess && newTaskList) {
      //flatten the retrieved data so that we have
      //one array item per task/schedule pair
      const pairs = newTaskList.flatMap((t) => t.schedules.map((s) => ({ task: t, schedule: s })));

      //store the unfiltered list
      unfilteredReportTaskListRef.current = pairs;

      //then the filtered list
      setReportTaskList(filterTasks(pairs));
    }
  }, [isSuccess, newTaskList]);

  useEffect(() => {
    if (unfilteredReportTaskListRef.current.length > 0) {
      setReportTaskList(filterTasks(unfilteredReportTaskListRef.current));
    }
  }, [includeCompletedIdle]);

  //if auto refresh is changed but is not turned off
  //refetch the data
  useEffect(() => {
    if (autoRefreshSeconds > 0) {
      refetch();
      setRetrieveData(true);
    }
  }, [autoRefreshSeconds]);

  //we only want to fetch data when the refresh button is clicked,
  //as opposed to automatically every time a user control is changed,
  //so handle both initiating and canceling the query here
  const handleRefreshButtonClick = () => {
    //if we are currently fetching data, cancel the current query
    if (isFetching) {
      queryClient.cancelQueries(keys.reportTasksCustomer(runningQueryProperties));
      setRetrieveData(false);
    }
    //if not, either refetch the current query or start a new one
    else {
      //if the query properties have changed,
      //save them and start a new query
      if (false === isEqual(runningQueryProperties, reportTaskQueryProperties)) {
        setRunningQueryProperties(reportTaskQueryProperties);
        setRetrieveData(true);
      }
      //if not, just refetch
      else {
        refetch();
      }
    }
  };

  const handleResetStatusClick = useCallback((item: TaskSchedulePair) => {
    if (item) {
      resetScheduleStatus(
        { scheduleID: item.schedule.id },
        {
          onSuccess: () => {
            displayToast({
              message: `Reset status for schedule ${item.schedule.description} successfully`,
              severity: "success",
            });
            refetch();
          },
          onError: (error) =>
            displayToast({
              message: `Error resetting status for schedule ${item.schedule.description}: ${error}`,
              severity: "error",
              duration: 15000,
            }),
        }
      );
    }
  }, []);

  const shouldDisplayResetStatus = useCallback((item: TaskSchedulePair) => {
    return item.schedule.allowStatusReset ?? false;
  }, []);

  const columnDefs = useMemo<Array<ColDef>>(() => {
    return [
      {
        headerName: "Customer ID",
        field: "schedule.customerId",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        minWidth: 120,
        maxWidth: 170,
      },
      {
        headerName: "Task ID",
        field: "task.id",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["lessThan", "lessThanOrEqual", "greaterThan", "greaterThanOrEqual"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        minWidth: 110,
        maxWidth: 120,
      },
      {
        headerName: "Task Name",
        field: "task.name",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Schedule ID",
        field: "schedule.id",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["lessThan", "lessThanOrEqual", "greaterThan", "greaterThanOrEqual"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        minWidth: 130,
        maxWidth: 150,
      },
      {
        headerName: "Schedule Description",
        field: "schedule.description",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Status",
        field: "schedule.statusDesc",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Actions",
        colId: "actions",
        cellRenderer: IconsCellRenderer,
        cellRendererParams: {
          iconDescriptions: [
            {
              trueIcon: faFileSlash,
              fontSize: "medium",
              onClick: handleResetStatusClick,
              trueTooltipText: "Reset to Idle",
              shouldDisplay: shouldDisplayResetStatus,
            },
          ],
        } as IconsCellRendererProps,
        width: 200,
        autoHeight: true,
      },
      {
        headerName: "Status Changed",
        field: "schedule.statusChangeTimeDto",
        valueFormatter: LocalTimestampFormatter,
      },
      {
        headerName: "Attempt #",
        field: "schedule.attempt",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["lessThan", "lessThanOrEqual", "greaterThan", "greaterThanOrEqual"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        minWidth: 150,
        maxWidth: 170,
      },
      {
        headerName: "Next Run Time",
        field: "schedule.nextRunTimeDto",
        valueFormatter: LocalTimestampFormatter,
      },
      {
        headerName: "Next Scheduled Time",
        field: "schedule.nextScheduledTimeDto",
        valueFormatter: LocalTimestampFormatter,
      },
    ];
  }, []);

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  const autoSizeColumns = useCallback(() => {
    gridRef.current?.api.autoSizeAllColumns();
  }, []);

  return (
    <ContentView>
      <ContentHeader title="Report Scheduler Monitor" />
      <Stack
        direction="row"
        spacing={3}
        sx={{
          backgroundColor: theme.palette.neutral.lowContrast,
          flexWrap: "wrap",
          padding: "6px",
          rowGap: "6px",
          alignItems: "center",
        }}
      >
        <Autocomplete
          autoHighlight
          size="small"
          options={customerList}
          onChange={(event, value) => {
            if (value) {
              setReportTaskQueryProperties({ ...reportTaskQueryProperties, customerID: +value.id });
            }
          }}
          sx={{ width: "40ch" }}
          renderInput={(params) => <TextField {...params} label="Customer" />}
        />
        <FormControlLabel
          value="ml"
          control={
            <Checkbox
              sx={{ paddingLeft: "2em", paddingRight: "4px" }}
              checked={includeCompletedIdle}
              onChange={(e) => setIncludeCompletedIdle(e.target.checked)}
            />
          }
          label="Show sucessfully Completed/Idle Tasks"
          labelPlacement="end"
        />
        <Box sx={{ "& > button": { m: 1 }, display: "flex", width: "14ch", marginLeft: "auto !important" }}>
          <Button
            onClick={() => handleRefreshButtonClick()}
            endIcon={isFetching ? <CircularProgress color="inherit" size="1em" /> : <RefreshIcon />}
            variant="contained"
          >
            Refresh
          </Button>
        </Box>
        <TextField
          size="small"
          select
          variant="filled"
          label="Auto-refresh"
          value={autoRefreshSeconds}
          onChange={(e) => setAutoRefreshSeconds(+e.target.value)}
          sx={{ width: "10ch", marginRight: ".5em !important" }}
        >
          <MenuItem value={0}>Off</MenuItem>
          <MenuItem value={30}>30s</MenuItem>
          <MenuItem value={60}>1m</MenuItem>
          <MenuItem value={300}>5m</MenuItem>
          <MenuItem value={900}>15m</MenuItem>
        </TextField>
      </Stack>
      {/* It appears that in order to make the grid fit into a flex scheme 
      it needs to be contained by a div with a hard size (calculated by flex, in this case) that it can fill completely */}
      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          "& .ag-theme-alpine .ag-cell-value": {
            lineHeight: "20px !important",
            wordBreak: "normal",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
          "& .ag-theme-alpine-dark .ag-cell-value": {
            lineHeight: "20px !important",
            wordBreak: "normal",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
        }}
      >
        <div className={gridTheme} style={{ height: "100%", width: "100%" }}>
          <AgGridReact
            ref={gridRef}
            defaultColDef={defaultColDef}
            rowData={reportTaskList}
            columnDefs={columnDefs}
            ensureDomOrder={true}
            enableCellTextSelection={true}
            onFirstDataRendered={autoSizeColumns}
            // onGridReady={autoSizeColumns}
            // onModelUpdated={autoSizeColumns}
            suppressClickEdit={true}
            suppressCellFocus={true}
            alwaysMultiSort={true}
            columnMenu="legacy"
          />
        </div>
      </Box>
    </ContentView>
  );
}
