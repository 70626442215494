import { useMutation } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { NonPolledDataItem } from "./Models";

export function useGetBlobUrl() {
  const { axios: ax } = useAxios();

  return useMutation<string, Error, NonPolledDataItem>(async (item) => {
    const queryParams = {
      container: item.container,
      directory: item.directory,
      file: item.name,
    };

    const { data } = await ax.get<string>("nonpolleddataops/file/url", {
      params: queryParams,
    });

    return data;
  });
}
