import { FirstLastTagValueRequest } from "../../queries/tag-value/FirstLastTagValueRequest";

//these are the strings used by the reducer
export enum TagValueActionTypeEnum {
  SetCustomer = "set-customer",
  SetOrganization = "set-organization",
  SetIncludeDisabledDevices = "set-disabled_devices",
  SetIncludeHiddenDevices = "set-hidden_devices",
  SetIncludeDisabledTags = "set-disabled_tags",
  SetIncludeHiddenTags = "set-hidden_tags",
  SetSelectedTags = "set-selected-tags",
}

//define a type per action to enforce typing very strictly
export type SetCustomer = {
  type: TagValueActionTypeEnum.SetCustomer;
  customerID: number;
};

export type SetOrganization = {
  type: TagValueActionTypeEnum.SetOrganization;
  organizationID: number;
};

export type SetDisabledDevicesAction = {
  type: TagValueActionTypeEnum.SetIncludeDisabledDevices;
  include: boolean;
};

export type SetHiddenDevicesAction = {
  type: TagValueActionTypeEnum.SetIncludeHiddenDevices;
  include: boolean;
};

export type SetDisabledTagsAction = {
  type: TagValueActionTypeEnum.SetIncludeDisabledTags;
  include: boolean;
};

export type SetHiddenTagsAction = {
  type: TagValueActionTypeEnum.SetIncludeHiddenTags;
  include: boolean;
};

export type SetSelectedTagsAction = {
  type: TagValueActionTypeEnum.SetSelectedTags;
  tagIDs: Array<number>;
};

//an action sent to the reducer can be any of the above individual actions
export type TagValueActions =
  | SetCustomer
  | SetOrganization
  | SetDisabledDevicesAction
  | SetHiddenDevicesAction
  | SetDisabledTagsAction
  | SetHiddenTagsAction
  | SetSelectedTagsAction;

//and now the reducer itself
export const tagValueActionReducer = (
  state: FirstLastTagValueRequest,
  action: TagValueActions
): FirstLastTagValueRequest => {
  const newState = createNewState(state, action);
  return newState;
};

const createNewState = (state: FirstLastTagValueRequest, action: TagValueActions): FirstLastTagValueRequest => {
  switch (action.type) {
    //if the customer has changed, reset the organization ID to null
    case TagValueActionTypeEnum.SetCustomer:
      return {
        ...state,
        customerID: action.customerID,
        organizationID: -1,
        tagIDs: [],
      };
    case TagValueActionTypeEnum.SetOrganization:
      return {
        ...state,
        organizationID: action.organizationID,
        tagIDs: [],
      };
    case TagValueActionTypeEnum.SetIncludeDisabledDevices:
      return {
        ...state,
        disabledDevices: action.include,
      };
    case TagValueActionTypeEnum.SetIncludeHiddenDevices:
      return {
        ...state,
        hiddenDevices: action.include,
      };
    case TagValueActionTypeEnum.SetIncludeDisabledTags:
      return {
        ...state,
        disabledTags: action.include,
      };
    case TagValueActionTypeEnum.SetIncludeHiddenTags:
      return {
        ...state,
        hiddenTags: action.include,
      };
    case TagValueActionTypeEnum.SetSelectedTags:
      return {
        ...state,
        tagIDs: action.tagIDs,
      };
    default:
      return state;
  }
};
