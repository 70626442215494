import React from "react";
import { isMobile, isTablet } from "react-device-detect";
import { AppBar, Toolbar, IconButton, Tooltip, useTheme, Box } from "@mui/material";
import { Menu, Brightness4, Brightness7 } from "@mui/icons-material";
import { useMsal } from "@azure/msal-react";

export type AppHeaderProps = {
  hideMenu?: boolean;
  hideLogo?: boolean;
  onToggleDrawer?: () => void;
  isDarkMode?: boolean;
  onChangeDarkMode?: (isDarkMode: boolean) => void;
};

export default function AppHeader({ onToggleDrawer, isDarkMode, onChangeDarkMode }: AppHeaderProps) {
  const theme = useTheme();
  const isPhone = isMobile && !isTablet;
  const { accounts } = useMsal();

  return (
    <>
      <AppBar
        position="absolute"
        sx={{
          zIndex: isPhone ? theme.zIndex.appBar : theme.zIndex.drawer + 1,
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}
      >
        <Toolbar
          sx={{
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
          }}
        >
          {/* Toggle Drawer Button */}

          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => {
              onToggleDrawer && onToggleDrawer();
            }}
          >
            <Tooltip title="Toggle menu">
              <Menu />
            </Tooltip>
          </IconButton>
          <Box
            sx={{
              display: "flex",
              marginLeft: "auto",
              marginRight: "auto",
              alignItems: "center",
            }}
          >
            <p>eLynx OpsCenter - {accounts[0]?.username}</p>
          </Box>
          {isDarkMode ? (
            <Tooltip arrow placement="left" title={"Light mode"} key="dark">
              <Brightness7
                onClick={() => {
                  onChangeDarkMode && onChangeDarkMode(false);
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip arrow placement="left" title={"Dark mode"} key="light">
              <Brightness4
                onClick={() => {
                  onChangeDarkMode && onChangeDarkMode(true);
                }}
              />
            </Tooltip>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
}
