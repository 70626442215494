import React, { PropsWithChildren } from "react";
import { Paper } from "@mui/material";

export type StepMenuStepsProps = Record<PropertyKey, unknown>;

export default function StepMenuSteps({ children }: PropsWithChildren<StepMenuStepsProps>) {
  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        overflow: "hidden",
      }}
      elevation={0}
    >
      {children}
    </Paper>
  );
}
