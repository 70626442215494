import { useMutation } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { CalcFKVsRequest } from "./Models";

export function useCalcFKVs() {
  const { axios: ax } = useAxios();

  return useMutation<void, Error, CalcFKVsRequest>(async (request) => {
    await ax.post("tagvalueaction/calcfkv", request);
  });
}
