import React from "react";
import { Box, IconButton, MenuItem, Stack, TextField, Tooltip } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { MetricFilterQueryParam } from "../../queries/metrics-data/Models";
import { MetricsActionTypeEnum, MetricsViewerActions } from "./MetricsViewerActions";

export type MetricsFilterProps = {
  filterRows: Array<MetricFilterQueryParam>;
  fieldList: Array<string>;
  dispatchQueryProperties: React.Dispatch<MetricsViewerActions>;
};

const filterDataTypes = ["String", "Int32", "Int64", "Double", "Boolean", "Guid", "DateTime", "Binary"];
const filterOperators = ["=", "<", ">", "<=", ">=", "<>"];

export default function MetricsFilter({ filterRows, fieldList, dispatchQueryProperties }: MetricsFilterProps) {
  return (
    <>
      {fieldList && fieldList.length > 0 && (
        <Stack direction="column" sx={{ paddingTop: 1, paddingBottom: 1, paddingLeft: 1 }}>
          {filterRows.map((row, inx) => {
            return (
              <Stack key={inx} spacing={1} direction="row">
                <Box>
                  <Tooltip title={"Add filter row"}>
                    <IconButton
                      onClick={() =>
                        dispatchQueryProperties({
                          type: MetricsActionTypeEnum.AddFilterItem,
                          filterID: inx,
                        })
                      }
                    >
                      <AddBoxIcon fontSize="large" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={"Delete filter row"}>
                    <IconButton
                      onClick={() =>
                        dispatchQueryProperties({
                          type: MetricsActionTypeEnum.RemoveFilterItem,
                          filterID: inx,
                        })
                      }
                    >
                      <RemoveCircleIcon fontSize="large" />
                    </IconButton>
                  </Tooltip>
                </Box>
                <TextField
                  size="small"
                  select
                  variant="filled"
                  label="Field"
                  value={row.field}
                  onChange={(e) =>
                    dispatchQueryProperties({
                      type: MetricsActionTypeEnum.SetFilterField,
                      filterID: inx,
                      field: e.target.value,
                    })
                  }
                  sx={{ width: "28ch" }}
                >
                  {fieldList.map((fld, inx) => (
                    <MenuItem key={inx} value={fld}>
                      {fld}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  size="small"
                  select
                  variant="filled"
                  label="Data Type"
                  value={row.type}
                  onChange={(e) =>
                    dispatchQueryProperties({
                      type: MetricsActionTypeEnum.SetFilterType,
                      filterID: inx,
                      fieldType: e.target.value,
                    })
                  }
                  sx={{ width: "14ch" }}
                >
                  {filterDataTypes.map((dt, inx) => (
                    <MenuItem key={inx} value={dt}>
                      {dt}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  size="small"
                  select
                  variant="filled"
                  label="Operator"
                  value={row.operator}
                  onChange={(e) =>
                    dispatchQueryProperties({
                      type: MetricsActionTypeEnum.SetFilterOperator,
                      filterID: inx,
                      operator: e.target.value,
                    })
                  }
                  sx={{ width: "14ch" }}
                >
                  {filterOperators.map((op, inx) => (
                    <MenuItem key={inx} value={op}>
                      {op}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  size="small"
                  variant="filled"
                  label="Value"
                  value={row.value}
                  onChange={(e) =>
                    dispatchQueryProperties({
                      type: MetricsActionTypeEnum.SetFilterValue,
                      filterID: inx,
                      value: e.target.value,
                    })
                  }
                  sx={{ width: "28ch" }}
                />
              </Stack>
            );
          })}
        </Stack>
      )}
    </>
  );
}
