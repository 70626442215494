import React, { useCallback, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from "@mui/material";
import { eLynxUserModel } from "../../queries/elynx-user/Models";
import { useConfirm } from "../../contexts/confirm-context/ConfirmContext";
import { useForm } from "react-hook-form";
import { FormInputText } from "../../components/form-components/FormInputText";
import { FormInputSelect } from "../../components/form-components/FormInputSelect";

export type AddUserProperties = {
  open: boolean;
  onClose: () => void;
  saveUser: (user: eLynxUserModel) => void;
};

type AddUserFormInput = {
  globalLogin: string;
  firstName: string;
  lastName: string;
  agentRole: string;
};

//AddUser and EditUser are similar but have different logic in enough places
//that creating separate components seemed less complex, if slightly less DRY,
//than embedding all those "if" statements in a single component
export default function AddUser({ open, onClose, saveUser }: AddUserProperties) {
  const confirm = useConfirm();
  const { control, handleSubmit, reset } = useForm<AddUserFormInput>({
    defaultValues: { globalLogin: "", firstName: "", lastName: "" },
  });

  useEffect(() => {
    //reset the form each time it is opened
    if (true === open) {
      reset();
    }
  }, [open]);

  const onClick = useCallback((data: AddUserFormInput) => {
    const confirmDescription = `Are you sure you want to add eLynx user ${data.globalLogin}?`;

    //confirm rejects the promise if the user does not confirm
    confirm({
      title: "Confirm Add eLynx User",
      description: confirmDescription,
      allowClose: false,
    })
      .then(() => {
        console.log(`Add eLynx user ${data.globalLogin}`);

        //create the user and send it to the save user function
        const newUser = {
          gl_login_name: data.globalLogin,
          user_first_name: data.firstName,
          user_last_name: data.lastName,
          agent_role: data.agentRole
        } as eLynxUserModel;

        saveUser(newUser);
      })
      .catch(() => {
        console.log("Add eLynx user canceled!");
      });

    onClose();
  }, []);

  return (
    (<Dialog open={open} onClose={onClose} scroll="paper" fullWidth={true} maxWidth="sm">
      <DialogTitle>Add User</DialogTitle>
      <DialogContent dividers={true}>
        <FormInputText
          name="globalLogin"
          label="Email Address"
          inputType="email"
          control={control}
          rules={{
            required: { value: true, message: "Email Address is required" },
            minLength: { value: 6, message: "Email Address must contain at least 6 characters" },
            maxLength: { value: 256, message: "Email Address cannot contain more than 256 characters" },
            pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: "Invalid email address" },
          }}
        />
        <FormInputText
          name="firstName"
          label="First Name"
          control={control}
          rules={{
            required: { value: true, message: "First Name is required" },
            maxLength: { value: 50, message: "First Name cannot contain more than 50 characters" },
          }}
        />
        <FormInputText
          name="lastName"
          label="Last Name"
          control={control}
          rules={{
            required: { value: true, message: "Last Name is required" },
            maxLength: { value: 50, message: "Last Name cannot contain more than 50 characters" },
          }}
        />
        <FormInputSelect name="agentRole" label="Agent Role" control={control} sx={{ width: "25ch" }}
          rules={{
            required: { value: true, message: "Agent Role is required" },
          }}
        >
          <MenuItem value="all">All Customers</MenuItem>
          <MenuItem value="internal">Internal Databases Only</MenuItem>
        </FormInputSelect>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="secondary" onClick={handleSubmit(onClick)}>
          Save
        </Button>
      </DialogActions>
    </Dialog>)
  );
}
