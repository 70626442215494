import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { EventHubProcessingMonitorData, EventHubProcessingMonitorQueryProperties } from "./Models";

export type UseEventHubProcessingMonitorMetricsParameters = {
  enabled: boolean;
  queryProperties: EventHubProcessingMonitorQueryProperties;
  refetchSeconds: number;
};

export function UseEventHubProcessingMonitorMetrics({
  enabled,
  queryProperties,
  refetchSeconds
}: UseEventHubProcessingMonitorMetricsParameters) {
  const { axios: ax } = useAxios();

  return useQuery<Array<EventHubProcessingMonitorData>, Error>(
    keys.eventHubProcessingMonitorMetric(queryProperties),
    async ({ signal }) => {
      const { data } = await ax.get<Array<EventHubProcessingMonitorData>>(
        `metrics/EventHubProcessingMonitor2/${queryProperties.configurationKey}/${queryProperties.timeframe}`,
        { signal }
      );
      return data;
    },
    {
      enabled: enabled,
      refetchInterval: refetchSeconds * 1000,
      refetchIntervalInBackground: true,
    }
  );
}
