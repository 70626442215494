import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box } from "@mui/material";
import { useConfirm } from "../../contexts/confirm-context/ConfirmContext";
import { ColDef, EditableCallbackParams, RowEditingStartedEvent, RowValueChangedEvent, IRowNode } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import AutoWrapHeaderGrid from "../../components/wrap-header-grid/AutoWrapHeaderGrid";
import isEqual from "react-fast-compare";
import CheckboxCellEditor from "../../components/ag-grid-extensions/cell-editors/CheckboxCellEditor";
import ActionButtonCellRenderer, {
  ActionButtonRendererProps,
} from "../../components/ag-grid-extensions/renderers/ActionButtonCellRenderer";
import CheckboxCellRenderer, {
  CheckboxRendererProps,
} from "../../components/ag-grid-extensions/renderers/CheckboxCellRenderer";
import RowEditingActionsRenderer from "../../components/ag-grid-extensions/renderers/RowEditingActionsRenderer";
import ContentHeader from "../../components/content-header/ContentHeader";
import ContentView from "../../components/content-view/ContentView";
import { useUserRole } from "../../contexts/user-role-context/UserRoleContext";
import { CustomerModel } from "../../queries/customer/Models";
import { useCustomerList } from "../../queries/customer/UseCustomerList";
import { useDeleteAPIMUsers } from "../../queries/customer/UseDeleteAPIMUsers";
import { useUpdateCustomer } from "../../queries/customer/UseUpdateCustomer";
import { useAppSettings } from "../../contexts/app-context/AppContext";
import { useToastNotification } from "../../contexts/toast-notification/ToastNotificationContext";
import { LocalTimestampFormatter } from "../../components/ag-grid-extensions/utilities/LocalTimestampFormatter";

export default function CustomerList() {
  const confirm = useConfirm();
  const gridRef = useRef<AgGridReact>(null);
  const shouldResize = useRef(true);
  const rowRef = useRef<IRowNode | null>(null);
  const rowDataRef = useRef<CustomerModel | null>(null);
  const isUpdateRef = useRef(false);
  const { isOpsCenterWriterMember } = useUserRole();
  const { displayToast } = useToastNotification();
  const [customers, setCustomers] = useState<Array<CustomerModel>>([]);
  const { data: newCustomerList, isSuccess } = useCustomerList();
  const { mutate: updateCustomer, isLoading: isCustomerSaving } = useUpdateCustomer();
  const { mutate: deleteAPIMUsers, isLoading: isDeletingAPIM } = useDeleteAPIMUsers();
  const [appSettings] = useAppSettings();
  const gridTheme = appSettings.themeSet[appSettings.themeSet.mode].grid;

  //set a flag to indicate that data has actually changed
  const onUpdate = () => {
    isUpdateRef.current = true;
  };

  //set the new customer list in state when it changes
  useEffect(() => {
    if (newCustomerList) {
      setCustomers(newCustomerList);
    }
  }, [isSuccess, newCustomerList]);

  // //ag-grid doesn't automatically redraw rows when the
  // //data that controls visibility updates
  // useEffect(() => {
  //   if (gridRef.current) {
  //     //build an array of the visible rows
  //     try {
  //       const startIndex = gridRef.current.api.getFirstDisplayedRowIndex();
  //       const endIndex = gridRef.current.api.getLastDisplayedRowIndex();
  //       const visibleRows = [] as Array<IRowNode>;
  //       for (let inx = startIndex; inx <= endIndex; inx++) {
  //         const rowNode = gridRef.current.api.getDisplayedRowAtIndex(inx);
  //         if (rowNode) {
  //           visibleRows.push(rowNode);
  //         }
  //       }
  //       //and redraw them all
  //       gridRef.current.api.redrawRows({ rowNodes: visibleRows });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   if (null !== gridRef.current && null !== rowRef.current) {
  //     gridRef.current.api.redrawRows({ rowNodes: [rowRef.current] });
  //   }
  // }, [isCustomerSaving]);

  //the handler for when the Disable APIM Users button is clicked
  const onDeleteAPIMUsers = useCallback((customer: CustomerModel) => {
    const confirmDescription = `Are you sure you want to delete all APIM users in ${customer.customerName} (CID: ${customer.customerID})?`;

    //confirm rejects the promise if the user does not confirm
    confirm({
      title: "Confirm Deleting APIM Users",
      description: confirmDescription,
      allowClose: false,
    })
      .then(() => {
        displayToast({ message: "DisablingAPIM users...", severity: "info", duration: null });
        deleteAPIMUsers(customer, {
          onSuccess: () =>
            displayToast({
              message: `APIM users disabled for customer ${customer.customerName} (${customer.customerID}) successfully`,
              severity: "success",
            }),
          onError: (error) =>
            displayToast({
              message: `Error disabling APIM users for customer ${customer.customerName} (${customer.customerID}): ${error}`,
              severity: "error",
              duration: 15000,
            }),
        });
        console.log(`Deleting APIM users in ${customer.customerName} (CID: ${customer.customerID})`);
      })
      .catch(() => {
        console.log("Deleting APIM users canceled!");
      });
  }, []);

  const shouldDisplayDisableAPIM = useCallback((customer: CustomerModel) => {
    return customer.customerEnabled && customer.cust_has_apim_users;
  }, []);

  const shouldCustomerNameBeEditable = useCallback(({ data }: EditableCallbackParams) => {
    const customer = data as CustomerModel;
    return customer.customerEnabled;
  }, []);

  const columnDefs = useMemo<Array<ColDef>>(() => {
    return [
      {
        colId: "actions",
        valueGetter: () => isOpsCenterWriterMember && !isCustomerSaving,
        cellRenderer: RowEditingActionsRenderer,
        cellRendererParams: {
          onUpdate: onUpdate,
        },
        minWidth: 200,
        maxWidth: 300,
      },
      {
        headerName: "Customer ID",
        field: "customerID",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        minWidth: 120,
        maxWidth: 120,
      },
      {
        headerName: "Customer Name",
        field: "customerName",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        editable: shouldCustomerNameBeEditable,
      },
      {
        headerName: "Enabled",
        field: "customerEnabled",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        cellRenderer: CheckboxCellRenderer,
        cellRendererParams: { disabled: true } as CheckboxRendererProps,
        cellEditor: CheckboxCellEditor,
        editable: true,
        minWidth: 120,
      },
      {
        headerName: "Customer Type",
        field: "cust_type",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        minWidth: 120,
      },
      {
        headerName: "Allow SSO",
        field: "cust_allow_sso",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        cellRenderer: CheckboxCellRenderer,
        cellRendererParams: { disabled: true } as CheckboxRendererProps,
        cellEditor: CheckboxCellEditor,
        editable: true,
      },
      {
        headerName: "Has Scheduled Rules",
        field: "cust_has_scheduled_rules",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        cellRenderer: CheckboxCellRenderer,
        cellRendererParams: { disabled: true } as CheckboxRendererProps,
        cellEditor: CheckboxCellEditor,
        editable: true,
        minWidth: 110,
        maxWidth: 110,
      },
      {
        headerName: "Has Calculated Tags",
        field: "cust_has_calculated_tags",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        cellRenderer: CheckboxCellRenderer,
        cellRendererParams: { disabled: true } as CheckboxRendererProps,
        cellEditor: CheckboxCellEditor,
        editable: true,
        minWidth: 110,
        maxWidth: 110,
      },
      {
        colId: "disableAPIM",
        headerName: "Disable APIM Users",
        field: "cust_has_apim_users",
        cellRenderer: ActionButtonCellRenderer,
        cellRendererParams: {
          disabled: !isOpsCenterWriterMember || isDeletingAPIM,
          buttonText: "Disable",
          shouldDisplay: shouldDisplayDisableAPIM,
          onClick: onDeleteAPIMUsers,
        } as ActionButtonRendererProps,
      },
      {
        headerName: "Max Days in Report",
        field: "cust_max_days_in_report",
        editable: true,
      },
      {
        headerName: "Max Concurrent Reports",
        field: "cust_max_concurrent_reports",
        editable: true,
        minWidth: 110,
        maxWidth: 110,
      },
      {
        headerName: "Database Server",
        field: "cust_database_server",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "GUID",
        field: "cust_guid",
      },
      {
        headerName: "Created Timestamp",
        field: "cust_created_timestamp",
        valueFormatter: LocalTimestampFormatter,
      },
      {
        headerName: "Modified Timestamp",
        field: "cust_modified_timestamp",
        valueFormatter: LocalTimestampFormatter,
      },
      {
        headerName: "Disabled Timestamp",
        field: "cust_disabled_timestamp",
        valueFormatter: LocalTimestampFormatter,
      },
    ] as Array<ColDef>;
  }, [isOpsCenterWriterMember, isDeletingAPIM, isCustomerSaving]);

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  //auto-size all columns to fit their content
  const autoSizeColumns = useCallback(() => {
    if (gridRef.current?.api && true === shouldResize.current) {
      shouldResize.current = false;
      setTimeout(() => {
        //because this is timing based,
        //there are sometimes errors because the grid is already doing something else
        //when this is called
        //that siutation should not be fatal
        try {
          gridRef.current?.api.autoSizeAllColumns(true);
        } catch (error) {
          console.error(`Error sizing all EventHubPartitionGrid columns: ${error}`);
        }
      }, 500);
    }
  }, []);

  //whenever row editing starts we need to capture the current data
  //so we can put it back if the save of the edited data is canceled
  //after the grid already has that new data
  const onRowEditingStarted = useCallback((event: RowEditingStartedEvent) => {
    rowRef.current = event.node;
    rowDataRef.current = { ...event.data };
  }, []);

  //the handler for clicking Update or otherwise ending row-level editing in the grid
  const onRowValueChanged = useCallback((event: RowValueChangedEvent) => {
    const data = event.data as CustomerModel;

    //ag-grid calls this method if the user clicks away to cancel editing
    //but in that case we want to restore the state as if they had clicked the cancel button
    if (false === isUpdateRef.current && rowDataRef.current) {
      event.node.setData(rowDataRef.current);
      rowRef.current = null;
      rowDataRef.current = null;
    }
    //ag-grid calls this method even if the data has not actually changed
    //we want to do nothing if nothing has changed
    else if (true === isUpdateRef.current && (null === rowDataRef.current || !isEqual(data, rowDataRef.current))) {
      //reset the update flag since we have consumed it
      isUpdateRef.current = false;

      //the editable number fields may be empty strings
      //if so, make them null instead
      data.cust_max_concurrent_reports = Number.isNaN(
        Number.parseInt(data.cust_max_concurrent_reports?.toString() ?? "")
      )
        ? undefined
        : data.cust_max_concurrent_reports;
      data.cust_max_days_in_report = Number.isNaN(Number.parseInt(data.cust_max_days_in_report?.toString() ?? ""))
        ? undefined
        : data.cust_max_days_in_report;

      //confirm rejects the promise if the user does not confirm
      const confirmDescription =
        null !== rowDataRef.current && rowDataRef.current.customerName !== data.customerName
          ? `Are you sure you want to save CID: ${data.customerID}, changing the name from ${rowDataRef.current.customerName} to ${data.customerName}, enabled: ${data.customerEnabled}, allowSSO: ${data.cust_allow_sso}, hasScheduledRules: ${data.cust_has_scheduled_rules}, hasCalculatedTags: ${data.cust_has_calculated_tags}, maxReportDays: ${data.cust_max_days_in_report}, maxConcurrentReports: ${data.cust_max_concurrent_reports} ?`
          : `Are you sure you want to save ${data.customerName} (CID: ${data.customerID}), enabled: ${data.customerEnabled}, allowSSO: ${data.cust_allow_sso}, hasScheduledRules: ${data.cust_has_scheduled_rules}, hasCalculatedTags: ${data.cust_has_calculated_tags}, maxReportDays: ${data.cust_max_days_in_report}, maxConcurrentReports: ${data.cust_max_concurrent_reports} ?`;
      confirm({
        title: "Confirm Saving Customer",
        description: confirmDescription,
        allowClose: false,
      })
        .then(() => {
          console.log(
            `Saving customer data: ${data.customerName} (CID: ${data.customerID}), enabled: ${data.customerEnabled}, allowSSO: ${data.cust_allow_sso}, hasScheduledRules: ${data.cust_has_scheduled_rules}, hasCalculatedTags: ${data.cust_has_calculated_tags}, maxReportDays: ${data.cust_max_days_in_report}, maxConcurrentReports: ${data.cust_max_concurrent_reports}`
          );
          displayToast({ message: "Saving customer...", severity: "info", duration: null });
          event.api.refreshCells({ force: true, rowNodes: [event.node] });
          updateCustomer(data, {
            onSuccess: () =>
              displayToast({
                message: `Saved customer ${data.customerName} (${data.customerID}) successfully`,
                severity: "success",
              }),
            onError: (error) =>
              displayToast({
                message: `Error saving customer ${data.customerName} (${data.customerID}): ${error}`,
                severity: "error",
                duration: 15000,
              }),
          });
        })
        .catch(() => {
          console.log("Customer save canceled!");
          if (rowDataRef.current) {
            event.node.setData(rowDataRef.current);
            rowDataRef.current = null;
          }
        });
    } else {
      console.log("Customer data has not actually changed!");
    }
  }, []);

  return (
    <>
      <ContentView>
        <ContentHeader title={"Customers"} />

        {/* It appears that in order to make the grid fit into a flex scheme 
      it needs to be contained by a div with a hard size (calculated by flex, in this case) that it can fill completely */}
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            "& .ag-theme-alpine .ag-cell-value": {
              lineHeight: "20px !important",
              wordBreak: "normal",
              paddingTop: "5px",
              paddingBottom: "5px",
            },
            "& .ag-theme-alpine-dark .ag-cell-value": {
              lineHeight: "20px !important",
              wordBreak: "normal",
              paddingTop: "5px",
              paddingBottom: "5px",
            },
          }}
        >
          <div className={gridTheme} style={{ height: "100%", width: "100%" }}>
            <AutoWrapHeaderGrid
              ref={gridRef}
              defaultColDef={defaultColDef}
              rowData={customers}
              columnDefs={columnDefs}
              ensureDomOrder={true}
              enableCellTextSelection={true}
              //onFirstDataRendered={autoSizeColumns}
              //onGridReady={autoSizeColumns}
              onModelUpdated={autoSizeColumns}
              editType={"fullRow"}
              onRowEditingStarted={onRowEditingStarted}
              onRowValueChanged={onRowValueChanged}
              suppressClickEdit={true}
              suppressCellFocus={true}
              alwaysMultiSort={true}
            />
          </div>
        </Box>
      </ContentView>
    </>
  );
}
