import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { DeviceCopyJob, DeviceCopyJobStatusEnum, GetFurthestStatusString } from "../../queries/copy-device/Models";
import { useDeviceCopyJobState } from "../../queries/copy-device/UseDeviceCopyJobState";
import { AgGridReact } from "ag-grid-react";
import { useAppSettings } from "../../contexts/app-context/AppContext";
import { ColDef, ValueFormatterParams, TextMatcherParams } from "ag-grid-community";

export type CopyJobMonitorProps = {
  dialogOpen: boolean;
  jobID: number;
  onClose: () => void;
};

export default function CopyJobMonitor({ dialogOpen, jobID, onClose }: CopyJobMonitorProps) {
  const gridRef = useRef<AgGridReact>(null);
  const [appSettings] = useAppSettings();
  const gridTheme = appSettings.themeSet[appSettings.themeSet.mode].grid;
  const [autoRefreshSeconds, setAutoRefreshSeconds] = useState(10);
  const [job, setJob] = useState<DeviceCopyJob | null>(null);
  const { data: newJob, isSuccess: isJobSuccess } = useDeviceCopyJobState({
    enabled: dialogOpen && jobID > 0,
    jobID: jobID,
    autoRefreshSeconds: autoRefreshSeconds,
  });

  const setJobWithDescription = useCallback((job: DeviceCopyJob) => {
    const description =
      job.objects == null || job.objects.length === 0
        ? "Copy devices"
        : job.objects.length === 1
        ? "Copy 1 device"
        : `Copy ${job.objects.length} devices`;
    job.jobDescription = `${description} from ${job.srcCustName} (${job.srcCustID}) to ${job.dstCustName} (${job.dstCustID}) for ${job.username}`;
    setJob(job);
  }, []);

  const autoSizeColumns = useCallback(() => {
    gridRef.current?.api.autoSizeAllColumns(false);
  }, []);

  //if we get a new device copy job, put it in our state
  //also calculate our update frequency
  useEffect(() => {
    if (newJob) {
      setJobWithDescription(newJob);
      setAutoRefreshSeconds(newJob.jobStatus === DeviceCopyJobStatusEnum.Completed ? 60 : 10);
    }
  }, [isJobSuccess, newJob]);

  const columnDefs = useMemo<Array<ColDef>>(() => {
    return [
      {
        headerName: "Object Name",
        field: "objectName",
        sort: "asc",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Object Type",
        field: "objectType",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith", "equals"],
          buttons: ["reset", "apply"],
          debounceMs: 300,
          maxNumConditions: 6,
        },
      },
      {
        headerName: "Object ID",
        field: "objectID",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Copy Status",
        field: "objectCopyStatus",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          debounceMs: 300,
          suppressAndOrCondition: true,
          textMatcher: ({ value, filterText }: TextMatcherParams) => {
            if (filterText == null) {
              return false;
            }
            const valueString = GetFurthestStatusString(value).toLowerCase();
            return valueString.indexOf(filterText) >= 0;
          },
        },
        valueFormatter: (p: ValueFormatterParams) => GetFurthestStatusString(p.value),
      },
    ];
  }, []);

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  return (
    <Dialog
      open={dialogOpen}
      onClose={onClose}
      scroll="paper"
      fullWidth={true}
      maxWidth="lg"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{ height: "100%", "& .MuiPaper-root": { height: "100%" } }}
    >
      <DialogTitle id="scroll-dialog-title">{`Monitoring job ${job?.jobID}: ${job?.jobDescription}`}</DialogTitle>
      <DialogContent dividers={true}>
        <Stack direction="column" height="100%">
          <Typography variant="h5" noWrap component="div">
            {`Job Status: ${DeviceCopyJobStatusEnum[job?.jobStatus ?? 0]}`}
          </Typography>

          {/* It appears that in order to make the grid fit into a flex scheme 
      it needs to be contained by a div with a hard size (calculated by flex, in this case) that it can fill completely */}
          <Box
            sx={{
              display: "flex",
              flex: "1 1 auto",
              "& .ag-theme-alpine .ag-cell-value": {
                lineHeight: "20px !important",
                wordBreak: "normal",
                paddingTop: "5px",
                paddingBottom: "5px",
              },
              "& .ag-theme-alpine-dark .ag-cell-value": {
                lineHeight: "20px !important",
                wordBreak: "normal",
                paddingTop: "5px",
                paddingBottom: "5px",
              },
            }}
          >
            <div className={gridTheme} style={{ height: "100%", width: "100%" }}>
              <AgGridReact
                ref={gridRef}
                defaultColDef={defaultColDef}
                rowData={job?.objects}
                columnDefs={columnDefs}
                ensureDomOrder={true}
                alwaysMultiSort={true}
                enableCellTextSelection={true}
                onFirstDataRendered={autoSizeColumns}
                onGridReady={autoSizeColumns}
                onModelUpdated={autoSizeColumns}
              />
            </div>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
