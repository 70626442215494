import React, { useCallback, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText } from "@mui/material";
import { useForm } from "react-hook-form";
import { FormInputText } from "../../components/form-components/FormInputText";
import { ISystemJob } from "../../queries/system-jobs/Models";
import { useRunSystemJob } from "../../queries/system-jobs/UseRunSystemJob";
import StringFormat from "../../utilities/StringFormat";
import { useToastNotification } from "../../contexts/toast-notification/ToastNotificationContext";

export type RunJobProperties = {
  open: boolean;
  onClose: () => void;
  job: ISystemJob;
};

type RunJobFormInput = {
  param0: string;
  param1: string;
  param2: string;
  param3: string;
  param4: string;
  param5: string;
  param6: string;
  param7: string;
};

export default function RunJob({ open, onClose, job }: RunJobProperties) {
  const { control, handleSubmit, reset } = useForm<RunJobFormInput>();
  const { displayToast } = useToastNotification();
  const { mutate: runJob } = useRunSystemJob();

  //whenever the user property changes, reset the values of our fields
  useEffect(() => {
    reset();
  }, [job]);

  const onClick = useCallback(
    (data: RunJobFormInput | null) => {
      let url = job.runJobEndpoint;
      if (data) {
        url = StringFormat(
          url,
          data.param0,
          data.param1,
          data.param2,
          data.param3,
          data.param4,
          data.param5,
          data.param6,
          data.param7
        );
      }

      runJob(
        { url: url },
        {
          onSuccess: () => displayToast({ message: `Started job ${job.name}`, severity: "success" }),
          onError: (error) =>
            displayToast({
              message: `Error running ${job.name} for job endpoint ${url}: ${error}`,
              severity: "error",
              duration: 15000,
            }),
        }
      );
      onClose();
    },
    [job]
  );

  return (
    <Dialog open={open} onClose={onClose} scroll="paper" fullWidth={true} maxWidth="sm">
      <DialogTitle>Run Job {job.name}</DialogTitle>
      <DialogContent dividers={true}>
        {job?.parameters?.length > 0 ? (
          job.parameters.map((p, inx) => (
            <FormInputText
              key={inx}
              name={`param${inx}`}
              label={p}
              control={control}
              rules={{
                required: { value: true, message: `${p} is required` },
              }}
            />
          ))
        ) : (
          <DialogContentText>No parameters for {job.name}</DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        {job?.parameters?.length > 0 ? (
          <Button color="secondary" onClick={handleSubmit(onClick)}>
            Run
          </Button>
        ) : (
          <Button color="secondary" onClick={() => onClick(null)}>
            Run
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
