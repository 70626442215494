import { useMutation } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { NonPolledDataItem } from "./Models";

export function useCopyBlob() {
  const { axios: ax } = useAxios();

  return useMutation<string, Error, NonPolledDataItem>(async (item) => {
    const postObject = {
      container: item.container,
      directory: item.directory,
      file: item.name,
    };

    const { data } = await ax.post<string>("nonpolleddataops/file/copy", postObject);

    return data;
  });
}
