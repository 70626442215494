import React from "react";
import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { FormInputProps } from "./FormInputProps";

export const FormInputText = ({ name, label, helperText, control, rules, sx, inputType }: FormInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          helperText={
            error && error.message && error.message.length > 0 ? error.message : helperText ? helperText : " "
          }
          size="small"
          error={!!error}
          onChange={onChange}
          value={value}
          fullWidth
          label={label}
          variant="filled"
          sx={sx}
          type={inputType}
        />
      )}
    />
  );
};
