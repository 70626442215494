import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { useAppSettings } from "../../../contexts/app-context/AppContext";
import { Box } from "@mui/system";
import { ConfigureJobStepProps } from "./ConfigureCopyJob";
import { ColDef, ValueFormatterParams } from "ag-grid-community";
import { TatGroupActionEnum, TatGroupInfoItem } from "../../../queries/copy-device/Models";
import { useDeviceCopyTatGroupInfo } from "../../../queries/copy-device/UseDeviceCopyTatGroupInfo";
import { faFileImport } from "@elynx/pro-regular-svg-icons";
import CheckboxCellRenderer, {
  CheckboxRendererProps,
} from "../../../components/ag-grid-extensions/renderers/CheckboxCellRenderer";
import { CopyDeviceJobActionTypeEnum } from "../CopyDeviceJobActions";
import SideSheet from "../../../components/side-sheet/side-sheet/SideSheet";
import CopyOptions from "./CopyOptions";
import { FormControlLabel, Typography } from "@mui/material";
import FontAwesomeIconButton from "../../../components/font-awesome-icon-button/FontAwesomeIconButton";
import { AutocompleteOption } from "../../../components/form-components/FormInputAutocomplete";
import ActionButtonCellRenderer, {
  ActionButtonRendererProps,
} from "../../../components/ag-grid-extensions/renderers/ActionButtonCellRenderer";
import SelectTatGroup from "./SelectTatGroup";

export default function MapTatGroupsStep({
  dialogOpen,
  job,
  dispatchJob,
  setNextButtonDisabled,
}: ConfigureJobStepProps) {
  const gridRef = useRef<AgGridReact>(null);
  const [appSettings] = useAppSettings();
  const gridTheme = appSettings.themeSet[appSettings.themeSet.mode].grid;
  const [destinationTatGroups, setDestinationTatGroups] = useState<Array<AutocompleteOption>>([]);
  const [sourceTatGroups, setSourceTatGroups] = useState<Array<TatGroupInfoItem>>([]);
  const currentTatGroupInfoRef = useRef<TatGroupInfoItem | null>(null);
  const [selectTatGroupOpen, setSelectTatGroupOpen] = useState(false);
  const { data: newTatGroupInfo, isSuccess } = useDeviceCopyTatGroupInfo({
    enabled: dialogOpen && (job?.objects?.length ?? 0) > 0,
    jobID: job?.jobID ?? 0,
  });

  //if we have just been opened, clear our data
  useEffect(() => {
    setDestinationTatGroups([]);
    setSourceTatGroups([]);
  }, [dialogOpen]);

  //store source and destination TAT Groups if they change
  useEffect(() => {
    if (newTatGroupInfo?.tg_info_list) {
      setSourceTatGroups(newTatGroupInfo.tg_info_list);
    }
  }, [dialogOpen, isSuccess, newTatGroupInfo?.tg_info_list]);

  useEffect(() => {
    if (newTatGroupInfo?.dst_tg_list) {
      const noTatGroup = { id: -1, label: "" } as AutocompleteOption;
      const tatGroupList = newTatGroupInfo.dst_tg_list.map((t) => {
        return { id: t.tg_id, label: t.tg_name } as AutocompleteOption;
      });
      setDestinationTatGroups([noTatGroup, ...tatGroupList]);
    }
  }, [dialogOpen, isSuccess, newTatGroupInfo?.dst_tg_list]);

  useEffect(() => {
    //if this is our first visit to this step,
    //i.e. dialog is open, job.tatGroupMappings is empty)
    //and we have sourceTatGroups,
    //setup the initial mapping in the job
    if (dialogOpen && sourceTatGroups.length > 0 && (job?.tatGroupMappings?.length ?? 0) === 0) {
      //then create a new mapping to be saved into the job
      const newTatGroupMapping = sourceTatGroups.map((t) => {
        return {
          srcTgID: t.src_tg_id,
          srcIsTatGroup: t.src_isTatGroup,
          dstTgID: t.dst_tg_id,
          tgAction: t.tg_action,
        };
      });
      dispatchJob({
        type: CopyDeviceJobActionTypeEnum.SetTatGroupMap,
        tgMap: newTatGroupMapping,
      });
    }
  }, [dialogOpen, sourceTatGroups]);

  //enable the Next button if we have mapped all TAT/Tag Groups
  useEffect(() => {
    setNextButtonDisabled(
      (job?.tatGroupMappings?.length ?? 0) == 0 ||
        job.tatGroupMappings.some((u) => TatGroupActionEnum.DoNotCopy === u.tgAction)
    );
  }, [job?.tatGroupMappings]);

  const autoSizeColumns = useCallback(() => {
    gridRef.current?.api.autoSizeAllColumns();
  }, []);

  const onCheckChanged = useCallback(
    (data: TatGroupInfoItem, checked: boolean) => {
      //first create a new set of source Lookup Lists so our grid can be updated
      const newSourceTatGroups = sourceTatGroups.map((t) => {
        //if the array item matches the one being changed, handle that
        if (t.src_tg_id === data.src_tg_id && t.src_isTatGroup === data.src_isTatGroup) {
          return {
            ...t,
            tg_action: checked
              ? null == t.dst_tg_id
                ? TatGroupActionEnum.CreateAndCopy
                : TatGroupActionEnum.Copy
              : TatGroupActionEnum.DoNotCopy,
          };
        }
        //else just return the array item
        else {
          return t;
        }
      });

      setSourceTatGroups(newSourceTatGroups);

      //then create a new mapping to be saved into the job
      const newTatGroupMapping = newSourceTatGroups.map((t) => {
        return {
          srcTgID: t.src_tg_id,
          srcIsTatGroup: t.src_isTatGroup,
          dstTgID: t.dst_tg_id,
          tgAction: t.tg_action,
        };
      });
      dispatchJob({
        type: CopyDeviceJobActionTypeEnum.SetTatGroupMap,
        tgMap: newTatGroupMapping,
      });
    },
    [sourceTatGroups]
  );

  const onSelectTatGroupClick = useCallback((tatGroupInfo: TatGroupInfoItem) => {
    currentTatGroupInfoRef.current = tatGroupInfo;
    setSelectTatGroupOpen(true);
  }, []);

  //setup all the columns
  const columnDefs = useMemo<Array<ColDef<TatGroupInfoItem>>>(() => {
    const columns = [
      {
        headerName: "Src Tag Group ID",
        valueGetter: (params) => {
          return params.data ? (params.data.src_isTatGroup ? undefined : params.data.src_tg_id) : undefined;
        },
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Source Tag Group",
        valueGetter: (params) => {
          return params.data ? (params.data.src_isTatGroup ? undefined : params.data.src_tg_name) : undefined;
        },
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        sort: "asc",
        sortIndex: 1,
        comparator: (valueA, valueB) => {
          //we want blanks to sort last
          if (valueA && valueB) {
            if (valueA == valueB) {
              return 0;
            }
            return valueA > valueB ? 1 : -1;
          } else if (valueA) {
            return -1;
          } else if (valueB) {
            return 1;
          }
          return 0;
        },
      },
      {
        headerName: "Src TAT Group ID",
        valueGetter: (params) => {
          return params.data ? (params.data.src_isTatGroup ? params.data.src_tg_id : undefined) : undefined;
        },
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Source TAT Group",
        valueGetter: (params) => {
          return params.data ? (params.data.src_isTatGroup ? params.data.src_tg_name : undefined) : undefined;
        },
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        sort: "asc",
        sortIndex: 2,
        comparator: (valueA, valueB) => {
          //we want blanks to sort last
          if (valueA && valueB) {
            if (valueA == valueB) {
              return 0;
            }
            return valueA > valueB ? 1 : -1;
          } else if (valueA) {
            return -1;
          } else if (valueB) {
            return 1;
          }
          return 0;
        },
      },
      {
        headerName: "Destination TAT Group",
        field: "dst_tg_name",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        valueFormatter: ({ data }: ValueFormatterParams<TatGroupInfoItem, string>) => {
          return null !== data && null !== data?.dst_tg_id ? `${data?.dst_tg_name} [${data?.dst_tg_id}]` : "";
        },
      },
      {
        colId: "selectTatGroup",
        headerName: "Select TAT Group",
        minWidth: 170,
        cellRenderer: ActionButtonCellRenderer,
        cellRendererParams: {
          disabled: false,
          buttonText: "Select TAT Group",
          shouldDisplay: true,
          onClick: onSelectTatGroupClick,
        } as ActionButtonRendererProps,
      },
      {
        headerName: "Copy TatGroup?",
        field: "tg_action",
        minWidth: 200,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        cellRenderer: CheckboxCellRenderer,
        cellRendererParams: {
          disabled: false,
          onChecked: onCheckChanged,
          label: (data: TatGroupInfoItem) => {
            return data
              ? TatGroupActionEnum.Copy === data.tg_action
                ? "Copy"
                : TatGroupActionEnum.CreateAndCopy === data.tg_action
                ? "Create and Copy"
                : ""
              : "";
          },
        } as CheckboxRendererProps,
      },
    ] as Array<ColDef<TatGroupInfoItem>>;

    return columns;
  }, [onCheckChanged]);

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  const createMissingTatGroups = useCallback(() => {
    //first create a new set of source TAT Groups so our grid can be updated
    const newSourceTatGroups = sourceTatGroups.map((t) => {
      const newAction = null == t.dst_tg_id ? TatGroupActionEnum.CreateAndCopy : t.tg_action;
      return {
        ...t,
        tg_action: newAction,
      };
    });

    setSourceTatGroups(newSourceTatGroups);

    //then create a new mapping to be saved into the job
    const newTatGroupMapping = newSourceTatGroups.map((t) => {
      return {
        srcTgID: t.src_tg_id,
        srcIsTatGroup: t.src_isTatGroup,
        dstTgID: t.dst_tg_id,
        tgAction: t.tg_action,
      };
    });
    dispatchJob({
      type: CopyDeviceJobActionTypeEnum.SetTatGroupMap,
      tgMap: newTatGroupMapping,
    });
  }, [sourceTatGroups]);

  //called when user selects a TAT Group in the popup dialog
  const onTatGroupSelected = useCallback(
    (tatGroupInfo: TatGroupInfoItem) => {
      //first create a new set of source UoMs so our grid can be updated
      const newSourceTatGroups = sourceTatGroups.map((t) => {
        //if the array item matches the one being changed, handle that
        if (t.src_tg_id === tatGroupInfo.src_tg_id && t.src_isTatGroup === tatGroupInfo.src_isTatGroup) {
          return {
            ...t,
            dst_tg_id: tatGroupInfo.dst_tg_id,
            dst_tg_name: tatGroupInfo.dst_tg_name,
            tg_action: tatGroupInfo.tg_action,
          };
        }
        //else just return the array item
        else {
          return t;
        }
      });

      setSourceTatGroups(newSourceTatGroups);

      //then create a new mapping to be saved into the job
      const newTatGroupMapping = newSourceTatGroups.map((t) => {
        return {
          srcTgID: t.src_tg_id,
          srcIsTatGroup: t.src_isTatGroup,
          dstTgID: t.dst_tg_id,
          tgAction: t.tg_action,
        };
      });
      dispatchJob({
        type: CopyDeviceJobActionTypeEnum.SetTatGroupMap,
        tgMap: newTatGroupMapping,
      });
    },
    [sourceTatGroups]
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          "& .ag-theme-alpine .ag-cell-value": {
            lineHeight: "20px !important",
            wordBreak: "normal",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
          "& .ag-theme-alpine-dark .ag-cell-value": {
            lineHeight: "20px !important",
            wordBreak: "normal",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
        }}
      >
        <div className={gridTheme} style={{ height: "100%", width: "100%" }}>
          <AgGridReact
            ref={gridRef}
            defaultColDef={defaultColDef}
            rowData={sourceTatGroups}
            columnDefs={columnDefs}
            ensureDomOrder={true}
            enableCellTextSelection={true}
            onFirstDataRendered={autoSizeColumns}
            suppressClickEdit={true}
            suppressCellFocus={true}
            alwaysMultiSort={true}
            suppressScrollOnNewData={true}
          />
        </div>
      </Box>
      <SideSheet title="Options" open={true}>
        <CopyOptions job={job} dispatchJob={dispatchJob} />
        <>
          <Typography variant="h6" sx={{ marginLeft: "-11px", marginTop: "10px" }}>
            {"Actions"}
          </Typography>
          <FormControlLabel
            control={<FontAwesomeIconButton icon={faFileImport} iconSize={24} />}
            onClick={() => createMissingTatGroups()}
            label="Create Missing TAT Groups"
            labelPlacement="end"
          />
        </>
      </SideSheet>

      {/* Select TAT Group */}
      {currentTatGroupInfoRef.current && (
        <SelectTatGroup
          tatGroupInfo={currentTatGroupInfoRef.current}
          tatGroupList={destinationTatGroups}
          open={selectTatGroupOpen}
          onClose={() => setSelectTatGroupOpen(false)}
          selectTatGroup={onTatGroupSelected}
        />
      )}
    </>
  );
}
