import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, CircularProgress, MenuItem, Stack, TextField, useTheme } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { DatePicker } from "@mui/x-date-pickers";
import { AgGridReact } from "ag-grid-react";
import { useAppSettings } from "../../contexts/app-context/AppContext";
import {
  ReportTypeEnum,
  MessagingReportData,
  MessagingReportQueryProperties,
} from "../../queries/messaging-reports/Models";
import { useQueryClient } from "react-query";
import { keys } from "../../queries/messaging-reports/Keys";
import isEqual from "react-fast-compare";
import { useMessagingReportData } from "../../queries/messaging-reports/UseMessagingReportData";
import { ColDef } from "ag-grid-community";
import ContentView from "../../components/content-view/ContentView";
import ContentHeader from "../../components/content-header/ContentHeader";
import { LocalTimestampFormatter } from "../../components/ag-grid-extensions/utilities/LocalTimestampFormatter";
import { FormatTimestampLocal } from "../../utilities/FormatTimestampLocal";
import { FormatDateYMD } from "../../utilities/FormatDateYMD";

const defaultMessagingReportQueryProperties = {
  reportType: ReportTypeEnum.TextingErrorsLastStatus,
  timeframe: 90,
  startDate: null,
  endDate: null,
  includeElynx: false,
} as MessagingReportQueryProperties;

export default function MessagingReports() {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const gridRef = useRef<AgGridReact>(null);
  const [appSettings] = useAppSettings();
  const gridTheme = appSettings.themeSet[appSettings.themeSet.mode].grid;
  const [retrieveData, setRetrieveData] = useState(false);
  const [messagingReportQueryProperties, setMessagingReportQueryProperties] = useState(
    defaultMessagingReportQueryProperties
  );
  const [runningQueryProperties, setRunningQueryProperties] = useState(defaultMessagingReportQueryProperties);
  const [messagingReportData, setMessagingReportData] = useState<Array<MessagingReportData>>([]);

  //use the messaging report query to retrieve data for the currently running set of properties
  const {
    data: newUsageData,
    isFetching,
    isSuccess,
    refetch,
  } = useMessagingReportData({
    enabled: retrieveData,
    messagingReportQueryProperties: runningQueryProperties,
  });

  //if the user changes their query parameters
  //wipe out current data to avoid confusion
  //and cancel a running query, if there is one
  useEffect(() => {
    setMessagingReportData([]);
    if (isFetching) {
      queryClient.cancelQueries(keys.queryKey(runningQueryProperties));
      setRetrieveData(false);
    }
  }, [messagingReportQueryProperties]);

  //turn off the query if it succeeded
  //and save the new data
  useEffect(() => {
    if (isSuccess) {
      setRetrieveData(false);
    }
    if (newUsageData) {
      setMessagingReportData(newUsageData);
    }
  }, [isSuccess, newUsageData]);

  //we only want to fetch data when the refresh button is clicked,
  //as opposed to automatically every time a user control is changed,
  //so handle both initiating and canceling the query here
  const handleRefreshButtonClick = () => {
    //if we are currently fetching data, cancel the current query
    if (isFetching) {
      queryClient.cancelQueries(keys.queryKey(runningQueryProperties));
      setRetrieveData(false);
    }
    //if not, either refetch the current query or start a new one
    else {
      //if the query properties have changed,
      //save them and start a new query
      if (false === isEqual(runningQueryProperties, messagingReportQueryProperties)) {
        setRunningQueryProperties(messagingReportQueryProperties);
        setRetrieveData(true);
      }
      //if not, just refetch
      else {
        refetch();
      }
    }
  };

  const columnDefs = useMemo<Array<ColDef>>(() => {
    return [
      {
        headerName: "Failed Count",
        field: "failedCount",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["lessThan", "lessThanOrEqual", "greaterThan", "greaterThanOrEqual"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        minWidth: 130,
        maxWidth: 140,
      },
      {
        headerName: "Completed Count",
        field: "completedCount",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["lessThan", "lessThanOrEqual", "greaterThan", "greaterThanOrEqual"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        minWidth: 150,
        maxWidth: 170,
      },
      {
        headerName: "Customer ID",
        field: "customerID",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        minWidth: 120,
        maxWidth: 170,
      },
      {
        headerName: "Customer Name",
        field: "customerName",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Recipient",
        field: "recipient",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Last Success",
        field: "lastSuccess",
        valueFormatter: LocalTimestampFormatter,
      },
      {
        headerName: "Last Failure",
        field: "lastFailure",
        valueFormatter: LocalTimestampFormatter,
      },
      {
        headerName: "Last Result",
        field: "lastResult",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        minWidth: 110,
        maxWidth: 130,
      },
      {
        headerName: "User Login",
        field: "userLogin",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "User Contact Email",
        field: "usr_contact_email",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "User First Name",
        field: "userFirstName",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "User Last Name",
        field: "userLastName",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
    ];
  }, [messagingReportQueryProperties.reportType]);

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  const autoSizeColumns = useCallback(() => {
    gridRef.current?.api.autoSizeAllColumns();
  }, []);

  const getExportFileName = useCallback(() => {
    const dataType =
      runningQueryProperties.reportType == ReportTypeEnum.TextingErrorsLastStatus ? "TextingErrorsLastStatus" : "";
    const dateSpan =
      runningQueryProperties.timeframe == 0
        ? `${runningQueryProperties.startDate ? FormatDateYMD(runningQueryProperties.startDate) : ""}_${
            runningQueryProperties.endDate ? FormatDateYMD(runningQueryProperties.endDate) : ""
          }`
        : `Past_${runningQueryProperties.timeframe}_days`;

    return `${dataType}_SystemUsage_${dateSpan}`;
  }, [runningQueryProperties]);

  const handleExportCSVClick = useCallback(() => {
    const defaultFileName = `${getExportFileName()}.csv`;
    gridRef.current?.api.exportDataAsCsv({
      fileName: defaultFileName,
      //we need to convert datetime strings for export like we do for display
      processCellCallback: (params) => {
        const colId = params.column.getColId();
        if (params.value) {
          return colId == "lastSuccess" || colId == "lastFailure"
            ? FormatTimestampLocal(params.value)
            : params.value?.toString();
        }
      },
    });
  }, [runningQueryProperties]);

  return (
    <ContentView>
      <ContentHeader title={"Messaging Reports"} />
      <Stack
        direction="row"
        spacing={1}
        sx={{
          backgroundColor: theme.palette.neutral.lowContrast,
          flexWrap: "wrap",
          padding: "6px",
          rowGap: "6px",
          alignItems: "center",
        }}
      >
        <TextField
          size="small"
          select
          variant="filled"
          label="Report Type"
          value={messagingReportQueryProperties.reportType}
          onChange={(e) =>
            setMessagingReportQueryProperties({ ...messagingReportQueryProperties, reportType: +e.target.value })
          }
          sx={{ width: "30ch" }}
        >
          <MenuItem value={ReportTypeEnum.TextingErrorsLastStatus}>Texting Errors Last Status</MenuItem>
        </TextField>
        <TextField
          size="small"
          select
          variant="filled"
          label="Timeframe"
          value={messagingReportQueryProperties.timeframe}
          onChange={(e) =>
            setMessagingReportQueryProperties({ ...messagingReportQueryProperties, timeframe: +e.target.value })
          }
          sx={{ width: "16ch" }}
        >
          <MenuItem value={0}>Custom</MenuItem>
          <MenuItem value={7}>Past 7 days</MenuItem>
          <MenuItem value={30}>Past 30 days</MenuItem>
          <MenuItem value={90}>Past 90 days</MenuItem>
        </TextField>
        <DatePicker
          slotProps={{ textField: { variant: "filled", size: "small", sx: { width: "26ch" } } }}
          label="Start Date"
          value={messagingReportQueryProperties.startDate}
          disabled={0 !== messagingReportQueryProperties.timeframe}
          onChange={(newValue: Date | null) => {
            if (newValue) {
              setMessagingReportQueryProperties({ ...messagingReportQueryProperties, startDate: newValue });
            }
          }}
        />
        <DatePicker
          disabled={0 !== messagingReportQueryProperties.timeframe}
          slotProps={{ textField: { variant: "filled", size: "small", sx: { width: "26ch" } } }}
          label="End Date"
          value={messagingReportQueryProperties.endDate}
          onChange={(newValue: Date | null) => {
            if (newValue) {
              setMessagingReportQueryProperties({ ...messagingReportQueryProperties, endDate: newValue });
            }
          }}
        />
        <Box
          sx={{
            "& > button": { m: 1 },
            display: "flex",
            width: "20ch",
            marginLeft: "24px",
          }}
        >
          <Button
            onClick={handleExportCSVClick}
            variant="contained"
            disabled={!(messagingReportData && messagingReportData.length > 0)}
          >
            Export CSV
          </Button>
        </Box>
        <Box
          sx={{
            "& > button": { m: 1 },
            display: "flex",
            width: "14ch",
            marginLeft: "auto !important",
            marginRight: "1em !important",
          }}
        >
          <Button
            onClick={() => handleRefreshButtonClick()}
            endIcon={isFetching ? <CircularProgress color="inherit" size="1em" /> : <RefreshIcon />}
            variant="contained"
          >
            Refresh
          </Button>
        </Box>
      </Stack>

      {/* It appears that in order to make the grid fit into a flex scheme 
      it needs to be contained by a div with a hard size (calculated by flex, in this case) that it can fill completely */}
      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          "& .ag-theme-alpine .ag-cell-value": {
            lineHeight: "20px !important",
            wordBreak: "normal",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
          "& .ag-theme-alpine-dark .ag-cell-value": {
            lineHeight: "20px !important",
            wordBreak: "normal",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
        }}
      >
        <div className={gridTheme} style={{ height: "100%", width: "100%" }}>
          <AgGridReact
            ref={gridRef}
            defaultColDef={defaultColDef}
            rowData={messagingReportData}
            columnDefs={columnDefs}
            ensureDomOrder={true}
            enableCellTextSelection={true}
            onFirstDataRendered={autoSizeColumns}
            // onGridReady={autoSizeColumns}
            // onModelUpdated={autoSizeColumns}
            suppressClickEdit={true}
            suppressCellFocus={true}
            alwaysMultiSort={true}
            columnMenu="legacy"
          />
        </div>
      </Box>
    </ContentView>
  );
}
