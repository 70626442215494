import { useMutation } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { UseSendTextMsgParameters } from "./Models";

export function useSendTextMsg() {
  const { axios: ax } = useAxios();

  return useMutation<void, Error, UseSendTextMsgParameters>(async (textMsg) => {
    await ax.post("outboundmsg/text", textMsg);
  });
}
