import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Alert } from "@mui/material";
import { AlertTemplateProps } from "react-alert";

export interface AlertProps extends AlertTemplateProps {
  title?: string;
  closeText?: string;
}

const AlertTemplate = (props: AlertProps) => {
  const { title, message, close, closeText, options } = props;
  return (
    <>
      <Dialog
        open={true}
        onClose={close}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        <DialogContent>
          <Alert severity={options.type} variant="outlined" sx={{ width: "100%" }}>
            {message}
          </Alert>
        </DialogContent>
        <DialogActions>
          {/* {options.actions &&
          options.actions.map((action, index) => (
            <Button
              onClick={(event) => {
                action.onClick(event);
                close(event);
              }}
              color="primary"
              key={index}
            >
              {action.copy}
            </Button>
          ))} */}
          <Button onClick={close} color={options?.type ?? "secondary"}>
            {closeText || "Okay"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AlertTemplate;
