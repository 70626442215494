import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { DeviceCopyJobListItem, DeviceCopyJobListQueryProperties } from "./Models";
import { keys } from "./Keys";
import { useQuery } from "react-query";

export type UseDeviceCopyJobListParameters = {
  enabled: boolean;
  deviceCopyJobListQueryProperties: DeviceCopyJobListQueryProperties;
};

export function makeQueryKey(deviceCopyJobListQueryProperties: DeviceCopyJobListQueryProperties) {
  return [...keys.list(), deviceCopyJobListQueryProperties];
}

export function useDeviceCopyJobList({ enabled, deviceCopyJobListQueryProperties }: UseDeviceCopyJobListParameters) {
  const { axios: ax } = useAxios();

  //create the query key from the supplied logQueryProperties
  const queryKey = makeQueryKey(deviceCopyJobListQueryProperties);

  return useQuery<Array<DeviceCopyJobListItem>, Error>(
    queryKey,
    async ({ signal }) => {
      //we have to setup the timestamp part of the query inside this function
      //so that it will be updated every time a refetch is done

      //now make the actual data call
      const { data } = await ax.get<Array<DeviceCopyJobListItem>>("devcpy/jobs", {
        signal,
        params: deviceCopyJobListQueryProperties,
      });
      return data;
    },
    {
      enabled: enabled,
      refetchInterval: 60 * 1000,
    }
  );
}
