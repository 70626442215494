import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  MenuItem,
  Stack,
  TextField,
  useTheme,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { DatePicker } from "@mui/x-date-pickers";
import { AgGridReact } from "ag-grid-react";
import { useAppSettings } from "../../contexts/app-context/AppContext";
import {
  ClientAcessTypeEnum,
  PageUsageData,
  PageUsageQueryProperties,
  PageUsageSummary,
} from "../../queries/page-usage/Models";
import { useQueryClient } from "react-query";
import { keys } from "../../queries/page-usage/Keys";
import isEqual from "react-fast-compare";
import { usePageUsageData } from "../../queries/page-usage/UsePageUsageData";
import { ColDef } from "ag-grid-community";
import ContentView from "../../components/content-view/ContentView";
import ContentHeader from "../../components/content-header/ContentHeader";
import { faTableCells, faChartBar } from "@elynx/pro-light-svg-icons";
import PageUsageSummaryChart from "./PageUsageSummaryChart";
import FileSaver from "file-saver";
import { useCurrentPng } from "recharts-to-png";
import FontAwesomeIconButton from "../../components/font-awesome-icon-button/FontAwesomeIconButton";
import { LocalTimestampFormatter } from "../../components/ag-grid-extensions/utilities/LocalTimestampFormatter";
import { FormatDateYMD } from "../../utilities/FormatDateYMD";
import { CustomerTypeEnum } from "../../queries/customer/Models";

const defaultPageUsageQueryProperties = {
  clientAccessType: ClientAcessTypeEnum.SCADALynx,
  timeframe: 90,
  startDate: null,
  endDate: null,
  includeElynx: true,
  customerType: CustomerTypeEnum.AllCustomers,
} as PageUsageQueryProperties;

enum PageUsageViewMode {
  Grid = 0,
  Chart = 1,
}

export default function PageUsage() {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const gridRef = useRef<AgGridReact>(null);
  const [appSettings] = useAppSettings();
  const gridTheme = appSettings.themeSet[appSettings.themeSet.mode].grid;
  const [getChartPng, { ref: chartRef }] = useCurrentPng();
  const [retrieveData, setRetrieveData] = useState(false);
  const [pageUsageQueryProperties, setPageUsageQueryProperties] = useState(defaultPageUsageQueryProperties);
  const [runningQueryProperties, setRunningQueryProperties] = useState(defaultPageUsageQueryProperties);
  const [pageUsageData, setPageUsageData] = useState<Array<PageUsageData>>([]);
  const [pageUsageSummary, setPageUsageSummary] = useState<Array<PageUsageSummary>>([]);
  const [viewMode, setViewMode] = useState(PageUsageViewMode.Grid);

  //use the page usage query to retrieve data for the currently running set of properties
  const {
    data: newUsageData,
    isFetching,
    isSuccess,
    refetch,
  } = usePageUsageData({
    enabled: retrieveData,
    pageUsageQueryProperties: runningQueryProperties,
  });

  //if the user changes their query parameters
  //wipe out current data to avoid confusion
  //and cancel a running query, if there is one
  useEffect(() => {
    setPageUsageData([]);
    setPageUsageSummary([]);
    if (isFetching) {
      queryClient.cancelQueries(keys.queryKey(runningQueryProperties));
      setRetrieveData(false);
    }
  }, [pageUsageQueryProperties]);

  //turn off the query if it succeeded
  //and save the new data
  useEffect(() => {
    if (isSuccess) {
      setRetrieveData(false);
    }
    if (newUsageData?.pageUsageData) {
      setPageUsageData(newUsageData.pageUsageData);
    }
    if (newUsageData?.pageUsageSummary) {
      setPageUsageSummary(newUsageData.pageUsageSummary);
    }
  }, [isSuccess, newUsageData]);

  //we only want to fetch data when the refresh button is clicked,
  //as opposed to automatically every time a user control is changed,
  //so handle both initiating and canceling the query here
  const handleRefreshButtonClick = () => {
    //if we are currently fetching data, cancel the current query
    if (isFetching) {
      queryClient.cancelQueries(keys.queryKey(runningQueryProperties));
      setRetrieveData(false);
    }
    //if not, either refetch the current query or start a new one
    else {
      //if the query properties have changed,
      //save them and start a new query
      if (false === isEqual(runningQueryProperties, pageUsageQueryProperties)) {
        setRunningQueryProperties(pageUsageQueryProperties);
        setRetrieveData(true);
      }
      //if not, just refetch
      else {
        refetch();
      }
    }
  };

  const columnDefs = useMemo<Array<ColDef>>(() => {
    return [
      {
        headerName: "View Name",
        field: "viewName",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        sort: "asc",
      },
      //this column is only visible for ReactApp usage
      {
        headerName: "Tab Name",
        field: "tabName",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        hide: pageUsageQueryProperties.clientAccessType != ClientAcessTypeEnum.ReactApp,
      },
      {
        headerName: "Customer ID",
        field: "customerID",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        minWidth: 120,
        maxWidth: 150,
      },
      {
        headerName: "Customer Name",
        field: "customerName",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Customer Type",
        field: "cust_type",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
        minWidth: 120,
      },
      {
        headerName: "User Login",
        field: "userLogin",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Access Count",
        field: "accessCount",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Last Access",
        field: "lastAccess",
        valueFormatter: LocalTimestampFormatter,
      },
    ];
  }, [pageUsageQueryProperties.clientAccessType]);

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  const autoSizeColumns = useCallback(() => {
    gridRef.current?.api.autoSizeAllColumns();
  }, []);

  const getExportFileName = useCallback(() => {
    const dataType =
      runningQueryProperties.clientAccessType == ClientAcessTypeEnum.SCADALynx
        ? "Legacy"
        : runningQueryProperties.clientAccessType == ClientAcessTypeEnum.HTML5Mobile
        ? "HTML5Mobile"
        : runningQueryProperties.clientAccessType == ClientAcessTypeEnum.ReactApp
        ? "React"
        : runningQueryProperties.clientAccessType == ClientAcessTypeEnum.PublicAPI
        ? "PublicAPI"
        : "";
    const dateSpan =
      runningQueryProperties.timeframe == 0
        ? `${runningQueryProperties.startDate ? FormatDateYMD(runningQueryProperties.startDate) : ""}_${
            runningQueryProperties.endDate ? FormatDateYMD(runningQueryProperties.endDate) : ""
          }`
        : `Past_${runningQueryProperties.timeframe}_days`;

    return `${dataType}_PageUsage_${dateSpan}`;
  }, [runningQueryProperties]);

  const handleExportCSVClick = useCallback(() => {
    const defaultFileName = `${getExportFileName()}.csv`;
    gridRef.current?.api.exportDataAsCsv({ fileName: defaultFileName });
  }, [runningQueryProperties]);

  const handleChartDownload = useCallback(async () => {
    const defaultFileName = `${getExportFileName()}.png`;
    const png = await getChartPng();
    if (png) {
      FileSaver.saveAs(png, defaultFileName);
    }
  }, [getChartPng, runningQueryProperties]);

  return (
    <ContentView>
      <ContentHeader title={"Page Usage Data"} />
      <Stack
        direction="row"
        spacing={1}
        sx={{
          backgroundColor: theme.palette.neutral.lowContrast,
          flexWrap: "wrap",
          padding: "6px",
          rowGap: "6px",
          alignItems: "center",
        }}
      >
        <TextField
          size="small"
          select
          variant="filled"
          label="Access Type"
          value={pageUsageQueryProperties.clientAccessType}
          onChange={(e) =>
            setPageUsageQueryProperties({ ...pageUsageQueryProperties, clientAccessType: +e.target.value })
          }
          sx={{ width: "25ch" }}
        >
          <MenuItem value={ClientAcessTypeEnum.SCADALynx}>Legacy (SCADALynx)</MenuItem>
          <MenuItem value={ClientAcessTypeEnum.HTML5Mobile}>HTML5 Mobile</MenuItem>
          <MenuItem value={ClientAcessTypeEnum.ReactApp}>React App</MenuItem>
          <MenuItem value={ClientAcessTypeEnum.PublicAPI}>Public API</MenuItem>
        </TextField>
        <TextField
          size="small"
          select
          variant="filled"
          label="Timeframe"
          value={pageUsageQueryProperties.timeframe}
          onChange={(e) => setPageUsageQueryProperties({ ...pageUsageQueryProperties, timeframe: +e.target.value })}
          sx={{ width: "16ch" }}
        >
          <MenuItem value={0}>Custom</MenuItem>
          <MenuItem value={30}>Past 30 days</MenuItem>
          <MenuItem value={90}>Past 90 days</MenuItem>
        </TextField>
        <DatePicker
          slotProps={{ textField: { variant: "filled", size: "small", sx: { width: "26ch" } } }}
          label="Start Date"
          value={pageUsageQueryProperties.startDate}
          disabled={0 !== pageUsageQueryProperties.timeframe}
          onChange={(newValue: Date | null) => {
            if (newValue) {
              setPageUsageQueryProperties({ ...pageUsageQueryProperties, startDate: newValue });
            }
          }}
        />
        <DatePicker
          disabled={0 !== pageUsageQueryProperties.timeframe}
          slotProps={{ textField: { variant: "filled", size: "small", sx: { width: "26ch" } } }}
          label="End Date"
          value={pageUsageQueryProperties.endDate}
          onChange={(newValue: Date | null) => {
            if (newValue) {
              setPageUsageQueryProperties({ ...pageUsageQueryProperties, endDate: newValue });
            }
          }}
        />
        <TextField
          size="small"
          select
          variant="filled"
          label="Customer Type"
          value={pageUsageQueryProperties.customerType}
          onChange={(e) => setPageUsageQueryProperties({ ...pageUsageQueryProperties, customerType: +e.target.value })}
          sx={{ width: "18ch" }}
        >
          <MenuItem value={CustomerTypeEnum.AllCustomers}>All Customers</MenuItem>
          <MenuItem value={CustomerTypeEnum.Energy}>Energy</MenuItem>
          <MenuItem value={CustomerTypeEnum.Water}>Water</MenuItem>
        </TextField>
        <FormControlLabel
          value="ml"
          control={
            <Checkbox
              sx={{ paddingLeft: "0px", paddingRight: "4px" }}
              checked={pageUsageQueryProperties.includeElynx}
              onChange={(e) =>
                setPageUsageQueryProperties({ ...pageUsageQueryProperties, includeElynx: e.target.checked })
              }
            />
          }
          label="Include eLynx users"
          labelPlacement="end"
        />
        <FontAwesomeIconButton
          icon={faTableCells}
          iconSize={32}
          title="Show data in grid"
          onClick={() => setViewMode(PageUsageViewMode.Grid)}
          sx={{ marginLeft: "24px !important" }}
        />
        <FontAwesomeIconButton
          icon={faChartBar}
          iconSize={32}
          title="Show summary chart"
          onClick={() => setViewMode(PageUsageViewMode.Chart)}
        />
        <Box
          sx={{
            "& > button": { m: 1 },
            display: "flex",
            width: "20ch",
            marginLeft: "24px",
          }}
        >
          {viewMode == PageUsageViewMode.Grid && (
            <Button
              onClick={handleExportCSVClick}
              variant="contained"
              disabled={!(pageUsageData && pageUsageData.length > 0)}
            >
              Export CSV
            </Button>
          )}
          {viewMode == PageUsageViewMode.Chart && (
            <Button
              onClick={handleChartDownload}
              variant="contained"
              disabled={!(pageUsageData && pageUsageData.length > 0)}
            >
              Save PNG File
            </Button>
          )}
        </Box>
        <Box
          sx={{
            "& > button": { m: 1 },
            display: "flex",
            width: "14ch",
            marginLeft: "auto !important",
            marginRight: "1em !important",
          }}
        >
          <Button
            onClick={() => handleRefreshButtonClick()}
            endIcon={isFetching ? <CircularProgress color="inherit" size="1em" /> : <RefreshIcon />}
            variant="contained"
          >
            Refresh
          </Button>
        </Box>
      </Stack>

      {/* It appears that in order to make the grid fit into a flex scheme 
      it needs to be contained by a div with a hard size (calculated by flex, in this case) that it can fill completely */}
      {viewMode == PageUsageViewMode.Grid && (
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            "& .ag-theme-alpine .ag-cell-value": {
              lineHeight: "20px !important",
              wordBreak: "normal",
              paddingTop: "5px",
              paddingBottom: "5px",
            },
            "& .ag-theme-alpine-dark .ag-cell-value": {
              lineHeight: "20px !important",
              wordBreak: "normal",
              paddingTop: "5px",
              paddingBottom: "5px",
            },
          }}
        >
          <div className={gridTheme} style={{ height: "100%", width: "100%" }}>
            <AgGridReact
              ref={gridRef}
              defaultColDef={defaultColDef}
              rowData={pageUsageData}
              columnDefs={columnDefs}
              ensureDomOrder={true}
              enableCellTextSelection={true}
              onFirstDataRendered={autoSizeColumns}
              onGridReady={autoSizeColumns}
              onModelUpdated={autoSizeColumns}
              suppressClickEdit={true}
              suppressCellFocus={true}
              alwaysMultiSort={true}
              columnMenu="legacy"
            />
          </div>
        </Box>
      )}
      {viewMode == PageUsageViewMode.Chart && (
        <PageUsageSummaryChart
          chartRef={chartRef}
          chartLabel="Page Usage Summary"
          summaryData={pageUsageSummary}
          clientAccessType={runningQueryProperties.clientAccessType}
        />
      )}
    </ContentView>
  );
}
