import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { SupportCenterAuditTrailItem, SupportCenterAuditTrailQueryProperties } from "./Models";
import { keys } from "./Keys";
import { useQuery } from "react-query";
import { addHours } from "date-fns";

export type UseSupportCenterAudiTrailParameters = {
  enabled: boolean;
  auditTrailQueryProperties: SupportCenterAuditTrailQueryProperties;
};

export function makeQueryKey(auditTrailQueryProperties: SupportCenterAuditTrailQueryProperties) {
  return [keys.all, auditTrailQueryProperties];
}

const wrapInPercent = (val: string | undefined) => {
  return val && `%${val}%`;
};

export function useSupportCenterAuditTrail({
  enabled,
  auditTrailQueryProperties,
}: UseSupportCenterAudiTrailParameters) {
  const { axios: ax } = useAxios();

  //create the query key from the supplied logQueryProperties
  const queryKey = makeQueryKey(auditTrailQueryProperties);

  return useQuery<Array<SupportCenterAuditTrailItem>, Error>(
    queryKey,
    async ({ signal }) => {
      //we have to setup the timestamp part of the query inside this function
      //so that it will be updated every time a refetch is done

      //setup the SQL wildcards
      //this is done here since we may want to control them
      //via the UI at some point in the future
      let queryProperties = {
        ...auditTrailQueryProperties,
        scat_username: wrapInPercent(auditTrailQueryProperties.scat_username),
        scat_machine_name: wrapInPercent(auditTrailQueryProperties.scat_machine_name),
        scat_old_value: wrapInPercent(auditTrailQueryProperties.scat_old_value),
        scat_new_value: wrapInPercent(auditTrailQueryProperties.scat_new_value),
        scat_description: wrapInPercent(auditTrailQueryProperties.scat_description),
      } as SupportCenterAuditTrailQueryProperties;

      //unless the user selected "Custom" as the timeframe
      //create the date range from the timeframe and clone a new object
      if (auditTrailQueryProperties && auditTrailQueryProperties.timeframe && auditTrailQueryProperties.timeframe > 0) {
        const endTime = new Date();
        const startTime = addHours(endTime, 0 - auditTrailQueryProperties.timeframe);
        queryProperties = {
          ...queryProperties,
          scat_start_timestamp: startTime,
          scat_end_timestamp: endTime,
        };
      }

      //now make the actual data call
      const { data } = await ax.post<Array<SupportCenterAuditTrailItem>>("scaudit/query", queryProperties, { signal });
      return data;
    },
    {
      enabled: enabled,
    }
  );
}
