import { useMutation } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { NonPolledDataQueryProperties } from "./Models";

export function useResubmitFiles() {
  const { axios: ax } = useAxios();

  return useMutation<void, Error, NonPolledDataQueryProperties>(async (queryProperties) => {
    await ax.post("nonpolleddataops/files/resubmit", queryProperties);
  });
}
