export enum ReportTaskStatusEnum {
  Idle = 0,
  Pending = 1,
  Running = 2,
  TaskCompleted = 3,
  TimedOut = 4,
  Error = 5,
  RetryScheduled = 6,
}

export enum ReportSchedulingDateRangeTypeEnum {
  Once = 0,
  Minute = 1,
  Hour = 2,
  Day = 3,
  Week = 4,
  Month = 5,
  Year = 6,
}

export type ReportTaskQueryProperties = {
  customerID: number;
  includeTasksWithInactiveSchedules: boolean;
};

export const defaultReportTaskQueryProperties = {
  customerID: -1,
  includeTasksWithInactiveSchedules: false,
};

export type ReportSchedule = {
  allowStatusReset?: boolean;
  attempt?: number;
  attemptDesc: string;
  description: string;
  endTimeDto?: Date;
  id: number;
  taskId: number;
  customerId: number;
  isActive: boolean;
  nextRunTimeDto: Date;
  nextScheduledTimeDto?: Date;
  repeatDateRange: ReportSchedulingDateRangeTypeEnum;
  repeatInterval: number;
  runAgainTimeSpan: string;
  runningTimeSpan: string;
  startTimeDto: Date;
  status: ReportTaskStatusEnum;
  statusDesc: string;
  statusChangeTimeDto?: Date;
};

export type ReportTask = {
  id: number;
  name: string;
  schedules: Array<ReportSchedule>;
};

export enum ReportSchedulingDeliveryOverrideTypeEnum {
  Default = 0,
  ForceEmail = 1,
  ForceFTP = 2,
  NoDelivery = 3,
}

export type ReportTestConfiguration = {
  name: string;
  selectedCustomerID?: number;
  selectedTaskIDs?: Array<number>;
  deliveryOverride: ReportSchedulingDeliveryOverrideTypeEnum;
  sortMode?: number;
  runSpecificTasks?: boolean;
  overrideReportDates?: boolean;
  reportStartDto?: Date | null;
  reportEndDto?: Date | null;
  overrideEmail?: boolean;
  overrideFtp?: boolean;
  selectedFtpConfigs?: Array<string>;
  selectedEmailAddresses?: Array<string>;
  includeUnscheduledTasks?: boolean;
  includeInactiveSchedules?: boolean;
  deleteReportAfterDelivery?: boolean;
  disableDBUpdate?: boolean;
  shouldAutoResendMessage?: boolean;
  autoResendInterval?: string;
  useSingleSchedule?: boolean;
  emailSubjectOverride?: string;
  emailBodyOverride?: string;
};

export const defaultReportTestConfiguration = {
  name: "Custom",
  autoResendInterval: "00:00:00",
  deleteReportAfterDelivery: true,
  deliveryOverride: ReportSchedulingDeliveryOverrideTypeEnum.ForceEmail,
  disableDBUpdate: false,
  includeInactiveSchedules: false,
  includeUnscheduledTasks: false,
  overrideEmail: true,
  overrideFtp: false,
  overrideReportDates: false,
  reportStartDto: null,
  reportEndDto: null,
  runSpecificTasks: false,
  selectedCustomerID: -1,
  selectedEmailAddresses: [],
  selectedFtpConfigs: [],
  selectedTaskIDs: [],
  shouldAutoResendMessage: false,
  sortMode: 0,
  useSingleSchedule: true,
} as ReportTestConfiguration;

export type FtpConfiguration = {
  configName: string;
  hostName: string;
  port?: number;
  userName: string;
  password: string;
  remoteDirectory: string;
};

export type ReportScheduleTesterConfig = {
  emailAddresses: Array<string>;
  ftpConfigurations: Array<FtpConfiguration>;
  testConfigurations: Array<ReportTestConfiguration>;
};
