import { Stack } from "@mui/material";
import React, { PropsWithChildren } from "react";

export default function ContentContainer({
  children,
}: PropsWithChildren<unknown>) {
  return (
    <Stack
      direction="row"
      sx={{
        overflow: "hidden",
        flexGrow: 1,
      }}
    >
      {children}
    </Stack>
  );
}
