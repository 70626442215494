export type SupportCenterAuditTrailQueryProperties = {
  scat_start_timestamp?: Date | null;
  scat_end_timestamp?: Date | null;
  scat_customer_id?: number;
  scat_organization_id?: number;
  scat_username?: string;
  scat_machine_name?: string;
  scat_action?: string;
  scat_page_name?: string;
  scat_object_type_id?: number;
  scat_object_id?: number;
  scat_old_value?: string;
  scat_new_value?: string;
  scat_description?: string;
  scat_max_count?: number;
  timeframe?: number;
};

export type SupportCenterAuditTrailItem = {
  scat_timestamp: Date;
  scat_customer_id: number;
  scat_organization_id: number;
  scat_username: string;
  scat_machine_name: string;
  scat_action: string;
  scat_page_name: string;
  scat_object_type_id?: number;
  scat_object_id?: number;
  scat_old_value?: string;
  scat_new_value?: string;
  scat_description: string;
};

export const defaultSupportCenterAuditTrailQueryProperties: SupportCenterAuditTrailQueryProperties = {
  scat_max_count: 100,
  timeframe: 24,
  scat_start_timestamp: null,
  scat_end_timestamp: null,
};
