import React, { useCallback, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { eLynxUserModel } from "../../queries/elynx-user/Models";
import { useConfirm } from "../../contexts/confirm-context/ConfirmContext";
import { useForm } from "react-hook-form";
import { FormInputText } from "../../components/form-components/FormInputText";

export type EditUserProperties = {
  open: boolean;
  onClose: () => void;
  user?: eLynxUserModel;
  saveUser: (user: eLynxUserModel) => void;
};

type EditUserFormInput = {
  firstName: string;
  lastName: string;
};

//AddUser and EditUser are similar but have different logic in enough places
//that creating separate components seemed less complex, if slightly less DRY,
//than embedding all those "if" statements in a single component
export default function EditUser({ open, onClose, user, saveUser }: EditUserProperties) {
  const confirm = useConfirm();
  const { control, handleSubmit, reset, setValue } = useForm<EditUserFormInput>({
    defaultValues: { firstName: "", lastName: "" },
  });

  //whenever the user property changes, update the values of our fields
  useEffect(() => {
    if (user) {
      setValue("firstName", user.user_first_name ?? "");
      setValue("lastName", user.user_last_name ?? "");
    } else {
      reset();
    }
  }, [user]);

  const onClick = useCallback(
    (data: EditUserFormInput) => {
      if (user && (user.user_first_name != data.firstName || user.user_last_name != data.lastName)) {
        const confirmDescription = `Are you sure you want to change the name of eLynx user ${user.gl_login_name} from ${user.user_first_name} ${user.user_last_name} to ${data.firstName} ${data.lastName}?`;

        //confirm rejects the promise if the user does not confirm
        confirm({
          title: "Confirm Edit eLynx User",
          description: confirmDescription,
          allowClose: false,
        })
          .then(() => {
            console.log(
              `Edit eLynx user ${user.gl_login_name} from ${user.user_first_name} ${user.user_last_name} to ${data.firstName} ${data.lastName}`
            );

            //create the user with the new state and send that to the save user function
            const newUser = {
              ...user,
              user_first_name: data.firstName,
              user_last_name: data.lastName,
            } as eLynxUserModel;

            saveUser(newUser);
          })
          .catch(() => {
            console.log("Edit eLynx user canceled!");
          });
      }
      onClose();
    },
    [user]
  );

  return (
    <>
      {user && (
        <Dialog open={open} onClose={onClose} scroll="paper" fullWidth={true} maxWidth="sm">
          <DialogTitle>Edit User</DialogTitle>
          <DialogContent dividers={true}>
            <FormInputText
              name="firstName"
              label="First Name"
              control={control}
              rules={{
                required: { value: true, message: "First Name is required" },
                maxLength: { value: 50, message: "First Name cannot contain more than 50 characters" },
              }}
            />
            <FormInputText
              name="lastName"
              label="Last Name"
              control={control}
              rules={{
                required: { value: true, message: "Last Name is required" },
                maxLength: { value: 50, message: "Last Name cannot contain more than 50 characters" },
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button color="secondary" onClick={handleSubmit(onClick)}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
