import { Box } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { isMobile, isTablet } from "react-device-detect";

const isPhone = isMobile && !isTablet;

export default function ContentSection({
  children,
}: PropsWithChildren<unknown>) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        overflow: isPhone ? "scroll" : "hidden",
        position: "relative",
      }}
    >
      {children}
    </Box>
  );
}
