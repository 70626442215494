import React, { useEffect, useReducer, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import ContentView from "../../components/content-view/ContentView";
import ContentHeader from "../../components/content-header/ContentHeader";
import { AutocompleteOption } from "../../components/form-components/FormInputAutocomplete";
import { useTDTCustomerList } from "../../queries/tag-data-testing/UseTDTCustomerList";
import { useTDTDeviceList } from "../../queries/tag-data-testing/UseTDTDeviceList";
import { useTDTTagList } from "../../queries/tag-data-testing/UseTDTTagList";
import { useTDTSetpointList } from "../../queries/tag-data-testing/UseTDTSetpointList";
import { testTagDataActionReducer, TestTagDataActionTypeEnum } from "./TestTagDataActions";
import { defaultPublishTagValueRequest } from "../../queries/tag-data-testing/PublishTagValueRequest";
import { TDTSetpoint, TDTTagValue } from "../../queries/tag-data-testing/Models";
import { useTDTTagValue } from "../../queries/tag-data-testing/UseTDTTagValue";
import { useTDTSetpoint } from "../../queries/tag-data-testing/UseTDTSetpoint";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useUserRole } from "../../contexts/user-role-context/UserRoleContext";
import { usePublishTagValue } from "../../queries/tag-data-testing/UsePublishTagValue";
import { useToastNotification } from "../../contexts/toast-notification/ToastNotificationContext";
import { FormatTimestampLocal } from "../../utilities/FormatTimestampLocal";

export default function TestTagData() {
  const theme = useTheme();
  const { isOpsCenterWriterMember } = useUserRole();
  const { displayToast } = useToastNotification();
  const [customerList, setCustomerList] = useState<Array<AutocompleteOption>>([]);
  const [deviceList, setDeviceList] = useState<Array<AutocompleteOption>>([]);
  const [tagList, setTagList] = useState<Array<AutocompleteOption>>([]);
  const [selectedTag, setSelectedTag] = useState<TDTTagValue | undefined>(undefined);
  const [setpointList, setSetpointList] = useState<Array<AutocompleteOption>>([]);
  const [selectedSetpoint, setSelectedSetpoint] = useState<TDTSetpoint | undefined>(undefined);
  const [publishTagDataRequest, dispatchPublishTagDataRequest] = useReducer(
    testTagDataActionReducer,
    defaultPublishTagValueRequest
  );
  const [autoRefreshSeconds, setAutoRefreshSeconds] = useState(0);

  const { data: newCustomerList, isSuccess: isCustomerSuccess } = useTDTCustomerList();
  const { data: newDeviceList, isSuccess: isDeviceSuccess } = useTDTDeviceList({
    customerID: publishTagDataRequest.customerID ?? 0,
    enabled: (publishTagDataRequest.customerID ?? 0) > 0,
  });
  const { data: newTagList, isSuccess: isTagSuccess } = useTDTTagList({
    customerID: publishTagDataRequest.customerID ?? 0,
    deviceID: publishTagDataRequest.parentID ?? 0,
    enabled: (publishTagDataRequest.customerID ?? 0) > 0 && (publishTagDataRequest.parentID ?? 0) > 0,
  });
  const {
    data: newTagValue,
    isSuccess: isTagValueSuccess,
    isFetching: isFetchingTagValue,
    refetch: refetchTagValue,
  } = useTDTTagValue({
    customerID: publishTagDataRequest.customerID ?? 0,
    tagID: publishTagDataRequest.tagID ?? 0,
    enabled: (publishTagDataRequest.customerID ?? 0) > 0 && (publishTagDataRequest.tagID ?? 0) > 0,
    autoRefreshSeconds: autoRefreshSeconds,
  });
  const { data: newSetpointList, isSuccess: isSetpointListSuccess } = useTDTSetpointList({
    customerID: publishTagDataRequest.customerID ?? 0,
    tagID: publishTagDataRequest.tagID ?? 0,
    enabled: (publishTagDataRequest.customerID ?? 0) > 0 && (publishTagDataRequest.tagID ?? 0) > 0,
  });
  const {
    data: newSetpoint,
    isSuccess: isSetpointSuccess,
    isFetching: isFetchingSetpoint,
    refetch: refetchSetpoint,
  } = useTDTSetpoint({
    customerID: publishTagDataRequest.customerID ?? 0,
    tagID: publishTagDataRequest.tagID ?? 0,
    setpointID: publishTagDataRequest.setpointID ?? 0,
    enabled:
      (publishTagDataRequest.customerID ?? 0) > 0 &&
      (publishTagDataRequest.tagID ?? 0) > 0 &&
      (publishTagDataRequest.setpointID ?? 0) > 0,
    autoRefreshSeconds: autoRefreshSeconds,
  });
  const { mutate: publishTagValue } = usePublishTagValue();

  useEffect(() => {
    if (isCustomerSuccess === true && newCustomerList && newCustomerList.length > 0) {
      setCustomerList(
        newCustomerList.map((c) => {
          return { id: c.customerID, label: `${c.customerID} - ${c.customerName}` };
        })
      );
    }
  }, [isCustomerSuccess, newCustomerList]);

  useEffect(() => {
    if (isDeviceSuccess === true && newDeviceList && newDeviceList.length > 0) {
      setDeviceList(
        newDeviceList.map((d) => {
          return { id: d.deviceID, label: `${d.deviceID} - ${d.deviceName}` };
        })
      );
    }
  }, [isDeviceSuccess, newDeviceList]);

  useEffect(() => {
    if (isTagSuccess === true && newTagList && newTagList.length > 0) {
      setTagList(
        newTagList.map((d) => {
          return { id: d.tagID, label: `${d.tagID} - ${d.tagName}` };
        })
      );
    } else {
      setTagList([]);
    }
  }, [isTagSuccess, newTagList]);

  useEffect(() => {
    if (isTagValueSuccess === true && newTagValue) {
      setSelectedTag(newTagValue);
    } else {
      setSelectedTag(undefined);
    }
  }, [isTagValueSuccess, newTagValue]);

  useEffect(() => {
    if (isSetpointListSuccess === true && newSetpointList && newSetpointList.length > 0) {
      setSetpointList(
        newSetpointList.map((d) => {
          return { id: d.setPointID, label: `${d.setPointID} - ${d.setPointName}` };
        })
      );
    } else {
      setSetpointList([]);
    }
  }, [isSetpointListSuccess, newSetpointList]);

  useEffect(() => {
    if (isSetpointSuccess === true && newSetpoint) {
      setSelectedSetpoint(newSetpoint);
    } else {
      setSelectedSetpoint(undefined);
    }
  }, [isSetpointSuccess, newSetpoint]);

  const handleRefreshButtonClick = () => {
    refetchTagValue();
    refetchSetpoint();
  };

  const handleSendNewValue = () => {
    const msg = `value '${publishTagDataRequest.dataValue}' with timestamp '${publishTagDataRequest.timestamp}' to tag ID '${publishTagDataRequest.tagID}' in customer ID '${publishTagDataRequest.customerID}'.`;

    publishTagValue(publishTagDataRequest, {
      onSuccess: () =>
        displayToast({
          message: `Successfully published ${msg}`,
          severity: "success",
        }),
      onError: (error) =>
        displayToast({
          message: `Error publishing ${msg}: ${error}`,
          severity: "error",
          duration: 15000,
        }),
    });
  };

  return (
    <ContentView>
      <ContentHeader title="Test Tag Data Utility" />
      <Stack
        direction="row"
        spacing={3}
        sx={{
          backgroundColor: theme.palette.neutral.lowContrast,
          flexWrap: "wrap",
          padding: "6px",
          rowGap: "6px",
          alignItems: "center",
        }}
      >
        <Autocomplete
          autoHighlight
          size="small"
          options={customerList}
          onChange={(event, value) => {
            if (value) {
              dispatchPublishTagDataRequest({
                type: TestTagDataActionTypeEnum.SetCustomer,
                customerID: value.id,
              });
            }
          }}
          sx={{ width: "40ch" }}
          renderInput={(params) => <TextField {...params} label="Customer" />}
        />
        <Autocomplete
          key={`device-${publishTagDataRequest.customerID}`}
          autoHighlight
          size="small"
          options={deviceList}
          onChange={(event, value) => {
            if (value) {
              dispatchPublishTagDataRequest({
                type: TestTagDataActionTypeEnum.SetDevice,
                deviceID: value.id,
              });
            }
          }}
          sx={{ width: "40ch" }}
          renderInput={(params) => <TextField {...params} label="Device" />}
        />
        <Autocomplete
          key={`tag-${publishTagDataRequest.parentID}`}
          autoHighlight
          size="small"
          options={tagList}
          onChange={(event, value) => {
            if (value) {
              dispatchPublishTagDataRequest({
                type: TestTagDataActionTypeEnum.SetTag,
                tagID: value.id,
              });
            }
          }}
          sx={{ width: "40ch" }}
          renderInput={(params) => <TextField {...params} label="Tag" />}
        />
        <Autocomplete
          key={`setpoint-${publishTagDataRequest.tagID}`}
          autoHighlight
          size="small"
          options={setpointList}
          onChange={(event, value) => {
            if (value) {
              dispatchPublishTagDataRequest({
                type: TestTagDataActionTypeEnum.SetSetpoint,
                setpointID: value.id,
              });
            }
          }}
          sx={{ width: "40ch" }}
          renderInput={(params) => <TextField {...params} label="Setpoint" />}
        />
        <Box sx={{ "& > button": { m: 1 }, display: "flex", width: "14ch", marginLeft: "auto !important" }}>
          <Button
            onClick={() => handleRefreshButtonClick()}
            endIcon={
              isFetchingTagValue || isFetchingSetpoint ? (
                <CircularProgress color="inherit" size="1em" />
              ) : (
                <RefreshIcon />
              )
            }
            variant="contained"
          >
            Refresh
          </Button>
        </Box>
        <TextField
          size="small"
          select
          variant="filled"
          label="Auto-refresh"
          value={autoRefreshSeconds}
          onChange={(e) => setAutoRefreshSeconds(+e.target.value)}
          sx={{ width: "10ch", marginRight: ".5em !important" }}
        >
          <MenuItem value={0}>Off</MenuItem>
          <MenuItem value={30}>30s</MenuItem>
          <MenuItem value={60}>1m</MenuItem>
          <MenuItem value={300}>5m</MenuItem>
          <MenuItem value={900}>15m</MenuItem>
        </TextField>
      </Stack>
      <Stack
        direction="column"
        spacing={3}
        sx={{
          padding: "12px",
          columnGap: "6px",
          alignItems: "left",
          flex: "1 1 auto",
          marginTop: "1.5em",
        }}
      >
        {isOpsCenterWriterMember && (
          <>
            <Typography>Send New Value</Typography>
            <Stack direction="row" spacing={3}>
              <TextField
                size="small"
                variant="filled"
                label="New Value"
                sx={{ width: "20ch" }}
                value={publishTagDataRequest.dataValue ?? undefined}
                onChange={(event) => {
                  dispatchPublishTagDataRequest({
                    type: TestTagDataActionTypeEnum.SetNewValue,
                    newValue: event.target.value,
                  });
                }}
              />
              <DateTimePicker
                slotProps={{ textField: { variant: "filled", size: "small", sx: { width: "26ch" } } }}
                label="Timestamp"
                value={publishTagDataRequest.timestamp}
                onChange={(newValue: Date | null) => {
                  if (newValue) {
                    dispatchPublishTagDataRequest({
                      type: TestTagDataActionTypeEnum.SetNewValueTimestamp,
                      newValueTimestamp: newValue,
                    });
                  }
                }}
              />
              <TextField
                size="small"
                select
                variant="filled"
                label="SetPoint Event Type"
                value={publishTagDataRequest.setPointEventType ?? ""}
                onChange={(e) =>
                  dispatchPublishTagDataRequest({
                    type: TestTagDataActionTypeEnum.SetSetpointType,
                    setpointType: +e.target.value,
                  })
                }
                sx={{ width: "25ch" }}
              >
                <MenuItem value={1}>Immediate</MenuItem>
                <MenuItem value={2}>Scheduled</MenuItem>
                <MenuItem value={3}>Manual</MenuItem>
                <MenuItem value={4}>Calculated</MenuItem>
                <MenuItem value={5}>Imported</MenuItem>
                <MenuItem value={6}>Allocated</MenuItem>
                <MenuItem value={7}>Recalculated</MenuItem>
              </TextField>
              <FormControlLabel
                value="bf"
                control={
                  <Checkbox
                    sx={{ paddingLeft: "0px", paddingRight: "4px" }}
                    checked={publishTagDataRequest.bypassFormula}
                    onChange={(e) =>
                      dispatchPublishTagDataRequest({
                        type: TestTagDataActionTypeEnum.SetBypassFormula,
                        bypassFormula: e.target.checked,
                      })
                    }
                  />
                }
                label="Bypass Formula"
                labelPlacement="end"
              />
              <FormControlLabel
                value="uta"
                control={
                  <Checkbox
                    sx={{ paddingLeft: "0px", paddingRight: "4px" }}
                    checked={publishTagDataRequest.useTimestampsUnaltered}
                    onChange={(e) =>
                      dispatchPublishTagDataRequest({
                        type: TestTagDataActionTypeEnum.SetUseTimestampsUnaltered,
                        useTimestampsUnaltered: e.target.checked,
                      })
                    }
                  />
                }
                label="Do not alter timestamps"
                labelPlacement="end"
              />
              <Box
                sx={{
                  "& > button": { m: 1 },
                  display: "flex",
                  width: "20ch",
                  marginLeft: "24px",
                }}
              >
                <Button
                  onClick={handleSendNewValue}
                  variant="contained"
                  disabled={
                    !(
                      publishTagDataRequest.dataValue &&
                      publishTagDataRequest.timestamp &&
                      publishTagDataRequest.setPointEventType
                    )
                  }
                >
                  Send Value
                </Button>
              </Box>
            </Stack>
          </>
        )}
        <Typography>Current Tag Value</Typography>
        <Stack direction="row" spacing={3}>
          <TextField
            size="small"
            variant="filled"
            disabled
            label="Tag ID"
            sx={{ width: "12ch" }}
            value={selectedTag?.tagID ?? ""}
          />
          <TextField
            size="small"
            variant="filled"
            disabled
            label="Timestamp"
            sx={{ width: "25ch" }}
            value={
              selectedTag?.lastKnownValueTimestamp
                ? FormatTimestampLocal(selectedTag.lastKnownValueTimestamp.toString())
                : ""
            }
          />
          <TextField
            size="small"
            variant="filled"
            disabled
            label="Current Value"
            sx={{ width: "18ch" }}
            value={selectedTag?.lastKnownValue ?? ""}
          />
          <TextField
            size="small"
            variant="filled"
            disabled
            label="SetPoint Event Type"
            sx={{ width: "20ch" }}
            value={selectedTag?.setPointEventType ?? ""}
          />
        </Stack>
        <Typography>Set Point Properties</Typography>
        <Stack
          direction="row"
          spacing={3}
          sx={{
            flexWrap: "wrap",
            padding: "6px",
            rowGap: "6px",
            alignItems: "center",
          }}
        >
          <TextField
            size="small"
            variant="filled"
            disabled
            label="SetPoint ID"
            sx={{ width: "12ch" }}
            value={selectedSetpoint?.setPointID ?? ""}
          />
          <TextField
            size="small"
            variant="filled"
            disabled
            label="SetPoint Value"
            sx={{ width: "18ch" }}
            value={selectedSetpoint?.setPointValue ?? ""}
          />
          <TextField
            size="small"
            variant="filled"
            disabled
            label="SetPoint Operator"
            value={selectedSetpoint?.setPointOperator ?? ""}
          />
          <TextField
            size="small"
            variant="filled"
            disabled
            label="SetPoint Discrete"
            value={selectedSetpoint?.setPointDiscrete ?? ""}
          />
          <TextField
            size="small"
            variant="filled"
            disabled
            label="SetPoint Type"
            sx={{ width: "20ch" }}
            value={selectedSetpoint?.setPointType ?? ""}
          />
          <TextField
            size="small"
            variant="filled"
            disabled
            label="Variance Window"
            sx={{ width: "17ch" }}
            value={selectedSetpoint?.varianceWindow ?? ""}
          />
          <TextField
            size="small"
            variant="filled"
            disabled
            label="Variance Timeframe"
            sx={{ width: "19ch" }}
            value={selectedSetpoint?.varianceTimeframeType ?? ""}
          />
          <TextField
            size="small"
            variant="filled"
            disabled
            label="Variance Direction"
            value={selectedSetpoint?.varianceType ?? ""}
          />
          <TextField
            size="small"
            variant="filled"
            disabled
            label="Currently In Alarm"
            sx={{ width: "14ch" }}
            value={selectedSetpoint?.inAlarm ?? "false"}
          />
        </Stack>
      </Stack>
    </ContentView>
  );
}
