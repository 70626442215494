import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ContentView from "../../components/content-view/ContentView";
import ContentHeader from "../../components/content-header/ContentHeader";
import ContentSection from "../../components/content-section/ContentSection";
import { Box, Button, CircularProgress, Divider, Fab, Stack, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import { AgGridReact } from "ag-grid-react";
import { useConfirm } from "../../contexts/confirm-context/ConfirmContext";
import { useAlert } from "react-alert";
import { useAppSettings } from "../../contexts/app-context/AppContext";
import { useUserRole } from "../../contexts/user-role-context/UserRoleContext";
import { useDeviceCopyJobList } from "../../queries/copy-device/UseDeviceCopyJobList";
import {
  DeviceCopyJob,
  DeviceCopyJobListItem,
  DeviceCopyJobStatusEnum,
  defaultDeviceCopyJobListQueryProperties,
} from "../../queries/copy-device/Models";
import { ColDef, ValueFormatterParams } from "ag-grid-community";
import { LocalTimestampFormatter } from "../../components/ag-grid-extensions/utilities/LocalTimestampFormatter";
import NewCopyJob from "./NewCopyJob";
import FontAwesomeIconButton from "../../components/font-awesome-icon-button/FontAwesomeIconButton";
import { faLineColumns } from "@elynx/pro-regular-svg-icons";
import ConfigureCopyJob from "./configure-job/ConfigureCopyJob";
import CopyJobMonitor from "./CopyJobMonitor";
import ActionButtonCellRenderer, {
  ActionButtonRendererProps,
} from "../../components/ag-grid-extensions/renderers/ActionButtonCellRenderer";
import { useDeleteDeviceCopyJob } from "../../queries/copy-device/UseDeleteDeviceCopyJob";
import MuiIconCellRenderer, {
  MuiIconRendererProps,
} from "../../components/ag-grid-extensions/renderers/MuiIconCellRenderer";

export default function CopyDevice() {
  const theme = useTheme();
  const confirm = useConfirm();
  const alert = useAlert();
  const gridRef = useRef<AgGridReact>(null);
  const [appSettings] = useAppSettings();
  const gridTheme = appSettings.themeSet[appSettings.themeSet.mode].grid;
  //fix this before deployment to Production
  //const { isOpsCenterWriterMember } = useUserRole();
  const isOpsCenterWriterMember = true;
  const [jobList, setJobList] = useState<Array<DeviceCopyJobListItem>>([]);
  const [newJobDialogOpen, setNewJobDialogOpen] = useState(false);
  const [configuringJobDialogOpen, setConfiguringJobDialogOpen] = useState(false);
  const [monitoringJobDialogOpen, setMonitoringJobDialogOpen] = useState(false);
  const [currentJobID, setCurrentJobID] = useState(0);
  const {
    data: newJobList,
    isSuccess,
    isFetching,
    refetch,
  } = useDeviceCopyJobList({
    enabled: true,
    deviceCopyJobListQueryProperties: defaultDeviceCopyJobListQueryProperties,
  });

  const {
    mutate: deleteDeviceCopyJob,
    isError: isDeleteDeviceCopyJobError,
    error: deleteDeviceCopyJobError,
  } = useDeleteDeviceCopyJob();

  //if we get a new list of device copy jobs, put it in our state
  useEffect(() => {
    if (newJobList) {
      setJobList(newJobList);
    }
  }, [isSuccess, newJobList]);

  //report errors deleting a job
  useEffect(() => {
    if (true === isDeleteDeviceCopyJobError) {
      alert.error(`Error deleting device copy job: ${deleteDeviceCopyJobError}`);
    }
  }, [isDeleteDeviceCopyJobError]);

  const autoSizeColumns = useCallback(() => {
    gridRef.current?.api.autoSizeAllColumns(false);
  }, []);

  const onNewDeviceCopyJob = useCallback((job: DeviceCopyJob) => {
    setNewJobDialogOpen(false);
    setCurrentJobID(job.jobID);
    setConfiguringJobDialogOpen(true);
  }, []);

  const getButtonText = useCallback((job: DeviceCopyJobListItem) => {
    return job.dcjl_dcjse_id === DeviceCopyJobStatusEnum.Configuring ? "Edit" : "View";
  }, []);

  const shouldBeDisabled = useCallback(
    (job: DeviceCopyJobListItem) => {
      return job.dcjl_dcjse_id === DeviceCopyJobStatusEnum.Configuring ? !isOpsCenterWriterMember : false;
    },
    [isOpsCenterWriterMember]
  );

  const shouldDisplayDeleteJob = useCallback((job: DeviceCopyJobListItem) => {
    return job.dcjl_dcjse_id === DeviceCopyJobStatusEnum.Configuring;
  }, []);

  const onEditJobButtonClicked = useCallback((job: DeviceCopyJobListItem) => {
    if (job) {
      setCurrentJobID(job.dcjl_id);
      if (job.dcjl_dcjse_id === DeviceCopyJobStatusEnum.Configuring) {
        setConfiguringJobDialogOpen(true);
      } else {
        setMonitoringJobDialogOpen(true);
      }
    }
  }, []);

  const onDeleteJobButtonClicked = useCallback((job: DeviceCopyJobListItem) => {
    if (job) {
      //confirm rejects the promise if the user does not confirm
      confirm({
        title: "Confirm Delete Copy Job",
        description: `Are you sure you want to delete copy job from ${job.src_cust_name} (${job.dcjl_src_cust_id}) to ${job.dst_cust_name} (${job.dcjl_dst_cust_id}) for ${job.dcjl_username}?`,
        allowClose: false,
      })
        .then(() => {
          deleteDeviceCopyJob({ jobID: job.dcjl_id });
        })
        .catch(() => {
          console.log(`Delete device copy job ${job.dcjl_id} canceled!`);
        });
    }
  }, []);

  const onJobStarted = useCallback(() => {
    //close the configuring dialog
    setConfiguringJobDialogOpen(false);

    //and open the monitoring dialog
    setMonitoringJobDialogOpen(true);
  }, []);

  //setup all the columns
  const columnDefs = useMemo<Array<ColDef>>(() => {
    const columns = [
      {
        headerName: "Action",
        colId: "action",
        width: 120,
        cellRenderer: ActionButtonCellRenderer,
        cellRendererParams: {
          disabled: shouldBeDisabled,
          buttonText: getButtonText,
          shouldDisplay: true,
          onClick: onEditJobButtonClicked,
        } as ActionButtonRendererProps,
      },
      {
        headerName: "Start Time",
        field: "dcjl_start_dto",
        valueFormatter: LocalTimestampFormatter,
      },
      {
        headerName: "Username",
        field: "dcjl_username",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Source Customer",
        field: "src_cust_name",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Src Cust ID",
        field: "dcjl_src_cust_id",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Destination Customer",
        field: "dst_cust_name",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "startsWith"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Dst Cust ID",
        field: "dcjl_dst_cust_id",
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: ["equals"],
          debounceMs: 300,
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: "Job Status",
        field: "dcjl_dcjse_id",
        valueFormatter: (params: ValueFormatterParams): string =>
          params.value == null ? "" : DeviceCopyJobStatusEnum[+params.value],
      },
      {
        headerName: "Device Count",
        field: "dcjl_dev_count",
      },
      {
        headerName: "Finish Time",
        field: "dcjl_end_dto",
        valueFormatter: LocalTimestampFormatter,
      },
      {
        headerName: "Job ID",
        field: "dcjl_id",
        width: 100,
      },
      {
        headerName: "Delete",
        colId: "delete",
        width: 100,
        cellRenderer: MuiIconCellRenderer,
        cellRendererParams: {
          disabled: !isOpsCenterWriterMember,
          iconName: "delete",
          size: "medium",
          tooltipText: "Delete",
          shouldDisplay: shouldDisplayDeleteJob,
          onClick: onDeleteJobButtonClicked,
        } as MuiIconRendererProps,
      },
    ] as Array<ColDef>;

    return columns;
  }, [isOpsCenterWriterMember]);

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  return (
    <>
      <ContentView>
        <ContentHeader title={"Copy Device"} />
        <Stack
          direction="row"
          spacing={3}
          divider={<Divider orientation="vertical" flexItem />}
          sx={{
            backgroundColor: theme.palette.neutral.lowContrast,
            flexWrap: "wrap",
            padding: "6px",
            rowGap: "6px",
            alignItems: "center",
          }}
        >
          <FontAwesomeIconButton
            icon={faLineColumns}
            iconSize={32}
            title="Size all columns to fit data"
            onClick={() => autoSizeColumns()}
            sx={{ marginLeft: "24px !important" }}
          />
          <Box sx={{ "& > button": { m: 1 }, display: "flex", width: "14ch" }}>
            <Button
              onClick={() => refetch()}
              endIcon={isFetching ? <CircularProgress color="inherit" size="1em" /> : <RefreshIcon />}
              variant="contained"
            >
              Refresh
            </Button>
          </Box>
        </Stack>
        <ContentSection>
          {/* It appears that in order to make the grid fit into a flex scheme 
      it needs to be contained by a div with a hard size (calculated by flex, in this case) that it can fill completely */}
          <Box
            sx={{
              display: "flex",
              flex: "1 1 auto",
              "& .ag-theme-alpine .ag-cell-value": {
                lineHeight: "20px !important",
                wordBreak: "normal",
                paddingTop: "5px",
                paddingBottom: "5px",
              },
              "& .ag-theme-alpine-dark .ag-cell-value": {
                lineHeight: "20px !important",
                wordBreak: "normal",
                paddingTop: "5px",
                paddingBottom: "5px",
              },
            }}
          >
            <div className={gridTheme} style={{ height: "100%", width: "100%" }}>
              <AgGridReact
                ref={gridRef}
                defaultColDef={defaultColDef}
                rowData={jobList}
                columnDefs={columnDefs}
                ensureDomOrder={true}
                enableCellTextSelection={true}
                suppressClickEdit={true}
                suppressCellFocus={true}
                alwaysMultiSort={true}
                scrollbarWidth={0}
                columnMenu="legacy"
                //onRowClicked={handleRowClicked}
              />
            </div>
          </Box>
        </ContentSection>
      </ContentView>

      {/* Floating Action Button for adding a new config item */}
      <Fab
        color="primary"
        aria-label="add"
        title="Add new configuration item"
        onClick={() => setNewJobDialogOpen(true)}
        disabled={!isOpsCenterWriterMember}
      >
        <AddIcon />
      </Fab>

      <NewCopyJob
        dialogOpen={newJobDialogOpen}
        onClose={() => setNewJobDialogOpen(false)}
        onNewJob={onNewDeviceCopyJob}
      />

      {currentJobID > 0 && (
        <ConfigureCopyJob
          dialogOpen={configuringJobDialogOpen}
          jobID={currentJobID}
          onClose={() => setConfiguringJobDialogOpen(false)}
          onJobStarted={onJobStarted}
        />
      )}

      {currentJobID > 0 && (
        <CopyJobMonitor
          dialogOpen={monitoringJobDialogOpen}
          jobID={currentJobID}
          onClose={() => setMonitoringJobDialogOpen(false)}
        />
      )}
    </>
  );
}
