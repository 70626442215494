export function innerJoin<TLeft, TRight, TKey, TResult>(
  left: Array<TLeft>,
  right: Array<TRight>,
  leftKeySelector: (l: TLeft) => TKey,
  rightKeySelector: (r: TRight) => TKey,
  resultSelector: (l: TLeft, r: TRight) => TResult
): Array<TResult> {
  const rightMap = new Map(right.map((r) => [rightKeySelector(r), r]));
  const returnData = left.flatMap((l) => {
    const leftKey = leftKeySelector(l);
    if (rightMap.has(leftKey)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return resultSelector(l, rightMap.get(leftKey)!);
    } else {
      return [];
    }
  });

  return returnData;
}

export function innerJoinCombined<TLeft, TRight, TKey, TResult extends TLeft & TRight>(
  left: Array<TLeft>,
  right: Array<TRight>,
  leftKeySelector: (l: TLeft) => TKey,
  rightKeySelector: (r: TRight) => TKey
): Array<TResult> {
  const rightMap = new Map(right.map((r) => [rightKeySelector(r), r]));
  const returnData = left.flatMap((l) => {
    const leftKey = leftKeySelector(l);
    if (rightMap.has(leftKey)) {
      return { ...l, ...rightMap.get(leftKey) } as TResult;
    } else {
      return [];
    }
  });

  return returnData;
}

export function leftOuterJoin<TLeft, TRight, TKey, TResult>(
  left: Array<TLeft>,
  right: Array<TRight>,
  leftKeySelector: (l: TLeft) => TKey,
  rightKeySelector: (r: TRight) => TKey,
  resultSelector: (l: TLeft, r: TRight | null) => TResult
): Array<TResult> {
  const rightMap = new Map(right.map((r) => [rightKeySelector(r), r]));
  const returnData = left.map((l) => {
    const leftKey = leftKeySelector(l);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return resultSelector(l, rightMap.has(leftKey) ? rightMap.get(leftKey)! : null);
  });

  return returnData;
}

export function leftOuterJoinCombined<TLeft, TRight, TKey, TResult extends TLeft & TRight>(
  left: Array<TLeft>,
  right: Array<TRight>,
  leftKeySelector: (l: TLeft) => TKey,
  rightKeySelector: (r: TRight) => TKey
): Array<TResult> {
  const rightMap = new Map(right.map((r) => [rightKeySelector(r), r]));
  const returnData = left.map((l) => {
    const leftKey = leftKeySelector(l);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return { ...l, ...(rightMap.has(leftKey) ? rightMap.get(leftKey)! : null) } as TResult;
  });

  return returnData;
}
