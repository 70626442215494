import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, CircularProgress, MenuItem, Stack, TextField, Typography, useTheme } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { AgGridReact } from "ag-grid-react";
import { useAppSettings } from "../../contexts/app-context/AppContext";
import { useUserRole } from "../../contexts/user-role-context/UserRoleContext";
import { MsgQueueInfo } from "../../queries/outbound-msg/Models";
import { useOutboundMsgData } from "../../queries/outbound-msg/UseOutboundMsgData";
import { ColDef } from "ag-grid-community";
import MuiIconCellRenderer, {
  MuiIconRendererProps,
} from "../../components/ag-grid-extensions/renderers/MuiIconCellRenderer";
import ContentView from "../../components/content-view/ContentView";
import ContentHeader from "../../components/content-header/ContentHeader";
import SendMessage from "./SendMessage";
import { FormatTimestampLocal } from "../../utilities/FormatTimestampLocal";

export default function OutboundMsgMonitor() {
  const theme = useTheme();
  const gridRef = useRef<AgGridReact>(null);
  const [appSettings] = useAppSettings();
  const gridTheme = appSettings.themeSet[appSettings.themeSet.mode].grid;
  const { isOpsCenterWriterMember } = useUserRole();
  const [msgQueueData, SetMsgQueueData] = useState<Array<MsgQueueInfo>>([]);
  const [lastUpdateTime, setLastUpdateTime] = useState(new Date());
  const [autoRefreshSeconds, setAutoRefreshSeconds] = useState(30);
  const currentMsgQueueRef = useRef<MsgQueueInfo | null>(null);
  const [sendMsgDialogOpen, setSendMsgDialogOpen] = useState(false);
  const {
    data: newMsgQueueData,
    isSuccess: isSuccessMsgQueueData,
    isFetching: isFetchingMsgQueueData,
    refetch: refetchMsgQueueData,
  } = useOutboundMsgData({ autoRefreshSeconds: autoRefreshSeconds });

  //if we get a new configuration list, put it in our state
  useEffect(() => {
    if (newMsgQueueData) {
      SetMsgQueueData(newMsgQueueData);
    }
  }, [isSuccessMsgQueueData, newMsgQueueData]);

  //update the last update timestamp every time we fetch successfully
  //even if the returned data has not actually changed
  useEffect(() => {
    if (isFetchingMsgQueueData === false && isSuccessMsgQueueData === true) {
      setLastUpdateTime(new Date());
    }
  }, [isFetchingMsgQueueData, isSuccessMsgQueueData]);

  const onSendMsgButtonClicked = useCallback((config: MsgQueueInfo) => {
    currentMsgQueueRef.current = config;
    setSendMsgDialogOpen(true);
  }, []);

  const shouldDisplaySendMsg = useCallback((msgQueue: MsgQueueInfo) => {
    return msgQueue.msgType > 0;
  }, []);

  const columnDefs = useMemo<Array<ColDef>>(() => {
    return [
      {
        colId: "sendMsg",
        headerName: "Send Message",
        cellRenderer: MuiIconCellRenderer,
        cellRendererParams: {
          disabled: !isOpsCenterWriterMember,
          iconName: "send",
          size: "medium",
          tooltipText: "Send Message",
          shouldDisplay: shouldDisplaySendMsg,
          onClick: onSendMsgButtonClicked,
        } as MuiIconRendererProps,
      },
      {
        headerName: "Queue Name",
        field: "name",
        //sort: "asc",
      },
      {
        headerName: "Message Count",
        field: "msgCount",
      },
      {
        headerName: "Minutes Behind",
        field: "minBehind",
      },
    ];
  }, [isOpsCenterWriterMember]);

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: false,
      resizable: true,
    };
  }, []);

  const autoSizeColumns = useCallback(() => {
    gridRef.current?.api.autoSizeAllColumns();
  }, []);

  return (
    <ContentView>
      <ContentHeader title={"Outbound Message Monitor"} />
      <Stack
        direction="row"
        spacing={3}
        sx={{
          backgroundColor: theme.palette.neutral.lowContrast,
          flexWrap: "wrap",
          padding: "6px",
          rowGap: "6px",
          alignItems: "center",
        }}
      >
        <Box sx={{ "& > button": { m: 1 }, display: "flex", width: "14ch", marginLeft: "auto !important" }}>
          <Button
            onClick={() => refetchMsgQueueData()}
            endIcon={isFetchingMsgQueueData ? <CircularProgress color="inherit" size="1em" /> : <RefreshIcon />}
            variant="contained"
          >
            Refresh
          </Button>
        </Box>
        <TextField
          size="small"
          select
          variant="filled"
          label="Auto-refresh"
          value={autoRefreshSeconds}
          onChange={(e) => setAutoRefreshSeconds(+e.target.value)}
          sx={{ width: "10ch", marginRight: ".5em !important" }}
        >
          <MenuItem value={0}>Off</MenuItem>
          <MenuItem value={30}>30s</MenuItem>
          <MenuItem value={60}>1m</MenuItem>
          <MenuItem value={300}>5m</MenuItem>
          <MenuItem value={900}>15m</MenuItem>
        </TextField>
      </Stack>

      {/* It appears that in order to make the grid fit into a flex scheme 
      it needs to be contained by a div with a hard size (calculated by flex, in this case) that it can fill completely */}
      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          "& .ag-theme-alpine .ag-cell-value": {
            lineHeight: "20px !important",
            wordBreak: "normal",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
          "& .ag-theme-alpine-dark .ag-cell-value": {
            lineHeight: "20px !important",
            wordBreak: "normal",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
        }}
      >
        <div className={gridTheme} style={{ display: "flex", flex: "1 1 auto" }}>
          <AgGridReact
            ref={gridRef}
            defaultColDef={defaultColDef}
            rowData={msgQueueData}
            columnDefs={columnDefs}
            ensureDomOrder={true}
            enableCellTextSelection={true}
            onFirstDataRendered={autoSizeColumns}
            onGridReady={autoSizeColumns}
            onModelUpdated={autoSizeColumns}
            containerStyle={{ width: "100%" }}
          />
        </div>
      </Box>
      <Typography
        variant="body2"
        noWrap
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          paddingLeft: "1em",
        }}
      >
        Last update time: {FormatTimestampLocal(lastUpdateTime)}
      </Typography>

      {/* This dialog shows the clear cache dialog */}
      {currentMsgQueueRef.current && (
        <SendMessage
          msgQueue={currentMsgQueueRef.current}
          open={sendMsgDialogOpen}
          onClose={() => setSendMsgDialogOpen(false)}
        />
      )}
    </ContentView>
  );
}
