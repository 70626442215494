import React from "react";
import { IconDefinition } from "@elynx/fontawesome-svg-core";
import { IconButton, SxProps } from "@mui/material";
import FontAwesomeSvgIcon from "./FontAwesomeSvgIcon";

export type FontAwesomeIconButtonProps = {
  icon: IconDefinition;
  disabled?: boolean;
  title?: string;
  fontSize?: "inherit" | "large" | "medium" | "small";
  iconSize?: number;
  sx?: SxProps;
  onClick?: () => void;
};

export default function FontAwesomeIconButton({
  disabled,
  icon,
  title,
  fontSize,
  iconSize,
  sx,
  onClick,
}: FontAwesomeIconButtonProps) {
  return (
    <IconButton disabled={disabled} onClick={() => onClick && onClick()} title={title} sx={sx}>
      <FontAwesomeSvgIcon icon={icon} fontSize={fontSize} sx={{ fontSize: iconSize }} />
    </IconButton>
  );
}
