import { useQuery } from "react-query";
import { useAxios } from "../../contexts/axios-context/AxiosContext";
import { keys } from "./Keys";
import { TDTTag } from "./Models";

export type UseTDTTagListParameters = {
  customerID: number;
  deviceID: number;
  enabled: boolean;
};

//custom query hook
export function useTDTTagList({ customerID, deviceID, enabled }: UseTDTTagListParameters) {
  const { axios: ax } = useAxios();

  const queryKey = keys.tags(customerID, deviceID);

  return useQuery<Array<TDTTag>, Error>(
    queryKey,
    async () => {
      const { data } = await ax.get<Array<TDTTag>>(`testtagdata/tags/${customerID}/${deviceID}`);
      return data;
    },
    {
      enabled: enabled,
    }
  );
}
